export const periodicSchema = [
   {
      items: [
         {
            number: 1,
            title: 'H',
         },
         {
            columns: 16,
            title: 'empty',
         },
         {
            number: 2,
            title: 'He',
         },
      ],
   },
   {
      items: [
         {
            number: 3,
            title: 'Li',
         },
         {
            number: 4,
            title: 'Be',
         },
         {
            columns: 10,
            title: 'empty',
         },
         {
            number: 5,
            title: 'B',
         },
         {
            number: 6,
            title: 'C',
         },
         {
            number: 7,
            title: 'N',
         },
         {
            number: 8,
            title: 'O',
         },
         {
            number: 9,
            title: 'F',
         },
         {
            number: 10,
            title: 'Ne',
         },
      ],
   },
   {
      items: [
         {
            number: 11,
            title: 'Na',
         },
         {
            number: 12,
            title: 'Mg',
         },
         {
            columns: 10,
            title: 'empty',
         },
         {
            number: 13,
            title: 'Al',
         },
         {
            number: 14,
            title: 'Si',
         },
         {
            number: 15,
            title: 'P',
         },
         {
            number: 16,
            title: 'S',
         },
         {
            number: 17,
            title: 'Cl',
         },
         {
            number: 18,
            title: 'Ar',
         },
      ],
   },
   {
      items: [
         {
            number: 19,
            title: 'K',
         },
         {
            number: 20,
            title: 'Ca',
         },
         {
            number: 21,
            title: 'Sc',
         },
         {
            number: 22,
            title: 'Ti',
         },
         {
            number: 23,
            title: 'V',
         },
         {
            number: 24,
            title: 'Cr',
         },
         {
            number: 25,
            title: 'Mn',
         },
         {
            number: 26,
            title: 'Fe',
         },
         {
            number: 27,
            title: 'Co',
         },
         {
            number: 28,
            title: 'Ni',
         },
         {
            number: 29,
            title: 'Cu',
         },
         {
            number: 30,
            title: 'Zn',
         },
         {
            number: 31,
            title: 'Ga',
         },
         {
            number: 32,
            title: 'Ge',
         },
         {
            number: 33,
            title: 'As',
         },
         {
            number: 34,
            title: 'Se',
         },
         {
            number: 35,
            title: 'Br',
         },
         {
            number: 36,
            title: 'Kr',
         },
      ],
   },
   {
      items: [
         {
            number: 37,
            title: 'Rb',
         },
         {
            number: 38,
            title: 'Sr',
         },
         {
            number: 39,
            title: 'Y',
         },
         {
            number: 40,
            title: 'Zr',
         },
         {
            number: 41,
            title: 'Nb',
         },
         {
            number: 42,
            title: 'Mo',
         },
         {
            number: 43,
            title: 'Tc',
         },
         {
            number: 44,
            title: 'Ru',
         },
         {
            number: 45,
            title: 'Rh',
         },
         {
            number: 46,
            title: 'Pd',
         },
         {
            number: 47,
            title: 'Ag',
         },
         {
            number: 48,
            title: 'Cd',
         },
         {
            number: 49,
            title: 'In',
         },
         {
            number: 50,
            title: 'Sn',
         },
         {
            number: 51,
            title: 'Sb',
         },
         {
            number: 52,
            title: 'Te',
         },
         {
            number: 53,
            title: 'I',
         },
         {
            number: 54,
            title: 'Xe',
         },
      ],
   },
   {
      items: [
         {
            number: 55,
            title: 'Cs',
         },
         {
            number: 56,
            title: 'Ba',
         },
         {
            columns: 1,
            title: 'empty',
            info: '89-103',
         },
         {
            number: 72,
            title: 'Hf',
         },
         {
            number: 73,
            title: 'Ta',
         },
         {
            number: 74,
            title: 'W',
         },
         {
            number: 75,
            title: 'Re',
         },
         {
            number: 76,
            title: 'Os',
         },
         {
            number: 77,
            title: 'Ir',
         },
         {
            number: 78,
            title: 'Pt',
         },
         {
            number: 79,
            title: 'Au',
         },
         {
            number: 80,
            title: 'Hg',
         },
         {
            number: 81,
            title: 'Ti',
         },
         {
            number: 82,
            title: 'Pb',
         },
         {
            number: 83,
            title: 'Bi',
         },
         {
            number: 84,
            title: 'Po',
         },
         {
            number: 85,
            title: 'At',
         },
         {
            number: 86,
            title: 'Rn',
         },
      ],
   },
   {
      items: [
         {
            number: 87,
            title: 'Fr',
         },
         {
            number: 88,
            title: 'Ra',
         },
         {
            columns: 1,
            title: 'empty',
            full: true,
            info: '89-103',
         },
         {
            number: 104,
            title: 'Rf',
         },
         {
            number: 105,
            title: 'Db',
         },
         {
            number: 106,
            title: 'Sg',
         },
         {
            number: 107,
            title: 'Bh',
         },
         {
            number: 108,
            title: 'Hs',
         },
         {
            number: 109,
            title: 'Mt',
         },
         {
            number: 110,
            title: 'Ds',
         },
         {
            number: 111,
            title: 'Rg',
         },
         {
            number: 112,
            title: 'Cn',
         },
         {
            number: 113,
            title: 'Uut',
         },
         {
            number: 114,
            title: 'FI',
         },
         {
            number: 115,
            title: 'Uup',
         },
         {
            number: 116,
            title: 'Lv',
         },
         {
            number: 117,
            title: 'Uus',
         },
         {
            number: 118,
            title: 'Uuo',
         },
      ],
   },
]

export const periodicSchemaExtra = [
   {
      items: [
         {
            columns: 2,
            title: 'empty',
         },
         {
            title: 'combine',
         },
         {
            number: 57,
            title: 'La',
         },
         {
            number: 58,
            title: 'Ce',
         },
         {
            number: 59,
            title: 'Pr',
         },
         {
            number: 60,
            title: 'Nd',
         },
         {
            number: 61,
            title: 'Pm',
         },
         {
            number: 62,
            title: 'Sm',
         },
         {
            number: 63,
            title: 'Eu',
         },
         {
            number: 64,
            title: 'Gd',
         },
         {
            number: 65,
            title: 'Tb',
         },
         {
            number: 66,
            title: 'Dy',
         },
         {
            number: 67,
            title: 'Ho',
         },
         {
            number: 68,
            title: 'Er',
         },
         {
            number: 69,
            title: 'Tm',
         },
         {
            number: 70,
            title: 'Yb',
         },
         {
            number: 71,
            title: 'Lu',
         },
      ],
   },
   {
      items: [
         {
            columns: 2,
            title: 'empty',
         },
         {
            half: true,
            title: 'combine',
         },
         {
            number: 89,
            title: 'Ac',
         },
         {
            number: 90,
            title: 'Th',
         },
         {
            columns: 91,
            title: 'Pa',
         },
         {
            number: 92,
            title: 'U',
         },
         {
            number: 93,
            title: 'Np',
         },
         {
            number: 94,
            title: 'Pu',
         },
         {
            number: 95,
            title: 'Am',
         },
         {
            number: 96,
            title: 'Cm',
         },
         {
            number: 97,
            title: 'Bk',
         },
         {
            number: 98,
            title: 'Cf',
         },
         {
            number: 99,
            title: 'Es',
         },
         {
            number: 100,
            title: 'Fm',
         },
         {
            number: 101,
            title: 'Md',
         },
         {
            number: 102,
            title: 'No',
         },
         {
            number: 103,
            title: 'Lr',
         },
      ],
   },
]
