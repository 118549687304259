import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { Box, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import React, { useState } from 'react'
import { VideoStoryblok } from 'types/storyblok-components'
import styles from './PostGridCard.module.scss'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import useTranslation from 'next-translate/useTranslation'

const getLabel = (type: string, nextTranslate: any) => {
   switch (type) {
      case 'page_article':
         return nextTranslate('article')
      case 'page_seo':
         return nextTranslate('resource')
      case 'page_podcast':
         return nextTranslate('podcast')
      case 'page_video':
         return nextTranslate('video')
      case 'page_publication':
         return nextTranslate('publication')
      case 'page_news':
         return nextTranslate('monthlyLetter')
      case 'page_portfolio_update':
         return nextTranslate('portfolioUpdate')
      default:
         return ''
   }
}

const CardContent = ({ item, preload }: any) => {
   const { t } = useTranslation('common')

   const {
      title,
      excerpt,
      video,
      image,
      duration,
      description,
      list_image,
   }: any = item && item.content ? item.content : {}
   let { component }: any = item && item.content ? item.content : {}
   const [hideContent, setHideContent] = useState(false)
   if (item && item.full_slug && item.full_slug.includes('portfolio-updates')) {
      component = 'page_portfolio_update'
   }
   const label = getLabel(component, t)
   return (
      <Flex
         onClick={() => {
            if (component === 'page_video') {
               setHideContent(true)
            }
         }}
         className={classnames(
            styles.wrapper,
            component === 'page_article' ||
               component === 'page_publication' ||
               component === 'page_portfolio_update'
               ? styles.scrim
               : '',
            component === 'page_seo' ? styles.seo : '',
            component === 'page_video' ? styles.videoRatio : '',
         )}
         width={1}
         height="100%"
         padding={[5, null, null, null, null, 10]}
         backgroundColor="#fff"
         flexDirection="column"
         color={list_image ? '#fff' : '#19180a'}
      >
         {list_image && list_image.filename && (
            <Image
               preload={preload}
               isCritical={preload}
               src={list_image.filename}
               sizes={{
                  mobile: '50vw',
                  tablet: '50vw',
                  desktop: '30vw',
               }}
               alt={title}
               offScreen
               className={styles.background}
            />
         )}
         {video &&
            video?.length > 0 &&
            video.map((blok: VideoStoryblok) => {
               return (
                  <Box className={classnames(styles.video)} key={blok._uid}>
                     <DynamicComponent
                        blok={{
                           ...blok,
                           small: true,
                        }}
                        withScrim={true}
                     />
                  </Box>
               )
            })}
         {component === 'page_podcast' && (
            <Flex marginBottom={[10]} alignItems={['flex-end']} width={1}>
               <Box
                  width={['100px']}
                  marginRight={[4]}
                  className={styles.imageWrapper}
               >
                  {image && image.filename && <Image src={image.filename} />}
               </Box>
               <Box className={styles.duration} as="span">
                  {`Duration: ${duration}`}
               </Box>
            </Flex>
         )}

         {!video && (
            <Flex
               className={styles.content}
               marginTop="auto"
               alignItems="flex-start"
               flexDirection="column"
               paddingTop={component === 'page_podcast' ? 0 : [20]}
            >
               {label && (
                  <Box marginBottom={[4]}>
                     <Label small title={label} />
                  </Box>
               )}
               <Box marginBottom={[excerpt ? 4 : 0]} as="h5">
                  {title}
               </Box>
               {excerpt && (
                  <Box as="p" className={styles.excerpt}>
                     {excerpt}
                  </Box>
               )}
            </Flex>
         )}
         {video && (
            <Flex
               style={{ opacity: hideContent ? '0' : '1' }}
               className={[styles.content, styles.videoContent].join(' ')}
               marginTop="auto"
               alignItems="flex-start"
               flexDirection="column"
               paddingTop={[20]}
            >
               {label && (
                  <Box marginBottom={[4]}>
                     <Label small title={label} />
                  </Box>
               )}
               <Box marginBottom={[excerpt ? 4 : 0]} as="h5">
                  {title}
               </Box>
               {description && (
                  <Box as="p" className={styles.excerpt}>
                     {description}
                  </Box>
               )}
            </Flex>
         )}
      </Flex>
   )
}

const PostGridCard = ({ item, preload }: any) => {
   const { t } = useTranslation('common')

   const { full_slug } = item
   const { component }: any = item && item.content ? item.content : {}

   const label = getLabel(component, t)

   return label === 'Video' ? (
      <CardContent preload={preload} item={item} />
   ) : (
      <LinkComponent className={styles.link} href={full_slug}>
         <CardContent preload={preload} item={item} />
      </LinkComponent>
   )
}

export default PostGridCard
