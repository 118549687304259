import React, { useEffect, useState, Fragment } from 'react'
import { CtaButtonStoryblok, LinkStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import styles from './Header.module.scss'
import { SettingsProps } from '../types'
import { Box, Flex } from '@components/UI/Grid/Grid'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import LanguagePicker from './LanguagePicker/LanguagePicker'
import { SmallLogotype } from '@components/UI/Icon/Icon'
import classnames from 'classnames'
import MenuButton from './MenuButton/MenuButton'

import useUi from 'stores/ui/useUi'
import NextImage from 'next/image'
import { stringify } from '@lib/helpers'
import { useRouter } from 'next/router'

const Header: React.FC<SettingsProps> = ({ blok, story }) => {
   const { locale } = useRouter()

   const { full_slug = '' } = story || {}
   const hasSubNavigation = [
      'page_default',
      'page_investor',
      'page_post_landing',
      'page_sustainability',
      'page_fund',
      'page_seo',
   ]
   let pageNavigationItems = []
   pageNavigationItems =
      hasSubNavigation.includes(story?.content?.component || '') &&
      story?.content?.body?.length > 0
         ? story?.content?.body
              .filter((blok: any) => {
                 if (
                    blok.label ||
                    blok.information_label ||
                    blok.documents_label
                 ) {
                    return true
                 } else {
                    return false
                 }
              })
              .map((blok: any) => {
                 if (
                    blok.component === 'chart_and_holdings' ||
                    blok.component === 'information_and_documents'
                 ) {
                    return blok
                 } else {
                    return blok.label
                 }
              })
         : []

   const { activeMenu, setActiveMenu } = useUi()
   const { nav_items, top_bar_links, button } = blok || {}
   const [activeSubmenu, setActiveSubmenu] = useState('')
   const [activeSection, setActiveSection] = useState(-1)
   const [isScrolling, setScrolling] = useState(false)
   const [isPastHero, setIsPastHero] = useState(false)

   const sectionsScroll = (sections?: any[]) => {
      const headerHeight =
         parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
               '--header-pagenav-height',
            ),
         ) || 0

      const scrollTop = window.scrollY
      const visibleSections =
         sections && sections.length > 0
            ? sections
                 .map((section, index) => {
                    const sectionOffsetTop =
                       scrollTop +
                       section.section.getBoundingClientRect().top -
                       headerHeight -
                       1
                    return {
                       top: sectionOffsetTop,
                       index: index,
                       visible: scrollTop >= sectionOffsetTop,
                       name: section.section.className,
                    }
                 })
                 .filter((section) => {
                    return section.visible
                 })
            : []

      const currentSection =
         visibleSections && visibleSections.length > 0
            ? visibleSections[visibleSections.length - 1]
            : null

      const wrapper = document.getElementById('pageNavigation')
      if (currentSection) {
         wrapper?.classList.add('active')
         if (activeSection !== currentSection.index) {
            setActiveSection(currentSection.index)
         }
      } else {
         wrapper?.classList.remove('active')
         setActiveSection(-1)
      }
   }

   const headerScroll = () => {
      const scrollTop = window.scrollY
      const headerHeight =
         parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
               '--header-height',
            ),
         ) || 0
      const heroHeight = window.innerHeight - headerHeight * 2 + 12

      if (scrollTop > 100) {
         setScrolling(true)
      }
      if (scrollTop < 101) {
         setScrolling(false)
      }

      if (scrollTop > heroHeight) {
         setIsPastHero(true)
         document.documentElement.style.setProperty('--pagenav-opacity', '0')
      }
      if (scrollTop < heroHeight) {
         setIsPastHero(false)
         document.documentElement.style.setProperty('--pagenav-opacity', '1')
      }
   }

   useEffect(() => {
      const sections = document?.getElementsByTagName('section') || []
      const scrollableSections: any[] = []
      if (sections && sections.length > 0) {
         for (var i = 0; i < sections.length; i++) {
            scrollableSections.push({
               section: sections[i],
            })
         }
      }

      window.addEventListener('scroll', headerScroll)
      window.addEventListener('scroll', () =>
         sectionsScroll(scrollableSections),
      )
      return () => {
         window.removeEventListener('scroll', headerScroll)
         window.removeEventListener('scroll', () => sectionsScroll)
      }
   }, [activeSection])

   return (
      <React.Fragment>
         <Box
            onClick={() => {
               setActiveMenu('')
            }}
            className={classnames(
               styles.overlay,
               activeMenu === 'nav' ? styles.active : '',
            )}
         />
         <header
            className={classnames(
               styles.wrapper,
               isScrolling ? styles.scrolling : '',
               isScrolling ? 'scrolling' : '',
               isPastHero ? styles.pastHero : '',
               activeMenu === 'nav' ? styles.active : '',
               activeMenu === 'nav' ? 'active' : '',
               activeSubmenu ? styles.activeSubmenu : '',
            )}
         >
            <Flex
               alignItems="center"
               justifyContent="space-between"
               className={styles.navigation}
               width="100%"
               flexDirection="column"
            >
               <Flex
                  width="100%"
                  className={styles.inner}
                  flexDirection="column"
               >
                  {/* Main navigation */}
                  <Flex
                     justifyContent="space-between"
                     alignItems={['flex-start', null, null, 'center']}
                     flexDirection={['column', null, null, 'row']}
                     width={1}
                  >
                     {/* Left navigation */}
                     <Flex
                        className={styles.left}
                        flexDirection={['column', null, null, 'row']}
                        width={[1, null, null, 'auto']}
                     >
                        <Flex
                           alignItems="center"
                           justifyContent={[
                              'space-between',
                              null,
                              null,
                              'flex-start',
                           ]}
                           marginRight={[0, null, null, 10]}
                           width={[1, null, null, 'auto']}
                        >
                           <LinkComponent
                              href="/"
                              ariaLabel="Home"
                              className={styles.logotypeWrapper}
                              onClick={() => setActiveMenu('')}
                           >
                              <SmallLogotype className={styles.logotype} />
                           </LinkComponent>
                           <Flex className="hideDesktop">
                              {button && button?.length > 0
                                 ? button.map((blok: CtaButtonStoryblok) => {
                                      return (
                                         <Box
                                            key={blok._uid}
                                            className={classnames(
                                               styles.button,
                                            )}
                                            marginRight={4}
                                         >
                                            <DynamicComponent
                                               blok={blok}
                                               onClick={() => setActiveMenu('')}
                                            />
                                         </Box>
                                      )
                                   })
                                 : null}
                              <MenuButton />
                           </Flex>
                        </Flex>
                        <Flex
                           as="nav"
                           className={styles.mainLinks}
                           width={1}
                           alignItems={['flex-start', null, null, 'center']}
                           flexDirection={['column', null, null, 'row']}
                        >
                           {nav_items && nav_items?.length > 0
                              ? nav_items.map((blok: any, index: number) => {
                                   const currentPageParts =
                                      full_slug?.split('/') || []
                                   const isCurrentParentPage =
                                      blok?.component === 'link_list' &&
                                      stringify(blok?.title || '') ===
                                         currentPageParts[0]
                                   const isCurrentPage =
                                      full_slug === blok?.link?.cached_url
                                   return blok?.component === 'link' ? (
                                      <Box
                                         key={blok._uid}
                                         className={[
                                            styles.linkWrapper,
                                            isCurrentPage ? styles.active : '',
                                         ].join(' ')}
                                         marginRight={[2]}
                                      >
                                         <DynamicComponent
                                            blok={blok}
                                            key={blok._uid}
                                            onClick={() => setActiveMenu('')}
                                         />
                                      </Box>
                                   ) : blok?.component === 'link_list' ? (
                                      <Flex
                                         key={blok._uid}
                                         alignItems={[
                                            'flex-start',
                                            null,
                                            null,
                                            'center',
                                         ]}
                                         flexDirection={[
                                            'column',
                                            null,
                                            null,
                                            'row',
                                         ]}
                                         className={classnames(
                                            styles.linkGroup,
                                            activeSubmenu === blok?._uid
                                               ? styles.active
                                               : '',
                                         )}
                                         onClick={() =>
                                            setActiveSubmenu(
                                               activeSubmenu === blok?._uid
                                                  ? ''
                                                  : blok?._uid,
                                            )
                                         }
                                         onMouseLeave={() => {
                                            setActiveSubmenu('')
                                         }}
                                         marginRight={[0, null, null, 2]}
                                      >
                                         <Flex
                                            as="button"
                                            className={classnames(
                                               styles.linkTitle,
                                               isCurrentParentPage
                                                  ? styles.active
                                                  : '',
                                            )}
                                         >
                                            {blok?.title}
                                            <div className={styles.arrow}>
                                               <div
                                                  className={styles.arrowBlack}
                                               >
                                                  <NextImage
                                                     src="/icons/material/drop-down-black.svg"
                                                     width="24"
                                                     height="24"
                                                     alt="Chevron icon"
                                                  />
                                               </div>
                                               <div
                                                  className={styles.arrowWhite}
                                               >
                                                  <NextImage
                                                     src="/icons/material/drop-down-white.svg"
                                                     width="24"
                                                     height="24"
                                                     alt="Chevron icon"
                                                  />
                                               </div>
                                            </div>
                                         </Flex>
                                         {/* Sub navigation */}
                                         {nav_items && nav_items?.length > 0
                                            ? nav_items.map(
                                                 (blok: any, index: number) => {
                                                    return blok?.component ===
                                                       'link' ? null : blok?.component ===
                                                      'link_list' ? (
                                                       <Box
                                                          key={`sub-${index}`}
                                                          className={classnames(
                                                             styles.subNavigationWrapper,
                                                             activeSubmenu ===
                                                                blok?._uid
                                                                ? styles.active
                                                                : '',
                                                          )}
                                                       >
                                                          <Flex
                                                             className={
                                                                styles.subNavigation
                                                             }
                                                             flexDirection="column"
                                                          >
                                                             {blok?.links
                                                                ?.length > 0 &&
                                                                blok.links.map(
                                                                   (
                                                                      innerBlok: LinkStoryblok,
                                                                   ) => {
                                                                      const isCurrentPage =
                                                                         full_slug ===
                                                                         innerBlok
                                                                            ?.link
                                                                            ?.cached_url
                                                                      return (
                                                                         <Box
                                                                            key={
                                                                               innerBlok._uid
                                                                            }
                                                                            className={classnames(
                                                                               styles.linkWrapper,
                                                                               isCurrentPage
                                                                                  ? styles.active
                                                                                  : '',
                                                                            )}
                                                                            paddingX={[
                                                                               3,
                                                                               null,
                                                                               null,
                                                                               null,
                                                                               null,
                                                                               3,
                                                                            ]}
                                                                         >
                                                                            <DynamicComponent
                                                                               blok={
                                                                                  innerBlok
                                                                               }
                                                                               key={
                                                                                  innerBlok._uid
                                                                               }
                                                                               onClick={() =>
                                                                                  setActiveMenu(
                                                                                     '',
                                                                                  )
                                                                               }
                                                                            />
                                                                         </Box>
                                                                      )
                                                                   },
                                                                )}
                                                          </Flex>
                                                       </Box>
                                                    ) : null
                                                 },
                                              )
                                            : null}
                                      </Flex>
                                   ) : null
                                })
                              : null}
                        </Flex>
                     </Flex>
                     {/* Right navigation */}
                     <Flex
                        alignItem={['flex-start', null, null, 'center']}
                        flexDirection={['column', null, null, 'row']}
                        width={[1, null, null, 'auto']}
                     >
                        {top_bar_links && top_bar_links?.length > 0 && (
                           <Flex
                              as="nav"
                              className={styles.topLinks}
                              alignItem={['flex-start', null, null, 'center']}
                           >
                              {top_bar_links.map(
                                 (blok: LinkStoryblok, index: number) => {
                                    return (
                                       <Box
                                          className={styles.linkWrapper}
                                          key={blok._uid}
                                          marginLeft={
                                             index === 0
                                                ? 0
                                                : [0, null, null, 2]
                                          }
                                       >
                                          <DynamicComponent
                                             onClick={() => {
                                                setActiveMenu('')
                                             }}
                                             blok={blok}
                                          />
                                       </Box>
                                    )
                                 },
                              )}
                           </Flex>
                        )}
                        <Flex
                           className={classnames(styles.actions)}
                           justifyContent={[
                              'space-between   ',
                              null,
                              null,
                              'flex-start',
                           ]}
                           width={[1, null, null, 'auto']}
                        >
                           <Box marginLeft={[0, null, null, 2]}>
                              <LanguagePicker id="desktop-picker" />
                           </Box>
                           <Box className="hideMobile">
                              {button && button?.length > 0
                                 ? button.map((blok: CtaButtonStoryblok) => {
                                      return (
                                         <Box
                                            key={blok._uid}
                                            className={classnames(
                                               styles.button,
                                            )}
                                            marginLeft={[
                                               0,
                                               null,
                                               null,
                                               null,
                                               4,
                                            ]}
                                         >
                                            <DynamicComponent
                                               blok={blok}
                                               onClick={() => setActiveMenu('')}
                                            />
                                         </Box>
                                      )
                                   })
                                 : null}
                           </Box>
                        </Flex>
                     </Flex>
                  </Flex>
                  {/* Page navigation */}
                  {pageNavigationItems && pageNavigationItems.length > 0 && (
                     <Flex width={1} key={`${locale}-${full_slug}`}>
                        <Box
                           className={styles.pageNavigationWrapper}
                           id="pageNavigation"
                        >
                           <Box className={styles.pageNavigation}>
                              <Flex
                                 className={styles.pageNavigationInner}
                                 alignItems="center"
                                 as="nav"
                              >
                                 {pageNavigationItems.map(
                                    (link: any, index: number) => {
                                       if (typeof link === 'string') {
                                          return (
                                             <Box
                                                key={stringify(link)}
                                                marginRight={[2]}
                                                className={classnames(
                                                   styles.linkWrapper,
                                                   activeSection === index
                                                      ? styles.active
                                                      : '',
                                                )}
                                             >
                                                <LinkComponent
                                                   href={`${full_slug}#${stringify(
                                                      link,
                                                   )}`}
                                                >
                                                   {link}
                                                </LinkComponent>
                                             </Box>
                                          )
                                       }
                                       if (
                                          link &&
                                          link.component &&
                                          link.component ===
                                             'chart_and_holdings'
                                       ) {
                                          const {
                                             id_for_chart,
                                             id_for_holdings,
                                             label,
                                          } = link
                                          return (
                                             <Fragment key="chart-frag">
                                                <Box
                                                   key={stringify(
                                                      id_for_chart
                                                         ? id_for_chart
                                                         : label,
                                                   )}
                                                   marginRight={[2]}
                                                   className={classnames(
                                                      styles.linkWrapper,
                                                      activeSection === index
                                                         ? styles.active
                                                         : '',
                                                   )}
                                                >
                                                   <LinkComponent
                                                      href={`${full_slug}#${stringify(
                                                         id_for_chart
                                                            ? id_for_chart
                                                            : label,
                                                      )}`}
                                                   >
                                                      {id_for_chart
                                                         ? id_for_chart
                                                         : label}
                                                   </LinkComponent>
                                                </Box>
                                                {id_for_holdings && (
                                                   <Box
                                                      key={stringify(
                                                         id_for_holdings,
                                                      )}
                                                      marginRight={[2]}
                                                      className={classnames(
                                                         styles.linkWrapper,
                                                         activeSection === index
                                                            ? styles.active
                                                            : '',
                                                      )}
                                                   >
                                                      <LinkComponent
                                                         href={`${full_slug}#${stringify(
                                                            id_for_holdings,
                                                         )}`}
                                                      >
                                                         {id_for_holdings}
                                                      </LinkComponent>
                                                   </Box>
                                                )}
                                             </Fragment>
                                          )
                                       }
                                       if (
                                          link &&
                                          link.component &&
                                          link.component ===
                                             'information_and_documents'
                                       ) {
                                          const {
                                             information_label,
                                             documents_label,
                                          } = link
                                          return (
                                             <Fragment key="chart-frag">
                                                {information_label && (
                                                   <Box
                                                      key={stringify(
                                                         information_label,
                                                      )}
                                                      marginRight={[2]}
                                                      className={classnames(
                                                         styles.linkWrapper,
                                                         activeSection === index
                                                            ? styles.active
                                                            : '',
                                                      )}
                                                   >
                                                      <LinkComponent
                                                         href={`${full_slug}#${stringify(
                                                            information_label,
                                                         )}`}
                                                      >
                                                         {information_label}
                                                      </LinkComponent>
                                                   </Box>
                                                )}
                                                {documents_label && (
                                                   <Box
                                                      key={stringify(
                                                         documents_label,
                                                      )}
                                                      marginRight={[2]}
                                                      className={classnames(
                                                         styles.linkWrapper,
                                                         activeSection === index
                                                            ? styles.active
                                                            : '',
                                                      )}
                                                   >
                                                      <LinkComponent
                                                         href={`${full_slug}#${stringify(
                                                            documents_label,
                                                         )}`}
                                                      >
                                                         {documents_label}
                                                      </LinkComponent>
                                                   </Box>
                                                )}
                                             </Fragment>
                                          )
                                       }
                                    },
                                 )}
                              </Flex>
                           </Box>
                        </Box>
                     </Flex>
                  )}
               </Flex>
            </Flex>
         </header>
      </React.Fragment>
   )
}

export default Header
