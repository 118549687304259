import React from 'react'
import { SEOProps } from './types'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useAppContext } from '@context/state'
import { getSeo } from '@lib/helpers'
import { DefaultContext } from '@context/state'

const englishOrgJson: any = {
   '@context': 'https://schema.org/',
   '@type': 'Organization',
   url: 'https://www.auagfunds.com/',
   logo: 'https://www.auagfunds.com/favicon.svg',
   name: 'AuAg Funds',
   description:
      'AuAg Funds creates investor-friendly investment solutions with a strong focus on precious metals and green tech elements. We know precious metals and green-tech elements are essential in the transformation to a sustainable world. In our current unsustainable financial system, we are also certain gold- and silver-investments are the winners.',
   address: {
      '@type': 'PostalAddress',
      postalCode: '41136',
      streetAddress: 'AuAg Fonder, c/o Convendum, Kungsportsavenyen 21',
      addressCountry: 'Sweden',
      addressRegion: 'Västra Götaland County',
      addressLocality: 'Göteborg',
   },
   hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Investment Solutions',
      itemListElement: [
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Silver Bullet',
               url: 'https://www.auagfunds.com/investment-solutions/auag-silver-bullet',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Precious Green',
               url: 'https://www.auagfunds.com/investment-solutions/auag-precious-green',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Gold Mining',
               url: 'https://www.auagfunds.com/investment-solutions/auag-gold-mining',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Essential Metals',
               url: 'https://www.auagfunds.com/investment-solutions/auag-essential-metals',
            },
         },
      ],
   },
}

const norwayOrgJson: any = {
   '@context': 'http://www.schema.org',
   '@type': 'Organization',
   name: 'AuAg Funds',
   url: 'https://www.auagfunds.com/no',
   logo: 'https://www.auagfunds.com/favicon.svg',
   description:
      'AuAg Funds skaper investeringsvennlige løsninger med fokus på edelmetaller og grønn teknologi. Vi vet at edelmetaller og grønn teknologi er essensielle i transformasjonen mot en bærekraftig verden. I vårt nåværende usunne finanssystem er vi også sikre på at investeringer i gull og sølv er vinnerne.',
   address: {
      '@type': 'PostalAddress',
      streetAddress: 'AuAg Fonder, c/o Convendum, Kungsportsavenyen 21',
      addressLocality: 'Göteborg',
      addressRegion: 'Västra Götaland County',
      postalCode: '41136',
      addressCountry: 'Sweden',
   },
   hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Investment Solutions',
      itemListElement: [
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Silver Bullet',
               url: 'https://www.auagfunds.com/no/investment-solutions/auag-silver-bullet',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Precious Green',
               url: 'https://www.auagfunds.com/no/investment-solutions/auag-precious-green',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Gold Mining',
               url: 'https://www.auagfunds.com/no/investment-solutions/auag-gold-mining',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Essential Metals',
               url: 'https://www.auagfunds.com/no/investment-solutions/auag-essential-metals',
            },
         },
      ],
   },
}

const swedishOrgJson: any = {
   '@context': 'http://www.schema.org',
   '@type': 'Organization',
   name: 'AuAg Funds',
   url: 'https://www.auagfunds.com/sv',
   logo: 'https://www.auagfunds.com/favicon.svg',
   description:
      'AuAg Fonder skapar investerarvänliga investeringslösningar med fokus på ädelmetaller och grön-teknik-grundämnen. Vi vet att ädelmetaller och grön-teknik-grundämnen är avgörande för omställningen till en hållbar värld. I vårt nuvarande ohållbara finansiella system är vi också säkra på att guld- och silver-investeringar är vinnare.',
   address: {
      '@type': 'PostalAddress',
      streetAddress: 'AuAg Fonder, c/o Convendum, Kungsportsavenyen 21',
      addressLocality: 'Göteborg',
      addressRegion: 'Västra Götaland County',
      postalCode: '41136',
      addressCountry: 'Sweden',
   },
   hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Investment Solutions',
      itemListElement: [
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Silver Bullet',
               url: 'https://www.auagfunds.com/sv/investment-solutions/auag-silver-bullet',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Precious Green',
               url: 'https://www.auagfunds.com/sv/investment-solutions/auag-precious-green',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Gold Mining',
               url: 'https://www.auagfunds.com/sv/investment-solutions/auag-gold-mining',
            },
         },
         {
            '@type': 'Offer',
            itemOffered: {
               '@type': 'Service',
               name: 'AuAg Essential Metals',
               url: 'https://www.auagfunds.com/sv/investment-solutions/auag-essential-metals',
            },
         },
      ],
   },
}

const SEO: React.FC<SEOProps> = ({
   title,
   description,
   image,
   type = 'website',
   isDefault,
}) => {
   const { fallbackSeo }: DefaultContext = useAppContext()
   const { seo_title: seoFallbackTitle = '' } = fallbackSeo
      ? getSeo(fallbackSeo)
      : {}

   const router = useRouter()
   const siteUrl = process.env.NEXT_PUBLIC_SITE_URL

   const { defaultLocale, locale, locales } = router || {}
   let { asPath } = router || {}

   // const pageUrl =
   //    siteUrl && asPath
   //       ? `${siteUrl}${defaultLocale === locale ? '' : `/${locale}`}${
   //            locale !== defaultLocale && asPath === '/' ? '' : asPath
   //         }`
   //       : null

   const seoTitle =
      title === seoFallbackTitle
         ? seoFallbackTitle
         : title
         ? `${title} | ${seoFallbackTitle}`
         : seoFallbackTitle

   if (asPath && asPath.includes('?')) {
      const splitted = asPath.split('?')
      asPath = splitted[0]
   }

   return (
      <Head>
         {locale === 'en' ? (
            <script
               type="application/ld+json"
               dangerouslySetInnerHTML={{
                  __html: JSON.stringify(englishOrgJson),
               }}
               key="item-jsonld-en"
            />
         ) : locale === 'sv' ? (
            <script
               type="application/ld+json"
               dangerouslySetInnerHTML={{
                  __html: JSON.stringify(swedishOrgJson),
               }}
               key="item-jsonld-sv"
            />
         ) : locale === 'no' ? (
            <script
               type="application/ld+json"
               dangerouslySetInnerHTML={{
                  __html: JSON.stringify(norwayOrgJson),
               }}
               key="item-jsonld-sv"
            />
         ) : null}
         <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=auto"
         />
         {locale && <meta property="og:locale" content={locale} />}
         {siteUrl && locale && asPath && isDefault && (
            <link
               rel="alternate"
               hrefLang="x-default"
               href={`${siteUrl}${asPath === '/' ? '' : asPath}`}
            />
         )}
         <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />

         {siteUrl &&
            asPath &&
            isDefault &&
            locales &&
            locales.length > 0 &&
            locales.map((loc: string) => {
               return (
                  <link
                     key={`locale-${loc}`}
                     rel="alternate"
                     hrefLang={loc}
                     href={`${siteUrl}${
                        defaultLocale === loc ? '' : `/${loc}`
                     }${asPath === '/' ? '' : asPath}`}
                  />
               )
            })}
         {siteUrl && locale && asPath && isDefault && (
            <link
               rel="canonical"
               href={`${siteUrl}${
                  locale === 'sv' ? '/sv' : locale === 'no' ? '/no' : ''
               }${asPath}`}
            />
         )}
         {seoTitle && <title key="title">{seoTitle}</title>}
         {seoTitle && (
            <meta property="og:title" content={seoTitle} key="ogTitle" />
         )}
         {seoTitle && (
            <meta
               property="twitter:title"
               content={seoTitle}
               key="twitterTitle"
            />
         )}

         {type && <meta property="og:type" content={type} key="ogType" />}
         {type && (
            <meta property="twitter:type" content={type} key="twitterType" />
         )}

         {description && (
            <meta name="description" content={description} key="description" />
         )}
         {description && (
            <meta
               name="og:description"
               content={description}
               key="ogDescription"
            />
         )}

         {image && (
            <meta property="og:image" content={image} key="ogImageSrc" />
         )}
         {image && (
            <meta
               property="twitter:image"
               content={image}
               key="twitterImageSrc"
            />
         )}

         {seoTitle && (
            <meta property="og:image:alt" content={seoTitle} key="ogImageAlt" />
         )}
         {seoTitle && (
            <meta
               property="twitter:image:alt"
               content={seoTitle}
               key="twitterImageAlt"
            />
         )}
      </Head>
   )
}

export default SEO
