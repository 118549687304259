import React from 'react'

type Icon = {
   color?: string
   bgColor?: string
   className?: string
   width?: number
   height?: number
   strokeWidth?: number
}

export const SmallLogotype: React.FC<Icon> = ({
   color = 'var(--white)',
   className,
   width = 100,
   height = 186,
}: Icon) => (
   <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1860.88 1080"
   >
      <path
         fill={color}
         d="M1308.82 660.2c1.14 16.28-11.14 30.4-27.41 31.54-.77.05-1.54.08-2.31.07-14.7.08-27.53-9.97-30.98-24.26l-20.06-65h-140.75l-20.06 66.79c-3.69 13.62-16.25 22.92-30.35 22.47-14.83.33-27.12-11.42-27.46-26.25-.03-1.16.02-2.32.15-3.47.21-3.05.81-6.06 1.79-8.95l107.44-334.42c4.92-18.55 21.63-31.53 40.83-31.7 19.16.03 35.9 12.98 40.74 31.52l106.73 330.21c1.04 3.73 1.61 7.58 1.7 11.45zm-205.93-110.4h110.49l-55.24-180.33-55.25 180.33zM1532.22 408.96c2.18-12.46 13.54-21.18 26.14-20.06 14.05-1.09 26.33 9.41 27.42 23.46.13 1.7.09 3.41-.11 5.1v284.01c0 66.79-46.11 106.82-115.32 106.82-56.41 0-91.6-23.64-106.82-57.66-7.88-17.91 0-41.19 23.64-41.19 12.8 0 18.89 6 25.52 16.92 9.77 21.43 31.73 34.64 55.24 33.22 38.23 0 57.66-21.85 57.66-55.15v-52.29a91.06 91.06 0 0 1-71.63 30.98c-66.17 0-101.98-45.48-101.98-112.91v-71.63c0-67.33 35.81-112.82 101.98-112.82 29.72-1.11 58.08 12.49 75.84 36.35l2.42-13.15zm-6.63 160.27v-69.3c0-34.56-22.47-61.33-58.29-61.33s-54.62 25.52-54.62 58.92v74.05c0 33.31 18.8 58.83 54.62 58.83s58.29-26.61 58.29-61.17zM566.65 660.2c1.14 16.28-11.14 30.4-27.41 31.54-.8.06-1.6.08-2.4.07-14.65.03-27.4-10.02-30.8-24.26l-20.06-65H344.87l-20.06 66.79c-3.69 13.62-16.25 22.92-30.35 22.47-14.83.08-26.93-11.87-27.01-26.71-.01-1.01.05-2.02.15-3.02.17-3.05.77-6.07 1.79-8.95l107.44-334.42c4.97-18.42 21.57-31.29 40.65-31.52 19.14.04 35.84 12.99 40.65 31.52l106.64 330.03c1.1 3.73 1.73 7.58 1.88 11.46zM360.72 549.8h110.04l-55.24-180.33-54.8 180.33zM797.21 657.79c-18.09 24.22-46.88 38.07-77.09 37.07-63.66 0-97.06-43.07-97.06-106.19V418.54c-.81-15.65 11.22-29 26.88-29.81.95-.05 1.9-.05 2.85 0 16.04-1.04 29.88 11.11 30.93 27.15.06.89.07 1.78.05 2.67v167.7c0 32.14 17.91 55.78 51.57 55.78 35.81 0 57.66-26.86 57.66-61.87V418.99c-.72-15.76 11.46-29.12 27.22-29.84 1.04-.05 2.09-.04 3.13.03 15.95-.8 29.53 11.48 30.33 27.44.04.79.05 1.59.02 2.38v244.26c1.29 14.43-9.36 27.18-23.79 28.47-1.02.09-2.05.12-3.07.09-12.35 1-23.54-7.31-26.14-19.43l-3.49-14.6z"
      />
      <path
         fill={color}
         d="M1062.69 1064.46H172.87c-87.72 0-158.84-71.11-158.84-158.84V15.53h537.94c65.68.05 118.9 53.31 118.9 118.99v49.33c0 12.56-10.18 22.74-22.74 22.74-12.56 0-22.74-10.18-22.74-22.74v-49.33c-.05-40.51-32.91-73.33-73.42-73.33H59.52v844.33c-.15 62.65 50.52 113.56 113.17 113.71h844.51V914.49c0-12.56 10.18-22.74 22.74-22.74 12.56 0 22.74 10.18 22.74 22.74v149.97zM1824.02 807.94c-12.57 0-22.78-10.17-22.83-22.74V174.47c-.05-62.53-50.73-113.21-113.26-113.26h-648.16c-12.57-.05-22.74-10.26-22.74-22.83-.05-12.56 10.09-22.78 22.65-22.83h648.25c87.77 0 158.93 71.15 158.93 158.93V785.2c0 12.56-10.18 22.74-22.74 22.74h-.1z"
      />
   </svg>
)

export const Logotype: React.FC<Icon> = ({
   color = 'var(--white)',
   className,
   width = 100,
   height = 100,
}: Icon) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      className={className}
      width={width}
      height={height}
   >
      <defs>
         <clipPath id="a">
            <path d="M0 0h1000v1000H0z" />
         </clipPath>
      </defs>
      <g fill={color} clipPath="url(#a)">
         <path
            data-name="Path 33"
            d="M369.25 531.905a7.787 7.787 0 0 1-7.835 8.33 8.354 8.354 0 0 1-8.165-6.4l-5.286-17.132h-37.1l-5.286 17.6a8.023 8.023 0 0 1-8 5.923 7.079 7.079 0 0 1-7.2-7.835 9.44 9.44 0 0 1 .472-2.36l28.318-88.138a11.233 11.233 0 0 1 10.761-8.354 11.115 11.115 0 0 1 10.737 8.306l28.129 87.029a12.531 12.531 0 0 1 .448 3.021Zm-54.275-29.1h29.12l-14.56-47.526Z"
         />
         <path
            data-name="Path 34"
            d="M428.127 465.689a6.419 6.419 0 0 1 6.891-5.286 6.725 6.725 0 0 1 7.2 7.528v74.853c0 17.6-12.153 28.152-30.394 28.152-14.867 0-24.141-6.23-28.152-15.2-2.077-4.72 0-10.855 6.23-10.855 3.375 0 4.979 1.581 6.725 4.46a15.008 15.008 0 0 0 14.56 8.755c10.076 0 15.2-5.758 15.2-14.536v-13.779a24 24 0 0 1-18.878 8.165c-17.439 0-26.878-11.988-26.878-29.757v-18.878c0-17.746 9.439-29.733 26.878-29.733a23.81 23.81 0 0 1 19.987 9.581Zm-1.746 42.24v-18.264c0-9.109-5.923-16.165-15.362-16.165s-14.395 6.725-14.395 15.527v19.516c0 8.778 4.956 15.5 14.395 15.5s15.362-7.009 15.362-16.117Z"
         />
         <path
            data-name="Path 35"
            d="M173.647 531.905a7.787 7.787 0 0 1-7.858 8.33 8.33 8.33 0 0 1-8.118-6.4l-5.286-17.132h-37.191l-5.286 17.6a8.023 8.023 0 0 1-8 5.923 7.079 7.079 0 0 1-7.079-7.835 8.424 8.424 0 0 1 .472-2.36l28.318-88.129a11.233 11.233 0 0 1 10.717-8.306 11.091 11.091 0 0 1 10.71 8.306l28.105 86.982a12.531 12.531 0 0 1 .496 3.021Zm-54.275-29.1h29l-14.559-47.522Z"
         />
         <path
            data-name="Path 36"
            d="M234.412 531.267a24.353 24.353 0 0 1-20.318 9.77c-16.778 0-25.58-11.351-25.58-27.987v-44.836a7.481 7.481 0 0 1 7.835-7.858 7.669 7.669 0 0 1 8.165 7.858v44.2c0 8.472 4.72 14.7 13.592 14.7 9.439 0 15.2-7.079 15.2-16.306v-42.476a7.528 7.528 0 0 1 8-7.858 7.622 7.622 0 0 1 8 7.858v64.375a6.914 6.914 0 0 1-7.079 7.528 6.513 6.513 0 0 1-6.891-5.121Z"
         />
         <path
            data-name="Path 37"
            d="M304.379 638.45H69.863A41.863 41.863 0 0 1 28 596.564V362h141.777a31.362 31.362 0 0 1 31.338 31.362v13a5.994 5.994 0 0 1-5.994 5.994 5.994 5.994 0 0 1-5.994-5.994v-13a19.35 19.35 0 0 0-19.35-19.327H39.988v222.529a29.9 29.9 0 0 0 29.9 29.969h222.504v-27.61a5.994 5.994 0 0 1 5.994-5.994 5.994 5.994 0 0 1 5.994 5.994Z"
         />
         <path
            data-name="Path 38"
            d="M505.033 570.842a6.018 6.018 0 0 1-6.018-5.994V403.886a29.875 29.875 0 0 0-29.851-29.851H298.338a6.018 6.018 0 0 1-5.994-6.017 5.994 5.994 0 0 1 5.994-6.018h170.826a41.886 41.886 0 0 1 41.886 41.886v160.962a5.994 5.994 0 0 1-6.018 5.994Z"
         />
         <path
            data-name="Path 39"
            d="M579.909 494.243v38.68a5.829 5.829 0 0 1-11.657 0v-90.242a5.38 5.38 0 0 1 5.664-5.664h47.408a5.1 5.1 0 0 1 5.215 5.451 5 5 0 0 1-5.215 5.215h-41.415v36.129h37.757a5 5 0 0 1 5.074 5.357 4.72 4.72 0 0 1-5.074 5.074Z"
         />
         <path
            data-name="Path 40"
            d="M708.802 441.927v65.2c0 19.8-13.191 32.235-32.542 32.235s-32.472-12.435-32.472-32.234v-65.2a5.522 5.522 0 0 1 5.829-5.687 5.451 5.451 0 0 1 5.97 5.687v65.508c0 12.271 7.5 21.238 20.7 21.238s20.884-9.062 20.884-21.238v-65.509a5.829 5.829 0 0 1 11.657 0Z"
         />
         <path
            data-name="Path 41"
            d="M797.79 532.92a5.38 5.38 0 0 1-5.664 5.664 6.371 6.371 0 0 1-5.829-3.823l-41.886-74.876v73.484a5.239 5.239 0 0 1-5.522 5.215 5.121 5.121 0 0 1-5.428-5.215v-91.442a5.664 5.664 0 0 1 5.994-5.687c3.209 0 4.6 1.085 6.277 4.295l41.129 73.153v-72.233a5.1 5.1 0 0 1 5.357-5.215 5.239 5.239 0 0 1 5.522 5.215Z"
         />
         <path
            data-name="Path 42"
            d="M889.209 470.007v34.831c0 20.247-13.333 33.037-33.887 33.037h-26.36a5.4 5.4 0 0 1-5.687-5.734v-89.46a5.4 5.4 0 0 1 5.687-5.663h26.241c20.554-.001 34.006 12.719 34.006 32.989Zm-54.276-22.418v79.644h20.271c14.159 0 22.229-9.2 22.229-22.088v-35.4c0-12.884-7.976-22.111-22.229-22.111Z"
         />
         <path
            data-name="Path 43"
            d="M958.869 462.646c-1.392-10.43-7.976-17.345-19.94-17.345s-19.492 6.277-19.492 15.2c0 7.079 4.908 12.106 11.516 14.725l16.519 6.749c11.516 4.6 24.4 10.737 24.4 28.082 0 17.793-13.357 29.309-32.848 29.309-18.406 0-30.843-9.2-32.683-25.321a5.475 5.475 0 0 1 5.522-6.607 4.932 4.932 0 0 1 5.38 4.72c1.062 10.124 8.283 17.179 21.639 17.179s21.474-7.669 21.474-17.958c0-10.879-7.835-15.173-17.179-18.878l-14.277-5.852c-11.351-4.436-20.719-11.8-20.719-25.3 0-15.2 12.271-25.958 30.677-25.958 17.179 0 29.45 9.439 30.843 25.155a5.64 5.64 0 0 1-5.522 6.607 5.1 5.1 0 0 1-5.31-4.507Z"
         />
      </g>
   </svg>
)
