import React from 'react'
import { PageContactProps } from './types'
import { Flex } from '@components/UI/Grid/Grid'

import styles from './PageContact.module.scss'
import SEOPage from '@components/Head/SEOPage/SEOPage'

import Head from 'next/head'

import DynamicComponent from '@components/DynamicComponent/DynamicComponent'

const PageContact: React.FC<PageContactProps> = ({
   blok,
   story,
   stories,
   funds,
   holdingData,
}) => {
   const { seo_data, schemas, content, body } = blok || {}
   const { currentNavData, navData } = stories || {}

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: story?.name || '',
            }}
         />

         <Flex width={1} className={styles.wrapper} flexDirection="column">
            {content &&
               content?.length > 0 &&
               content.map((blok: any, index: number) => (
                  <DynamicComponent
                     key={blok._uid}
                     blok={{ ...blok, isLast: index === content.length - 1 }}
                     className={styles.buttonFilled}
                  />
               ))}
         </Flex>
         <Flex width={1} flexDirection="column">
            {body &&
               body?.length > 0 &&
               body.map((blok: any, index: number) => (
                  <DynamicComponent
                     key={blok._uid}
                     currentNavData={currentNavData}
                     funds={
                        blok.component === 'fund_list' ||
                        blok.component === 'investors'
                           ? funds
                           : null
                     }
                     navData={
                        blok.component === 'chart_and_holdings' ? navData : null
                     }
                     holdingData={
                        blok.component === 'chart_and_holdings'
                           ? holdingData
                           : null
                     }
                     blok={{ ...blok, isLast: index === body.length - 1 }}
                     className={styles.buttonFilled}
                  />
               ))}
         </Flex>
      </React.Fragment>
   )
}

export default PageContact
