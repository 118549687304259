import React from 'react'

import styles from './MenuButton.module.scss'
import { MenuButtonProps } from './types'
import classnames from 'classnames'
import useUi from 'stores/ui/useUi'

const MenuButton: React.FC<MenuButtonProps> = ({ className }) => {
   const { setActiveMenu, activeMenu } = useUi()
   return (
      <button
         className={classnames(
            styles.hamburger,
            styles.hamburgerSqueeze,
            activeMenu === 'nav' ? styles.isActive : '',
            className,
         )}
         aria-label="Menu Button"
         onClick={() => setActiveMenu(activeMenu === 'nav' ? '' : 'nav')}
         type="button"
      >
         <span className={styles.hamburgerBox}>
            <span className={styles.hamburgerInner}></span>
         </span>
      </button>
   )
}

export default MenuButton
