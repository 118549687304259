import React from 'react'
import { SustainabilityCardProps } from './types'
import styles from './SustainabilityCard.module.scss'

import { Box, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'

const SustainabilityCard: React.FC<SustainabilityCardProps> = ({ blok }) => {
   const { title, text, width } = blok
   return (
      <Flex flexDirection="column">
         <Box
            paddingBottom={[width === '1' ? 0 : 5]}
            marginBottom={[5, null, null, 11]}
            className={width === '1' ? styles.bigTitle : styles.title}
            width={[8 / 12, null, null, 12 / 12]}
            as="span"
         >
            {title}
         </Box>
         <Box className={styles.text}>
            <Markdown>{text}</Markdown>
         </Box>
      </Flex>
   )
}

export default SustainabilityCard
