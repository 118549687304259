import React from 'react'
import Image from '../Image/Image'

import { StoryblokImageProps } from './types'

const StoryblokImage: React.FC<StoryblokImageProps> = ({
   src,
   alt,
   className = '',
   ratio,
}) => {
   return ratio ? (
      <Image src={src} alt={alt} ratio={ratio} className={className} />
   ) : (
      <Image src={src} alt={alt} className={className} />
   )
}

export default StoryblokImage
