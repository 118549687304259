import Funds from '@components/Funds/Funds'
import PeriodicTable from '@components/Modules/PeriodicTable/PeriodicTable'
import Footer from '@components/Settings/Footer/Footer'
import Header from '@components/Settings/Header/Header'
import { Container, Flex } from '@components/UI/Grid/Grid'
import React from 'react'

const EditSettings = ({ blok }: any) => {
   const {
      retailer_investors,
      institutional_investors,
      financial_investors,
      active_items,
   } = blok
   return (
      <div>
         <Header blok={blok} />
         <Flex as="section" style={{ background: '#fff' }} color={'black'}>
            <Container paddingTop={40} bg="#ddd">
               {(retailer_investors?.length > 0 ||
                  institutional_investors?.length > 0 ||
                  financial_investors?.length > 0) && (
                  <Funds
                     background={'#ddd'}
                     funds={{
                        retailer_investors: retailer_investors[0] || null,
                        institutional_investors:
                           institutional_investors[0] || null,
                        financial_investors: financial_investors[0] || null,
                     }}
                  />
               )}
            </Container>
         </Flex>
         <Flex as="section" paddingY={[15, null, null, 40]}>
            <Container>
               <PeriodicTable clickable activeItems={active_items} />
            </Container>
         </Flex>
         <Footer blok={blok} />
      </div>
   )
}

export default EditSettings
