
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@styles/fonts.scss';
import '@styles/globals.scss';
import '@splidejs/splide/dist/css/splide-core.min.css';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
// import { config } from 'config'
import type { AppProps } from 'next/app';
import Header from '@components/Settings/Header/Header';
import Footer from '@components/Settings/Footer/Footer';
// import Storyblok, { useStoryblok } from '@lib/storyblok'
import { AppWrapper } from '@context/state';
import styles from './app.module.scss';
import { SbDataSourceProps } from 'types/storyblok-props';
// import { SbDataSource } from 'types/storyblok-config'
import SEO from '@components/Head/SEO/SEO';
import Head from 'next/head';
import { getSeo } from '@lib/helpers';
import { useRouter } from 'next/router';
// import LayoutGrid from '@components/UI/LayoutGrid/LayoutGrid'
import { Box } from '@components/UI/Grid/Grid';
import GraphicWave from '@components/UI/GraphicWave/GraphicWave';
import useSystemTheme from 'react-use-system-theme';
import OridAnalytics from '@components/Head/OridAnalytics/OridAnalytics';
import globalJson from 'json/globalData.json'; /* eslint-disable-line */
// import ReactCookieFirst from 'react-cookiefirst'
import Script from 'next/script';
import UiProvider from 'stores/ui/UiProvider';
import PreviewBar from '@components/PreviewBar/PreviewBar';
const GTM_CODE = process.env.NEXT_PUBLIC_GTM_CODE;
interface Props extends AppProps {
    props: {
        settings: any;
        holdingData: any;
        dataSources: SbDataSourceProps[];
    };
    pageProps: {
        preview: boolean;
        story: any;
    };
    __N_PREVIEW: boolean;
}
// interface Context {
//    // any modifications to the default context, e.g. query types
//    preview: boolean
//    router: {
//       locale: string
//       locales: [string]
//    }
// }
const theme = {
    breakpoints: ['374px', '767px', '1024px', '1099px', '1920px'],
    space: [...Array(90)].map((_, i) => i * 4),
};
// firebase()
function MyApp({ Component, pageProps }: Props) {
    // let { settings, holdingData } = props || {}
    const systemTheme = useSystemTheme('dark');
    const router = useRouter();
    const { locale } = router;
    let cachedGlobalData: any = globalJson[locale === 'sv' ? 'sv' : locale === 'no' ? 'no' : 'en']; /* eslint-disable-line */
    if (!cachedGlobalData) {
        cachedGlobalData = globalJson['en'];
    }
    const { settings, holdingData } = cachedGlobalData;
    const { dataSources } = cachedGlobalData;
    const { preview = false, story } = pageProps || {};
    const component = story && story.content && story.content.component;
    const hidePattern = (story && story.content && story.content.hide_footer_pattern) ||
        component === 'page_post_landing' ||
        component === 'page_newsletter';
    // settings = useStoryblok(settings, preview)
    // holdingData = useStoryblok(holdingData, preview)
    const handleRouteStart = () => {
        document.documentElement.classList.add('smooth-anchor');
    };
    const handleRouteChange = () => {
        setTimeout(() => {
            document.documentElement.classList.remove('smooth-anchor');
        }, 250);
    };
    useEffect(() => {
        // routeChangeStart
        router.events.on('hashChangeStart', handleRouteStart);
        router.events.on('hashChangeComplete', handleRouteChange);
        return () => {
            router.events.off('hashChangeComplete', handleRouteChange);
            router.events.off('hashChangeStart', handleRouteStart);
        };
    }, []);
    // useEffect(() => {
    //    console.log('here',window.location)
    //    if (
    //       __N_PREVIEW &&
    //       window &&
    //       window.location &&
    //       !window.location.href.includes('secret')
    //    ) {
    //       console.log(1, document.getElementById('preview-bar'))
    //       const el = document.getElementById('preview-bar')
    //       console.log(3, el)
    //       if(el){
    //       console.log(2)
    //          el.classList.add('show-preview')
    //       }
    //    }
    // },[__N_PREVIEW])
    const { content } = settings || {};
    const { seo_data } = content || {};
    const { seo_title = '', seo_description = '', seo_image = '', } = getSeo(seo_data);
    const fundListProps = {
        retailer_investors: settings?.content?.retailer_investors?.length > 0
            ? settings?.content?.retailer_investors[0]
            : [],
        financial_investors: settings?.content?.financial_investors?.length > 0
            ? settings?.content?.financial_investors[0]
            : [],
        institutional_investors: settings?.content?.institutional_investors?.length > 0
            ? settings?.content?.institutional_investors[0]
            : [],
    };
    return (<AppWrapper defaultValue={{
            dataSources: dataSources || [],
            fallbackSeo: seo_data || {},
        }}>
         {/* <Script
           strategy="afterInteractive"
           src={
              'https://consent.cookiefirst.com/sites/auagfunds.com-10e01d78-3c5e-4704-ac44-b37950646dd8/consent.js'
           }
        /> */}

         <UiProvider>
            <ThemeProvider theme={theme}>
               {/* <LayoutGrid /> */}
               <div id="app" className={preview ? 'preview' : ''}>
                  {GTM_CODE && (<Script id="google-tagmanager" dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_CODE}');`,
            }}/>)}
                  <Script id="microsoft-clarity" dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
                              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                          })(window, document, "clarity", "script", "jq92mkt8s5");`,
        }}/>
                  <Head>
                     <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                     {systemTheme === 'dark' ? (<link rel="icon" type="image/svg+xml" href="/favicon-dark.svg"/>) : (<link rel="icon" type="image/svg+xml" href="/favicon.svg"/>)}
                     <link rel="manifest" href="/site.webmanifest"/>
                     <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                     <meta name="msapplication-TileColor" content="#ffffff"/>
                     <meta name="theme-color" content="#ffffff"/>
                  </Head>
                  <SEO title={seo_title} description={seo_description} image={seo_image} isDefault/>
                  <div className={styles.wrapper}>
                     {component !== 'settings' &&
            settings &&
            settings.content && (<Header blok={settings.content} story={story}/>)}
                     {preview && <PreviewBar />}

                     <main className={styles.page}>
                        <Component {...pageProps} funds={fundListProps} holdingData={holdingData} periodicItems={settings?.content?.active_items}/>
                     </main>
                     <Box className={styles.footerWrapper}>
                        {!hidePattern && (<Box className={styles.graphicWrapper}>
                              <GraphicWave />
                           </Box>)}

                        {!preview && settings?.content && (<Footer blok={settings.content}/>)}
                     </Box>
                  </div>
               </div>
               {/* {GTM_CODE && <OridAnalytics />} */}
            </ThemeProvider>
         </UiProvider>
      </AppWrapper>);
}
const __Next_Translate__Page__1929a584b33__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1929a584b33__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  