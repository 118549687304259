import React, { useEffect, useRef, useState } from 'react'
import { TwitterFeedProps } from './types'
import styles from './TwitterFeed.module.scss'
import { Flex, Box } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getTitleMarkup, stringify } from '@lib/helpers'
import Markdown from '@components/UI/Markdown/Markdown'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
const TwitterFeed: React.FC<TwitterFeedProps> = ({ blok }) => {
   const wrapperRef = useRef(null)
   const [loaded, setLoaded] = useState(false)
   const [visible, setVisible] = useState(false)

   const observeHandler = (entries: any) => {
      for (const entry of entries) {
         if (entry.isIntersecting && !loaded) {
            setLoaded(true)
         }
      }
   }

   const observer = process.browser
      ? new IntersectionObserver(observeHandler, {
           rootMargin: '0px 0px 0px 0px',
        })
      : null

   useEffect(() => {
      if (wrapperRef && wrapperRef.current) {
         observer?.observe(wrapperRef.current)
      }
      return () => {
         observer?.disconnect()
      }
   }, [])

   const { label, title, description, cta, title_markup } = blok
   return (
      <Flex
         ref={wrapperRef}
         as="section"
         className={styles.wrapper}
         flexDirection={['column', null, null, 'row']}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Flex
            width={[1, null, null, 1 / 2]}
            flexDirection="column"
            alignItems="flex-start"
            paddingX={['var(--container-side-padding-mobile)', null, null, 16]}
            paddingY={[10, null, null, 20]}
            className={styles.content}
         >
            <Box marginBottom={[4, null, null, 6]}>
               {label && <Label title={label} />}
            </Box>
            <Box
               as={getTitleMarkup(title_markup)}
               className={styles.title}
               width={1}
               marginBottom={[5, null, null, 10]}
            >
               {title}
            </Box>
            {description && (
               <Markdown className={styles.text}>{description}</Markdown>
            )}
            {cta &&
               cta?.length > 0 &&
               cta.map((blok: any) => (
                  <Box key={blok._uid} mt={[10, null, null, 40]}>
                     <DynamicComponent blok={blok} background="#321a3b" />
                  </Box>
               ))}
         </Flex>
         <Box className={styles.feed} width={[1, null, null, 1 / 2]}>
            <Flex
               alignItems={'center'}
               justifyContent={'center'}
               className={
                  visible
                     ? [styles.loading, styles.done].join(' ')
                     : styles.loading
               }
            >
               <svg
                  width="64"
                  height="64"
                  viewBox="0 0 38 38"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <defs>
                     <linearGradient
                        x1="8.042%"
                        y1="0%"
                        x2="65.682%"
                        y2="23.865%"
                        id="a"
                     >
                        <stop stopColor="#6b3850" stopOpacity="0" offset="0%" />
                        <stop
                           stopColor="#6b3850"
                           stopOpacity=".631"
                           offset="63.146%"
                        />
                        <stop stopColor="#6b3850" offset="100%" />
                     </linearGradient>
                  </defs>
                  <g fill="none" fillRule="evenodd">
                     <g transform="translate(1 1)">
                        <path
                           d="M36 18c0-9.94-8.06-18-18-18"
                           id="Oval-2"
                           stroke="url(#a)"
                           strokeWidth="2"
                        >
                           <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="0.9s"
                              repeatCount="indefinite"
                           />
                        </path>
                        <circle fill="#6b3850" cx="36" cy="18" r="1">
                           <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="0.9s"
                              repeatCount="indefinite"
                           />
                        </circle>
                     </g>
                  </g>
               </svg>
            </Flex>
            {loaded && (
               <TwitterTimelineEmbed
                  onLoad={() => {
                     const el: any = document.getElementById('twitter-widget-0')
                     if (el && el.contentDocument) {
                        const doc = el.contentDocument
                        doc.body.innerHTML =
                           doc.body.innerHTML +
                           '<style>html{height: 100%;}.timeline-Tweet-text{font-size:16px !important;line-height:1.3em !important;}</style>'
                     }
                     setVisible(true)
                  }}
                  options={{
                     height: 1000,
                  }}
                  screenName="AuAgFunds"
                  sourceType="profile"
                  noBorders={true}
                  linkColor="#000"
                  noHeader={true}
               />
            )}
         </Box>
      </Flex>
   )
}

export default TwitterFeed
