import React from 'react'
import { ContactCardProps } from './types'
import styles from './ContactCard.module.scss'
import { Box, Flex } from '@components/UI/Grid/Grid'
import NextImage from 'next/image'

import CtaButton from '@components/CtaButton/CtaButton'
import Image from '@components/UI/Image/Image'

const ContactCard: React.FC<ContactCardProps> = ({ blok }) => {
   const { title, phone, email, name, image } = blok
   const link: any = {
      title: 'Send an E-mail',
      link: {
         cached_url: `mailto:${email}`,
      },
   }
   return (
      <Flex width={[1]}>
         <Box width={1}>
            <Box className={styles.imageWrapper}>
               {image && image.filename && (
                  <Image alt={image.alt} src={image.filename} />
               )}
            </Box>
         </Box>
         <Flex
            paddingLeft={[5, null, null, 10]}
            paddingRight={[0, null, null, 10]}
            flexDirection={['column']}
            width={1}
         >
            {name && (
               <Box className={styles.name} as="span">
                  {name}
               </Box>
            )}
            {title && (
               <Box
                  className={styles.title}
                  marginTop={[4, null, null, 2]}
                  as="span"
               >
                  {title}
               </Box>
            )}
            <Flex
               marginTop={[4]}
               paddingTop={[4, null, null, 6]}
               flexDirection={['column']}
               className={styles.contact}
               alignItems="flex-start"
               height="100%"
            >
               {phone && (
                  <Flex marginBottom={[4]} alignItems="center">
                     <NextImage
                        src="/icons/phonecall.svg"
                        className="hideMobile"
                        width="27"
                        height="27"
                        alt="phone"
                     />
                     <NextImage
                        src="/icons/phonecall.svg"
                        className="hideDesktop"
                        width="12"
                        height="12"
                        alt="phone"
                     />
                     <Box as="span" marginLeft={2} className={styles.info}>
                        {phone}
                     </Box>
                  </Flex>
               )}
               {email && (
                  <Flex className={styles.info} alignItems="center" as="span">
                     <NextImage
                        src="/icons/mail.svg"
                        className="hideMobile"
                        width="27"
                        height="27"
                        alt="mail"
                     />
                     <NextImage
                        src="/icons/mail.svg"
                        className="hideDesktop"
                        width="12"
                        height="12"
                        alt="mail"
                     />

                     <Box as="span" marginLeft={2} className={styles.info}>
                        {email}
                     </Box>
                  </Flex>
               )}
               <Box
                  marginTop={[0, null, null, 'auto']}
                  paddingTop={[4, null, null, 6]}
               >
                  <CtaButton
                     blok={{
                        _uid: '',
                        component: 'cta_button',
                        link: [link],
                        color: 'purple',
                        background_color: 'gray',
                        hover_color: 'purple',
                     }}
                  />
               </Box>
            </Flex>
         </Flex>
      </Flex>
   )
}

export default ContactCard
