import React from 'react'
import { InvestorsProps } from './types'
import { Box, Container } from '@components/UI/Grid/Grid'
import InvestorsTable from '@components/Funds/InvestorsTable/InvestorsTable'

const Investors: React.FC<InvestorsProps> = ({ funds }) => {
   const { institutional_investors } = funds
      ? funds
      : { institutional_investors: { table_data: [] } }
   const { table_data } = institutional_investors || []
   return (
      <Box as="section" paddingY={[10]}>
         {table_data && table_data?.length > 0 && (
            <Container>
               <InvestorsTable tableData={institutional_investors} />
            </Container>
         )}
      </Box>
   )
}

export default Investors
