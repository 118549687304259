import { NextPage } from 'next'
import { useState } from 'react'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import NextImage from 'next/image'

import styles from './DocumentList.module.scss'

import LinkComponent from '../UI/LinkComponent/LinkComponent'
import SbEditable from 'storyblok-react'
import useTranslation from 'next-translate/useTranslation'

interface Props {
   blok: any
   filterByFund: any
}

const dropdown = {
   closed: {
      height: 0,
   },
   open: {
      height: 'auto',
   },
}

const DocumentList: NextPage<Props> = ({ blok, filterByFund }) => {
   const [activeGroup, setActiveGroup] = useState(0)
   const [activeSort, setActiveSort] = useState('language')
   const { groups, sort_title, sort_info, sort_country } = blok || {}
   const { t } = useTranslation('common')

   const filteredDocumentGroups =
      groups &&
      groups.map((group: any) => {
         return {
            ...group,
            documents:
               group.documents &&
               group.documents.filter((blok: any) => {
                  if (filterByFund && filterByFund.length > 0) {
                     if (blok.fund && blok.fund.length > 0) {
                        const found = blok.fund.find((fund: string) => {
                           return filterByFund.includes(fund)
                        })
                        return found
                     }
                  } else {
                     return true
                  }
               }),
         }
      })

   return (
      <div className={classnames(styles.wrapper)}>
         <div className={styles.sortSelect}>
            <div className={styles.sortSelectWrapper}>
               <select
                  onChange={(e: any) => {
                     setActiveSort(e.target.value)
                  }}
               >
                  <option selected={activeSort === 'title'} value={'title'}>
                     {sort_title}
                  </option>
                  <option
                     selected={activeSort === 'language'}
                     value={'language'}
                  >
                     {sort_country}
                  </option>
                  <option
                     selected={activeSort === 'description'}
                     value={'description'}
                  >
                     {sort_info}
                  </option>
               </select>
               <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path d="M12 15L7 10H17L12 15Z" fill="#808080" />
               </svg>
            </div>
         </div>
         <div className={styles.sort}>
            <div className={styles.title}>
               <button
                  className={classnames(
                     'table-1',
                     activeSort === 'title' ? styles.active : '',
                  )}
                  onClick={() => {
                     setActiveSort('title')
                  }}
               >
                  {sort_title}
                  <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path d="M12 15L7 10H17L12 15Z" fill="#808080" />
                  </svg>
               </button>
            </div>
            <div className={styles.country}>
               <button
                  onClick={() => {
                     setActiveSort('language')
                  }}
                  className={classnames(
                     'table-1',
                     activeSort === 'language' ? styles.active : '',
                  )}
               >
                  {sort_country}
                  <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path d="M12 15L7 10H17L12 15Z" fill="#808080" />
                  </svg>
               </button>
            </div>
            <div className={styles.info}>
               <button
                  onClick={() => {
                     setActiveSort('description')
                  }}
                  className={classnames(
                     'table-1',
                     activeSort === 'description' ? styles.active : '',
                  )}
               >
                  {sort_info}
                  <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path d="M12 15L7 10H17L12 15Z" fill="#808080" />
                  </svg>
               </button>
            </div>
         </div>
         {filteredDocumentGroups &&
            filteredDocumentGroups.map((blok: any, index: number) =>
               blok && blok.documents && blok.documents.length > 0 ? (
                  <SbEditable content={blok} key={blok._uid}>
                     <div
                        className={classnames(
                           styles.group,
                           index === activeGroup ? styles.active : '',
                        )}
                        key={blok._uid}
                     >
                        <button
                           onClick={() => {
                              setActiveGroup(index === activeGroup ? -1 : index)
                           }}
                           className={classnames('label-1', styles.button)}
                        >
                           {blok.title}
                           <svg
                              width="12"
                              height="7"
                              viewBox="0 0 12 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M5.99999 6.9748C5.86665 6.9748 5.74165 6.95397 5.62499 6.9123C5.50832 6.87064 5.39999 6.7998 5.29999 6.6998L0.699988 2.0998C0.516654 1.91647 0.424988 1.68314 0.424988 1.3998C0.424988 1.11647 0.516654 0.883138 0.699988 0.699805C0.883321 0.516471 1.11665 0.424805 1.39999 0.424805C1.68332 0.424805 1.91665 0.516471 2.09999 0.699805L5.99999 4.5998L9.89999 0.699805C10.0833 0.516471 10.3167 0.424805 10.6 0.424805C10.8833 0.424805 11.1167 0.516471 11.3 0.699805C11.4833 0.883138 11.575 1.11647 11.575 1.3998C11.575 1.68314 11.4833 1.91647 11.3 2.0998L6.69999 6.6998C6.59999 6.7998 6.49165 6.87064 6.37499 6.9123C6.25832 6.95397 6.13332 6.9748 5.99999 6.9748Z"
                                 fill="#808080"
                              />
                           </svg>
                        </button>
                        <motion.div
                           variants={dropdown}
                           animate={index === activeGroup ? 'open' : 'closed'}
                           className={styles.list}
                        >
                           {blok.documents &&
                              blok.documents
                                 .sort((a: any, b: any) => {
                                    if (a[activeSort] < b[activeSort]) {
                                       return -1
                                    }
                                    if (a[activeSort] > b[activeSort]) {
                                       return 1
                                    }
                                    return 0
                                 })
                                 .map((blok: any) => (
                                    <LinkComponent
                                       key={blok._uid}
                                       href={blok.file && blok.file.filename}
                                       className={styles.file}
                                    >
                                       <div className={styles.inner}>
                                          <div
                                             className={classnames(
                                                styles.title,
                                             )}
                                          >
                                             <NextImage
                                                src="/icons/material/document.svg"
                                                width="24"
                                                height="24"
                                                className={styles.icon}
                                                alt="Chevron icon"
                                             />
                                             <span className="body-text">
                                                {blok.title}
                                             </span>
                                          </div>
                                          <div
                                             className={classnames(
                                                styles.country,
                                             )}
                                          >
                                             <span className="body-text">
                                                {t(blok.language)}
                                             </span>
                                          </div>
                                          <div
                                             className={classnames(styles.info)}
                                          >
                                             <span className="body-text">
                                                {blok.description}
                                             </span>
                                          </div>
                                          <div className={styles.download}>
                                             <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                             >
                                                <path
                                                   d="M8.99998 13.6007L4.86665 9.46738L5.53332 8.80072L8.53332 11.8007V0.0673828H9.46665V11.8007L12.4667 8.80072L13.1333 9.46738L8.99998 13.6007ZM2.06665 17.934C1.48887 17.934 1.01109 17.7452 0.633317 17.3674C0.255539 16.9896 0.0666504 16.5118 0.0666504 15.9341V12.9341H0.999984V15.9341C0.999984 16.2007 1.11109 16.4452 1.33332 16.6674C1.55554 16.8896 1.79998 17.0007 2.06665 17.0007H15.9333C16.2 17.0007 16.4444 16.8896 16.6667 16.6674C16.8889 16.4452 17 16.2007 17 15.9341V12.9341H17.9333V15.9341C17.9333 16.5118 17.7444 16.9896 17.3667 17.3674C16.9889 17.7452 16.5111 17.934 15.9333 17.934H2.06665Z"
                                                   fill="var(--black)"
                                                />
                                             </svg>
                                          </div>
                                       </div>
                                    </LinkComponent>
                                 ))}
                        </motion.div>
                     </div>
                  </SbEditable>
               ) : null,
            )}
      </div>
   )
}

export default DocumentList
