import React from 'react'
import styles from './Label.module.scss'
const Label = ({ title, small }: { title: string; small?: boolean }) => (
   <div>
      <span
         className={
            small ? [styles.label, styles.small].join(' ') : styles.label
         }
      >
         <span className={styles.text}>{title}</span>
      </span>
   </div>
)

export default Label
