import React from 'react'
import { ContactsProps } from './types'
import { ContactCardStoryblok } from 'types/storyblok-components'
import styles from './Contacts.module.scss'
import { Flex, Box } from '@components/UI/Grid/Grid'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import Image from '@components/UI/Image/Image'

const Contacts: React.FC<ContactsProps> = ({ blok }) => {
   const { contacts } = blok
   return contacts && contacts.length > 0 ? (
      <Flex className={styles.items}>
         {contacts.map((blok: ContactCardStoryblok) => {
            const { title, phone, email, name, image } = blok

            return (
               <Box key={blok._uid} className={styles.card}>
                  <Box
                     className={styles.imageWrapper}
                     marginBottom={[5, null, null, 5]}
                  >
                     {image && image.filename && (
                        <Image alt={name} offScreen src={image.filename} />
                     )}
                  </Box>
                  {name && (
                     <Box
                        className={styles.name}
                        marginBottom={[1, null, null, 1]}
                     >
                        {name}
                     </Box>
                  )}
                  {title && (
                     <Box
                        marginBottom={[0, null, null, 1]}
                        className={styles.title}
                     >
                        {title}
                     </Box>
                  )}
                  {phone && (
                     <Box
                        marginBottom={[0, null, null, 1]}
                        className={styles.phone}
                     >
                        {phone}
                     </Box>
                  )}
                  {email && (
                     <Box
                        marginBottom={[0, null, null, 1]}
                        className={styles.mail}
                     >
                        <LinkComponent
                           href={`mailto:${email}`}
                        >{`${email}`}</LinkComponent>
                     </Box>
                  )}
               </Box>
            )
         })}
      </Flex>
   ) : null
}

export default Contacts
