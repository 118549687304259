import React, { useEffect, useRef, useState } from 'react'
import styles from './ChartAndHolding.module.scss'
import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { stringify } from '@lib/helpers'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { getLatestData, getNavString } from '@lib/charthelpers'
import NextImage from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import HoldingTable from '@components/HoldingTable/HoldingTable'
import { getChartData } from '@lib/getChartData'
import { fundNameToSlug } from '@lib/fundNameToSlug'

const AnyGraph = dynamic(() => import('./AnyGraph/AnyGraph'), {
   loading: () => <div className={styles.loaderGraph}></div>,
})

const fundTypes: any = {
   'AUAG SILVER BULLET A': {
      value: 'AUAG SILVER BULLET A',
      name: 'AuAg Silver Bullet A (SEK)',
      type: 'silver',
   },
   'AUAG SILVER BULLET B': {
      value: 'AUAG SILVER BULLET B',
      name: 'AuAg Silver Bullet B (EUR)',
      type: 'silver',
   },
   'AUAG ESSENTIAL METALS A': {
      value: 'AUAG ESSENTIAL METALS A',
      name: 'AuAg Essential Metals A (SEK)',
      type: 'metals',
   },
   'AUAG ESSENTIAL METALS B': {
      value: 'AUAG ESSENTIAL METALS B',
      name: 'AuAg Essential Metals B (EUR)',
      type: 'metals',
   },
   'AUAG PRECIOUS GREEN A': {
      value: 'AUAG PRECIOUS GREEN A',
      name: 'AuAg Precious Green A (SEK)',
      type: 'green',
   },
   'AUAG PRECIOUS GREEN B': {
      value: 'AUAG PRECIOUS GREEN B',
      name: 'AuAg Precious Green B (EUR)',
      type: 'green',
   },
   'AUAG GOLD MINING ETF': {
      value: 'AUAG GOLD MINING ETF',
      name: 'AuAg Gold Mining - ETF (USD)',
      type: 'etf',
   },
   'AUAG GOLD MINING ETF EURO': {
      value: 'AUAG GOLD MINING ETF EURO',
      name: 'AuAg Gold Mining - ETF (EUR)',
      type: 'etf-euro',
   },
   'AUAG GOLD MINING ETF GBP': {
      value: 'AUAG GOLD MINING ETF GBP',
      name: 'AuAg Gold Mining - ETF (GBP)',
      type: 'etf-gbp',
   },
   'AUAG GOLD MINING ETF CHF': {
      value: 'AUAG GOLD MINING ETF CHF',
      name: 'AuAg Gold Mining - ETF (CHF)',
      type: 'etf-chf',
   },
   'AUAG GOLD MINING A': {
      value: 'AUAG GOLD MINING A',
      name: 'AuAg Gold Mining - Daily Traded (SEK)',
      type: 'etf-daily',
   },
   'AUAG GOLD MINING B': {
      value: 'AUAG GOLD MINING B',
      name: 'AuAg Gold Mining - Daily Traded (EUR)',
      type: 'etf-daily',
   },
}

const ChartAndHolding = ({ blok, holdingData }: any) => {
   const { t } = useTranslation('common')

   const wrapperRef = useRef(null)

   const { locale } = useRouter()
   const { initial_fund, label, id_for_holdings, id_for_chart } = blok
   const [latestNavData, setLatestNavData]: any = useState(null)
   const [navData, setNavData]: any = useState(null)
   const [loaded, setLoaded] = useState(false)
   // const [fetching, setFetching] = useState(true)
   // const fetching = false

   const observeHandler = (entries: any) => {
      for (const entry of entries) {
         if (entry.isIntersecting && !loaded) {
            setLoaded(true)
         }
      }
   }

   const observer = process.browser
      ? new IntersectionObserver(observeHandler, {
           rootMargin: '0px 0px 0px 0px',
        })
      : null

   useEffect(() => {
      if (wrapperRef && wrapperRef.current) {
         observer?.observe(wrapperRef.current)
      }
      return () => {
         observer?.disconnect()
      }
   }, [])

   const [activeFund, setActiveFund] = useState(
      fundTypes[initial_fund] ? fundTypes[initial_fund] : fundTypes[0],
   )

   const updateNavData = async (name: string, type: string) => {
      try {
         // setFetching(true)
         const response: any = await getChartData(fundNameToSlug(name))
         const currNavData = response && response.data ? response.data : []
         const data = getLatestData(name, currNavData)

         if (type === 'etf-gbp') {
            data.price = data.gbp
            data.percentage = data.gbpPercentage
            data.currency = 'GBP'
         }
         if (type === 'etf-chf') {
            data.price = data.chf
            data.percentage = data.chfPercentage
            data.currency = 'CHF'
         }
         if (type === 'etf-euro') {
            data.price = data.euro
            data.percentage = data.euroPercentage
            data.currency = 'EUR'
         }
         setNavData(currNavData)
         setLatestNavData(data)
         // setFetching(false)
      } catch (error) {
         console.log('ER', error)
      }
   }

   useEffect(() => {
      if (activeFund && activeFund.value) {
         updateNavData(activeFund.value, activeFund.type)
      }
   }, [])

   const {
      holdings_title,
      holdings_introduction,

      // holding_list_title,
      disclaimer,
      silver_clarification,
      green_clarification,
      esgo_clarification,
      metals_clarification,
   } =
      holdingData && holdingData.content
         ? holdingData.content
         : {
              holdings_title: null,
              holdings_introduction: null,

              //   holding_list_title: null,
              disclaimer: null,
              silver_clarification: null,
              green_clarification: null,
              esgo_clarification: null,
              metals_clarification: null,
           }

   const sectionID = label ? stringify(id_for_chart ? id_for_chart : label) : ''
   const holdingSectionID = label ? stringify(id_for_holdings) : ''

   return (
      <Flex
         ref={wrapperRef}
         as="section"
         paddingY={[10, null, null, null, 20]}
         className={styles.wrapper}
         width={1}
      >
         <div id={sectionID} className="anchor" />
         <Container>
            <Flex
               flexDirection={['column', null, 'row']}
               justifyContent="space-between"
            >
               {id_for_chart && (
                  <Flex alignItems={'flex-start'}>
                     <Box marginBottom={[4, null, null, 10]}>
                        {id_for_chart && <Label title={id_for_chart} />}
                     </Box>
                  </Flex>
               )}

               <Flex
                  marginBottom={[4, null, null, 6]}
                  alignItems={'flex-start'}
                  className={styles.filter}
                  height={['36px']}
               >
                  <Flex
                     alignItems="center"
                     justifyContent={'flex-start'}
                     className={styles.selectWrapper}
                  >
                     <select
                        onChange={(e) => {
                           const fund = fundTypes[e?.target?.value]
                           setActiveFund(fund)
                           updateNavData(e?.target?.value, fund.type)
                        }}
                        name="graph-type"
                        id="graph-type"
                        value={activeFund.value}
                     >
                        {Object.values(fundTypes).map((item: any) => (
                           <option value={item.value} key={item.value}>
                              {item.name}
                           </option>
                        ))}
                     </select>
                     <div className={styles.arrow}>
                        <NextImage
                           src="/icons/chevron-left.svg"
                           width="10.8"
                           height="17.6"
                           alt="Chevron icon"
                        />
                     </div>
                  </Flex>
               </Flex>
            </Flex>

            <Flex flexDirection={'column'}>
               <Box marginBottom={[6]} className={styles.title} as="span">
                  {activeFund.name}
               </Box>

               <AnyGraph
                  type={activeFund.type}
                  navString={
                     latestNavData && latestNavData.price
                        ? getNavString(latestNavData, locale, t)
                        : ''
                  }
                  downloadString={t('downloadData')}
                  shareClass={latestNavData ? latestNavData.shareClass : ''}
                  data={navData}
               />
            </Flex>

            <Flex
               style={{ position: 'relative' }}
               width={1}
               flexDirection={'column'}
            >
               <div className="anchor extra" id={holdingSectionID} />
               {id_for_holdings && (
                  <Flex alignItems={'flex-start'}>
                     <Box marginBottom={[4, null, null, 10]}>
                        {id_for_holdings && <Label title={id_for_holdings} />}
                     </Box>
                  </Flex>
               )}
               <Flex flexDirection={'column'}>
                  {holdings_title && (
                     <Box
                        className={styles.title}
                        as="h2"
                        marginBottom={[8, null, null, 15]}
                     >
                        {holdings_title}
                     </Box>
                  )}
                  {holdings_introduction && (
                     <Box
                        width={[1, null, null, 7 / 12]}
                        className={styles.intro}
                     >
                        <Markdown>{holdings_introduction}</Markdown>
                     </Box>
                  )}
                  <React.Fragment>
                     {/* {holding_list_title && (
                        <Box
                           className={styles.subTitle}
                           as="h3"
                           // marginTop={[16, null, null, 20]}
                           marginBottom={[0, null, null, 10]}
                        >
                           {`${holding_list_title} ${activeFund.name}`}
                        </Box>
                     )} */}

                     <Box marginTop={[8, null, null, 0]}>
                        {activeFund.type === 'metals' && (
                           <HoldingTable tableName="essentialMetals" />
                        )}
                        {activeFund.type === 'silver' && (
                           <HoldingTable tableName="silverBullet" />
                        )}
                        {activeFund.type === 'green' && (
                           <HoldingTable tableName="preciousGreen" />
                        )}
                        {activeFund.type.includes('etf') && (
                           <HoldingTable tableName="goldMining" />
                        )}
                     </Box>
                     <Flex
                        flexDirection={['column', null, null, 'row']}
                        marginTop={[13, null, null, 25]}
                     >
                        <Box
                           className={styles.belowColumn}
                           width={[1, null, null, 1 / 2]}
                        >
                           {disclaimer && (
                              <Markdown className={styles.belowText}>
                                 {disclaimer}
                              </Markdown>
                           )}
                        </Box>
                        <Box
                           marginY={[5, null, null, 0]}
                           className={styles.separator}
                        />
                        <Box
                           className={styles.belowColumn}
                           width={[1, null, null, 1 / 2]}
                        >
                           {(silver_clarification ||
                              green_clarification ||
                              esgo_clarification ||
                              metals_clarification) && (
                              <Markdown className={styles.belowText}>
                                 {activeFund.type === 'silver'
                                    ? silver_clarification
                                    : activeFund.type.includes('etf')
                                    ? esgo_clarification
                                    : activeFund.type.includes('metals')
                                    ? metals_clarification
                                    : green_clarification}
                              </Markdown>
                           )}
                        </Box>
                     </Flex>
                  </React.Fragment>
               </Flex>
            </Flex>
         </Container>
      </Flex>
   )
}

export default ChartAndHolding
