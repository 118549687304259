import React from 'react'
import { Box } from '../Grid/Grid'
import styles from './InputTextarea.module.scss'
import classnames from 'classnames'
const InputTextarea = ({
   name,
   placeholder,
   register,
   error,
   required = false,
}: {
   name: string
   placeholder: string
   register: any
   error: any
   required?: boolean
}) => {
   return (
      <Box
         className={classnames(
            styles.wrapper,
            error?.message ? styles.error : '',
         )}
      >
         <textarea
            id={name}
            placeholder={`${placeholder}${required ? '*' : ''}`}
            name={name}
            required={required}
            {...register(name)}
         />
         {error?.message && (
            <Box className={styles.error} as="span" marginTop={[2]}>
               Please fill out this field
            </Box>
         )}
      </Box>
   )
}

export default InputTextarea
