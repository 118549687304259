import React, { useEffect } from 'react'
import Link from 'next/link'
import styles from './PreviewBar.module.scss'

const PreviewBar: React.FC = () => {
   useEffect(() => {
      console.log('here', window.location)
      if (
         window &&
         window.location &&
         !window.location.href.includes('secret')
      ) {
         console.log(1, document.getElementById('preview-bar'))

         const el = document.getElementById('preview-bar')
         console.log(3, el)
         if (el) {
            console.log(2)

            el.classList.add('show-preview')
         }
      }
   }, [])

   return (
      <div id="preview-bar" className={styles.wrapper}>
         <p>You view the site in preview mode.</p>
         <Link href={'/api/exit-preview'}>Turn off preview</Link>
      </div>
   )
}

export default PreviewBar
