import React from 'react'
import { Box } from '../Grid/Grid'
import styles from './InputSelect.module.scss'
import classnames from 'classnames'
import NextImage from 'next/image'

const InputSelect = ({
   name,
   placeholder,
   options,
   register,
   error,
   required = false,
}: {
   name: string
   placeholder: string
   options: { label: string; value: string }[] | []
   register: any
   error: any
   required?: boolean
}) => (
   <Box
      className={classnames(styles.wrapper, error?.message ? styles.error : '')}
   >
      <Box className={styles.select}>
         <select
            {...register(name)}
            id={name}
            defaultValue=""
            name={name}
            required={required}
         >
            {placeholder && (
               <option value="" disabled>
                  {`${placeholder}${required ? '*' : ''}`}
               </option>
            )}
            {options &&
               options?.length > 0 &&
               options.map((option: { label: string; value: string }) => (
                  <option key={option?.label} value={option?.value}>
                     {option?.label}
                  </option>
               ))}
         </select>
         <div className={styles.arrow}>
            <NextImage
               src="/icons/chevron-left-grey.svg"
               width="10.8"
               height="17.6"
               alt="Chevron icon"
            />
         </div>
      </Box>
      {error?.message && (
         <Box className={styles.error} marginTop={[2]}>
            Please fill out this field
         </Box>
      )}
   </Box>
)

export default InputSelect
