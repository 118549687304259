import { ref, get, limitToLast, query } from 'firebase/database'
import { getFirebaseDatabase } from './firebase'

export async function getChartData(slug = 'preciousGreen', sliced = false) {
   let response = []
   const navQuery = sliced
      ? query(
           ref(getFirebaseDatabase(), `chartData/${slug}/items`),
           limitToLast(slug === 'goldMining' ? 10 : 3),
        )
      : query(ref(getFirebaseDatabase(), `chartData/${slug}/items`))
   await get(navQuery)
      .then((snapshot) => {
         if (snapshot.exists()) {
            const rawData = snapshot.val()
            response = Object.values(rawData).sort(function (a, b) {
               // Turn your strings into dates, and then subtract them
               // to get a value that is either negative, positive, or zero.
               return new Date(b.date) - new Date(a.date)
            })
            if (sliced) {
               response = response.slice(0, slug === 'goldMining' ? 10 : 2)
            }
         } else {
            console.log('No data available')
         }
      })
      .catch((error) => {
         console.error(error)
      })
   return { data: response }
}
