import React from 'react'
import { PeriodicSystemProps } from './types'
import styles from './PeriodicSystem.module.scss'
import { Flex, Container, Box } from '@components/UI/Grid/Grid'
import { stringify } from '@lib/helpers'
import PeriodicTable from '../PeriodicTable/PeriodicTable'
import PeriodicSlider from '../PeriodicSlider/PeriodicSlider'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'

const PeriodicSystem: React.FC<PeriodicSystemProps> = ({
   blok,
   periodicItems,
}) => {
   const { title, label, description } = blok

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         paddingBottom={[15, null, null, 40]}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Flex
               flexDirection="column"
               alignItems="flex-start"
               className={styles.content}
               width={1}
               paddingTop={[15, null, null, 25]}
               marginBottom={[10, null, null, 20]}
            >
               <Flex alignItems={'flex-start'}>
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
               </Flex>
               <Box
                  width={[1, null, null, 10 / 12]}
                  as="h3"
                  className={styles.title}
               >
                  {title}
               </Box>
               {description && (
                  <Box
                     marginTop={[5, null, null, 6]}
                     width={[1, null, null, 6 / 12]}
                  >
                     <Markdown>{description}</Markdown>
                  </Box>
               )}
            </Flex>
            <Box className="hideMobile">
               <PeriodicTable clickable activeItems={periodicItems} />
            </Box>
            <Box className="hideDesktop">
               <PeriodicSlider activeItems={periodicItems} />
            </Box>
         </Container>
      </Flex>
   )
}

export default PeriodicSystem
