import React, { useRef } from 'react'
import { HeroProps } from './types'
import { Flex, Box, Container, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import styles from './Hero.module.scss'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import { useRouter } from 'next/router'
import { isImage } from '@lib/isImage'
import useTranslation from 'next-translate/useTranslation'
import Video from '@components/Video/Video'

const Hero: React.FC<HeroProps> = ({ blok }) => {
   const { locale } = useRouter()
   const { t } = useTranslation('common')
   const {
      template,
      label,
      title,
      sub_title,
      description,
      cta,
      background,
      media,
      graphic_element,
      smallMobile,
   } = blok

   return (
      <Box
         className={classnames(
            styles.hero,
            template === 'research' ? styles.half : '',
            smallMobile ? styles.smallMobile : '',
         )}
      >
         <Flex
            className={styles.content}
            alignItems="center"
            justifyContent="center"
         >
            <Container>
               <Row>
                  <Column
                     width={[1, null, null, 10 / 12]}
                     display="flex"
                     flexDirection="column"
                     alignItems="flex-start"
                  >
                     {label && <Label title={label ? label : ''} />}
                     {title && (
                        <Box
                           as="h1"
                           className={styles.title}
                           marginTop={[3, null, null, 5]}
                        >
                           {title}
                        </Box>
                     )}
                     {sub_title && (
                        <Box
                           className={styles.subTitle}
                           marginTop={[2, null, null, 2]}
                        >
                           {sub_title}
                        </Box>
                     )}
                  </Column>
                  <Column width={[1, null, null, 6 / 12]}>
                     {description && (
                        <Box
                           className={styles.description}
                           marginTop={[4, null, null, 6]}
                        >
                           {description}
                        </Box>
                     )}
                     {cta && (
                        <Flex
                           marginTop={[12, null, null, null, 12]}
                           flexWrap="wrap"
                        >
                           {cta?.length > 0 &&
                              cta.map(
                                 (blok: CtaButtonStoryblok, index: number) => {
                                    return (
                                       <Box
                                          key={blok._uid}
                                          marginRight={
                                             index < cta?.length - 1
                                                ? [3, null, 4]
                                                : 0
                                          }
                                          marginBottom={[3, null, 4]}
                                       >
                                          <DynamicComponent blok={blok} />
                                       </Box>
                                    )
                                 },
                              )}
                        </Flex>
                     )}
                  </Column>
               </Row>
            </Container>

            {background === 'element' &&
               graphic_element &&
               graphic_element.filename && (
                  <Flex alignItems="center" className={styles.circle}>
                     <Image
                        preload
                        // isCritical
                        sizes={{
                           mobile: '50vw',
                           tablet: '50vw',
                           desktop: '50vw',
                        }}
                        alt="AuAg Graphic"
                        src={graphic_element.filename}
                     />
                  </Flex>
               )}
         </Flex>
         {background === 'media' && media && media?.filename && (
            <Box
               className={classnames(
                  styles.mediaBackground,
                  isImage(media?.filename)
                     ? styles.imageBackground
                     : styles.videoBackground,
               )}
            >
               {isImage(media?.filename) ? (
                  <Image alt={title} src={media.filename} />
               ) : (
                  <Video
                     blok={{
                        _uid: 'video',
                        component: 'video',
                        video: media || null,
                        video_type: 'native',
                     }}
                     autoplay
                  />
               )}
            </Box>
         )}
      </Box>
   )
}

export default Hero
