import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import { MarkdownProps } from './types'
import styles from './Markdown.module.scss'
import { isExternalUrl } from '@lib/helpers'
import Link from 'next/link'

const LinkRenderer = ({
   href = '',
   children,
}: {
   href: string
   children: any
}) => {
   const isExternal = isExternalUrl(href)
   const title = children && children.length > 0 ? children[0] : ''
   const target = isExternal ? '_blank' : ''
   const rel = isExternal ? 'noopener noreferrer' : ''
   if (href && href.startsWith('/en')) {
      href = href.replace('/en/', '/')
   }

   if (href.endsWith('/') && href !== '/' && !isExternal) {
      href = href.slice(0, -1)
   }

   return isExternal ? (
      <a href={href} target={target} rel={rel} title={title}>
         {children}
      </a>
   ) : (
      <Link href={href}>
         <a title={title} target={target} rel={rel}>
            {children}
         </a>
      </Link>
   )
}

const Markdown: React.FC<MarkdownProps> = ({
   children,
   className = '',
   noParagraphs = false,
}) => {
   const plugins = [remarkBreaks]

   /* eslint-disable */
   const markdownProps: any = {
      noParagraphs,
      remarkPlugins: plugins,
      children,
   }

   if (noParagraphs) {
      markdownProps.disallowedTypes = ['paragraph']
      markdownProps.unwrapDisallowed = true
   }

   return (
      <div className={[styles.markdown, className ? className : ''].join(' ')}>
         {children && (
            <ReactMarkdown
               components={{
                  a({ href = '', children }) {
                     return LinkRenderer({ href, children })
                  },
               }}
               {...markdownProps}
            >
               {children}
            </ReactMarkdown>
         )}
      </div>
   )
}

export default Markdown
