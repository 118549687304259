import React from 'react'
import { SelectedPostsProps } from './types'
import styles from './SelectedPosts.module.scss'
import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import PostGrid from '@components/PostGrid/PostGrid'
import { getThemeColor } from '@lib/helpers'

const SelectedPosts: React.FC<SelectedPostsProps> = ({ blok }) => {
   const { title, posts, type, background } = blok

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         paddingY={[10, null, null, 35]}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <Container>
            <Flex
               marginBottom={[0, null, null, 15]}
               width={1}
               alignItems="flex-end"
            >
               <Flex
                  flexDirection="column"
                  className={styles.content}
                  width={7 / 12}
               >
                  <Box as="h3" className={styles.title} marginBottom={[10]}>
                     {title}
                  </Box>
               </Flex>
            </Flex>
            <PostGrid posts={posts} type={type} />
         </Container>
      </Flex>
   )
}

export default SelectedPosts
