import React, { useState } from 'react'
import { ContactFormProps } from './types'
import { Flex, Box } from '@components/UI/Grid/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './ContactForm.module.scss'
import Label from '@components/UI/Label/Label'
import { contactFormSchema } from './schemas'
import Markdown from '@components/UI/Markdown/Markdown'
import CtaButton from '@components/CtaButton/CtaButton'
import InputText from '@components/UI/InputText/InputText'
import axios from 'axios'
import useTranslation from 'next-translate/useTranslation'
import InputTextarea from '@components/UI/InputTextarea/InputTextarea'

const ContactForm: React.FC<ContactFormProps> = ({ blok, story }) => {
   const { t } = useTranslation('common')
   const { label, title, text, hubspot_id, consent_text } = blok || {}
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(false)
   const resolver = yupResolver(contactFormSchema)

   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({
      resolver: resolver,
   })
   if (!hubspot_id) {
      return null
   }
   const onSubmit = async (data: any) => {
      setLoading(true)
      if (data) {
         const portalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID
         const formGuid = hubspot_id

         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         }
         const timeOut = 3000

         const fields: any[] = []

         const formKeys = Object.keys(data)
         formKeys.forEach((key: string) => {
            fields.push({ name: key, value: data[key] })
         })

         if (fields && fields?.length > 0) {
            axios
               .post(
                  `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
                  {
                     portalId,
                     formGuid,
                     fields,
                  },
                  config,
               )
               .then(() => {
                  setLoading(false)
                  setSuccess(true)
                  console.log('Success!')
                  setTimeout(() => {
                     setSuccess(false)
                     reset()
                  }, timeOut)
               })
               .catch((error) => {
                  console.log(error)
                  setLoading(false)
                  setError(true)
                  setTimeout(() => {
                     setError(false)
                     reset()
                  }, timeOut)
               })
         }
      }
   }

   return (
      <React.Fragment>
         <Flex
            width={1}
            className={styles.wrapper}
            alignItems="flex-start"
            flexDirection={['column', null, null, 'row']}
         >
            <Flex
               className={styles.content}
               paddingY={[15, null, null, 20]}
               width={[1, null, null, 1 / 2]}
               flexDirection="column"
               justifyContent="center"
               alignItems="flex-start"
               paddingX={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
            >
               {label && (
                  <Box marginBottom={[5, null, null, 5]}>
                     <Label title={label} />
                  </Box>
               )}
               {title && (
                  <Box
                     as="h1"
                     marginBottom={[5, null, null, 10]}
                     className={styles.title}
                  >
                     {title}
                  </Box>
               )}
               {text && <Box className={styles.text}>{text}</Box>}
            </Flex>
            <Box
               className={styles.formWrapper}
               width={[1, null, null, 1 / 2]}
               bg="var(--white)"
            >
               <form
                  className={styles.formInner}
                  method="POST"
                  name="newsletter"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
               >
                  <Box
                     paddingY={[
                        10,
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                     paddingX={[
                        'var(--container-side-padding-mobile)',
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                  >
                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           name="firstname"
                           placeholder={t('firstName')}
                           register={register}
                           error={errors?.firstname}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           name="lastname"
                           placeholder={t('lastName')}
                           register={register}
                           error={errors?.lastname}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           required
                           name="email"
                           placeholder={t('eMail')}
                           register={register}
                           error={errors?.email}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           name="company"
                           placeholder={t('companyName')}
                           register={register}
                           error={errors?.company}
                        />
                     </Box>
                     <Box marginBottom={[8, null, null, 10]}>
                        <InputTextarea
                           name="message"
                           placeholder={t('message')}
                           register={register}
                           required
                           error={errors?.message}
                        />
                     </Box>
                     {consent_text && (
                        <Box marginBottom={[5]} className={styles.description}>
                           <Markdown>{consent_text}</Markdown>
                        </Box>
                     )}
                     <Box className={styles.submit}>
                        <CtaButton
                           type="submit"
                           blok={{
                              component: 'cta_button',
                              _uid: '1',
                              color: 'purple',
                              background_color: 'lightGray',
                              hover_color: 'purple',
                              link: [
                                 {
                                    component: 'link',
                                    _uid: '1',
                                    title: success
                                       ? 'Success'
                                       : error
                                       ? 'Error'
                                       : loading
                                       ? 'Loading'
                                       : t('submit'),
                                 },
                              ],
                           }}
                        />
                     </Box>
                  </Box>
               </form>
            </Box>
         </Flex>
      </React.Fragment>
   )
}

export default ContactForm
