import React from 'react'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import { LinkProps } from './types'
import { getHrefFromStoryblokLink } from '@lib/helpers'

const LinkStoryblok: React.FC<LinkProps> = ({ blok, className, onClick }) => {
   const { title } = blok || {}

   const href = getHrefFromStoryblokLink({ ...blok })

   return href && title ? (
      <LinkComponent href={href} className={className} onClick={onClick}>
         {title}
      </LinkComponent>
   ) : null
}

export default LinkStoryblok
