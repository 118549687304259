export const getCountryTranslation = (country?: string, locale?: string) => {
   if (locale && locale === 'sv') {
      switch (country) {
         case 'Sweden':
            return 'Sverige'
         case 'Norway':
            return 'Norge'
         case 'Finland':
            return 'Finland'
         case 'Denmark':
            return 'Danmark'
         case 'United Kingdom':
            return 'Storbritannien'
         case 'Italy':
            return 'Italien'
         case 'Germany':
            return 'Tyskland'
         default:
            return country
      }
   }
   if (locale && locale === 'no') {
      switch (country) {
         case 'Sweden':
            return 'Sverige'
         case 'Norway':
            return 'Norge'
         case 'Finland':
            return 'Finland'
         case 'Denmark':
            return 'Danmark'
         case 'United Kingdom':
            return 'Storbritannia'
         case 'Italy':
            return 'Italia'
         case 'Germany':
            return 'Tyskland'
         default:
            return country
      }
   }

   return country
}

export const getNewsletterTranslations = (nextTranslate: any) => {
   const languageOptions = [
      { label: nextTranslate('english'), value: 'en' },
      { label: nextTranslate('swedish'), value: 'sv' },
   ]

   const typeOptions = [
      {
         label: nextTranslate('individualInvestor'),
         value: 'Privat Investerare',
      },
      {
         label: nextTranslate('financialAdvisor'),
         value: 'Professionell Investerare',
      },
      {
         label: nextTranslate('institutionalInvestor'),
         value: 'Institutionell Investerare',
      },
   ]

   const investmentOptions = [
      { label: 'AuAg Silver Bullet', value: 'AuAg Silver Bullet' },
      { label: 'AuAg Precious Green', value: 'AuAg Precious Green' },
      {
         label: 'AuAg Gold Mining',
         value: 'AuAg Gold Mining',
      },
      {
         label: 'AuAg Essential Metals',
         value: 'AuAg Essential Metals',
      },
      { value: nextTranslate('notYet'), label: 'Not yet' },
   ]

   const communicationOptions = [
      {
         label: 'communication',
         value: nextTranslate('otherCommunication'),
      },
   ]
   const newsletterOptions = [
      {
         label: 'monthly',
         value: nextTranslate('monthlyLetters'),
      },
   ]

   const pressOptions = [
      {
         label: 'press',
         value: nextTranslate('pressReleases'),
      },
   ]
   const subscribeTitle = nextTranslate('subscribe')

   return {
      languageOptions,
      pressOptions,
      typeOptions,
      investmentOptions,
      communicationOptions,
      newsletterOptions,
      subscribeTitle,
   }
}
