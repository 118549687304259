import React from 'react'
import { formatImage } from '@lib/formatImage'

import { Props, Breakpoints } from './types'
import Head from 'next/head'

const getSrcSet = (src: string, type: string, ratio?: number) => {
   const array = []
   array.push(`${formatImage(src, 200, ratio)} 200w`)
   array.push(`${formatImage(src, 400, ratio)} 400w`)
   array.push(`${formatImage(src, 800, ratio)} 800w`)
   array.push(`${formatImage(src, 1200, ratio)} 1200w`)
   array.push(`${formatImage(src, 1600, ratio)} 1600w`)
   array.push(`${formatImage(src, 2400, ratio)} 2400w`)
   return array.join(', ')
}

export const breakpoints: Breakpoints = {
   mobile: '(max-width: 767px)',
   tablet: '(min-width: 768px) and (max-width: 1023px)',
   desktop: '(min-width: 1024px)',
}

class Image extends React.Component<Props> {
   imageRef: any
   observer: any

   static defaultProps = {
      sizes: {
         mobile: '100vw',
         tablet: '50vw',
         desktop: '50vw',
      },
   }

   constructor(props: Props) {
      super(props)
      if (process.browser) {
         this.observer = new IntersectionObserver(this.observeHandler, {
            rootMargin: '0px 0px 500px 0px',
         })
      }
   }

   observeHandler = (entries: any) => {
      const { onImageLoaded } = this.props
      for (const entry of entries) {
         if (entry.isIntersecting) {
            const src = this.imageRef.getAttribute('data-src') || ''
            const srcSet = this.imageRef.getAttribute('data-srcset') || ''
            this.imageRef.setAttribute('src', src)
            this.imageRef.setAttribute('srcset', srcSet)
            this.imageRef.onload = () => {
               this.imageRef.style.opacity = 1
               if (onImageLoaded) {
                  onImageLoaded()
               }
            }
            this.observer.unobserve(entry.target)
         }
      }
   }

   get placeholder() {
      const { ratio, src, offScreen } = this.props
      if (!ratio && !offScreen) {
         // Return an empty pixel
         return formatImage(src, 200, ratio)
      }
      const viewBox = `0 0 ${ratio} 1`
      return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
         '#FFFFFF00',
      )}" width="100%" height="100%" /></svg>`
   }

   getSizes() {
      const { sizes } = this.props
      if (typeof sizes === 'string') {
         return sizes
      } else if (typeof sizes === 'object') {
         return Object.keys(sizes)
            .map((width: string) => `${breakpoints[width]} ${sizes[width]}`)
            .join(',')
      }
      return ''
   }

   render() {
      let { className } = this.props
      const {
         src,
         alt,
         ratio,
         isCritical,
         type = 'storyblok',
         preload,
      } = this.props

      // Removed offscreen since it buggs

      if (!src) return null
      if (className) {
         className = className + ' o-image'
      } else {
         className = 'o-image'
      }
      if (isCritical) {
         className = className + ' critical'
      }

      const srcSet = getSrcSet(src, type, ratio)
      const formatSrc = formatImage(src, 2400, ratio)

      if (src.endsWith('.svg')) {
         return (
            <img
               ref={(x) => {
                  if (x && !isCritical) {
                     this.observer.observe(x)
                     this.imageRef = x
                  }
               }}
               className={className}
               // sizes={this.getSizes()}
               data-src={src}
               src={isCritical ? src : this.placeholder}
               alt={alt}
            />
         )
      }

      /* eslint-disable */
      return (
         <React.Fragment>
            {preload && (
               <Head>
                  <link
                     rel="preload"
                     as="image"
                     href={formatSrc}
                     imageSrcSet={srcSet}
                     sizes={this.getSizes()}
                  />
               </Head>
            )}
            <img
               ref={(x) => {
                  if (x && !isCritical) {
                     this.observer.observe(x)
                     this.imageRef = x
                  }
               }}
               className={className}
               data-srcset={srcSet}
               sizes={this.getSizes()}
               data-src={formatSrc}
               src={isCritical ? formatSrc : this.placeholder}
               alt={alt}
            />
         </React.Fragment>
      )
      /* eslint-enable */
   }
}

export default Image
