import React from 'react'
import { SolutionCardProps } from './types'
import styles from './SolutionCard.module.scss'
import Markdown from '@components/UI/Markdown/Markdown'

import { CtaButtonStoryblok, LinkStoryblok } from 'types/storyblok-components'
import { Box, Flex } from '@components/UI/Grid/Grid'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Image from '@components/UI/Image/Image'

const LinkWrapper = ({
   children,
   link,
}: {
   children: any
   link: false | LinkStoryblok | undefined
}) =>
   link ? (
      <LinkComponent className={styles.link} href={link.link?.cached_url}>
         {children}
      </LinkComponent>
   ) : (
      <Box className={styles.box}>{children}</Box>
   )

const SolutionCard: React.FC<SolutionCardProps> = ({ blok }) => {
   const { title, text, link, hover_image } = blok
   const linkItem =
      link && link.length > 0 && link[0] && link[0].link && link[0].link[0]
   return (
      <LinkWrapper link={linkItem}>
         <Box
            paddingTop={[0, null, null, 30, 40]}
            width={1}
            className={styles.wrapper}
            height={['100%', null, null, '100%']}
         >
            <Flex
               flexDirection="column"
               alignItems="flex-start"
               className={styles.content}
            >
               {hover_image && hover_image.filename && (
                  <Image
                     alt={title}
                     offScreen={true}
                     className={styles.hoverImage}
                     src={hover_image.filename}
                  />
               )}
               <Flex
                  className={styles.info}
                  flexDirection="column"
                  paddingBottom={[10, null, null, 10, 20]}
               >
                  <Box
                     as="h4"
                     marginTop={[5, null, null, 0]}
                     marginBottom={[5, null, null, 10]}
                  >
                     {title}
                  </Box>
                  {text && <Markdown className={styles.text}>{text}</Markdown>}
               </Flex>
               {link && link?.length > 0
                  ? link.map((blok: CtaButtonStoryblok) => {
                       const linkItem =
                          blok.link && blok.link[0] ? blok.link[0] : {}

                       return (
                          <Box
                             marginTop={['auto', null, null, 'auto']}
                             key={blok._uid}
                             className={styles.button}
                          >
                             <DynamicComponent
                                type="button"
                                blok={{
                                   ...blok,
                                   link: [
                                      {
                                         ...linkItem,
                                         link: {
                                            chached_url: '',
                                         },
                                      },
                                   ],
                                }}
                             />
                          </Box>
                       )
                    })
                  : null}
            </Flex>
         </Box>
      </LinkWrapper>
   )
}

export default SolutionCard
