import React from 'react'
import Link from 'next/link'

import { LinkComponentProps } from './types'
import { config } from 'config'

import { isExternalUrl } from '@lib/helpers'
import { StripCurrentLocale } from '@lib/helpers'

const LinkComponent: React.FC<LinkComponentProps> = ({
   children,
   href,
   title,
   className,
   onClick,
   onMouseEnter,
   onMouseLeave,
   ariaLabel,
}) => {
   if (!href) {
      return <React.Fragment>{children}</React.Fragment>
   }

   const isDownload = href.substr(0, 9) === 'data:text'
   const isExternal = isExternalUrl(href) || isDownload
   const hash = href.split('#')[1] ? `#${href.split('#')[1]}` : ''
   const hrefWithoutHash = href.split('#')[0]
   const hrefWithoutHashAndLocale = StripCurrentLocale(hrefWithoutHash)

   // Check for missing "/" in relative urls
   if (!isExternal && !href.startsWith('/')) {
      href = `/${href}`
   }

   // Remove trailing slash
   if (!isExternal && href.endsWith('/') && href !== '/') {
      href = href.slice(0, -1)
   }

   // Remove home slug from internal links
   if (
      (!isExternal &&
         config?.storyblok?.home?.slug ===
            hrefWithoutHashAndLocale.split('/')[1]) ||
      (!isExternal &&
         config?.storyblok?.home?.slug ===
            hrefWithoutHashAndLocale.split('/')[0])
   ) {
      href = `${href.substring(0, href.lastIndexOf('/') + 1)}${
         hash ? `${hash}` : ''
      }`
   }

   return isExternal ? (
      <a
         href={href}
         title={title}
         className={className}
         onClick={onClick}
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
         download={isDownload}
         target="_blank"
         rel="noopener noreferrer"
         aria-label={ariaLabel ? ariaLabel : ''}
      >
         {children}
      </a>
   ) : (
      <Link href={href}>
         <a
            className={className}
            title={title}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            aria-label={ariaLabel ? ariaLabel : ''}
         >
            {children}
         </a>
      </Link>
   )
}

export default LinkComponent
