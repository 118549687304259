import React from 'react'
import { TextProps } from './types'
import styles from './Text.module.scss'
import { Box } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'

const Text: React.FC<TextProps> = ({ blok }) => {
   const { text } = blok
   return (
      <Box className={styles.markdown}>
         <Markdown>{text}</Markdown>
      </Box>
   )
}

export default Text
