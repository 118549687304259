import React from 'react'
import styles from './Files.module.scss'
import { Flex, Box } from '@components/UI/Grid/Grid'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import Image from '@components/UI/Image/Image'
import useTranslation from 'next-translate/useTranslation'

const Files = ({ blok }: any) => {
   const { t } = useTranslation('common')

   const { files } = blok
   return (
      <Box className={styles.wrapper}>
         <Box className={styles.files}>
            {files &&
               files.length > 0 &&
               files.map((blok: any) => {
                  const { icon, file, title, description } = blok
                  return (
                     <Flex key={blok._uid} className={styles.file}>
                        <Flex
                           className={styles.icon}
                           alignItems="center"
                           justifyContent="center"
                        >
                           {icon?.filename && (
                              <Image
                                 offScreen
                                 alt={icon.alt}
                                 src={icon.filename}
                              />
                           )}
                        </Flex>
                        <Box className={styles.content} padding={[5]}>
                           {title && (
                              <Box className={styles.title}>{title}</Box>
                           )}
                           {description && (
                              <Box
                                 marginTop={[2]}
                                 className={styles.description}
                              >
                                 {description}
                              </Box>
                           )}
                           {file?.filename && (
                              <Box marginTop={[4]} className={styles.link}>
                                 <LinkComponent href={file.filename}>
                                    {t('readMore')}
                                 </LinkComponent>
                              </Box>
                           )}
                        </Box>
                     </Flex>
                  )
               })}
         </Box>
      </Box>
   )
}

export default Files
