import React from 'react'
// import styles from './GraphicWave.module.scss'
import Image from '@components/UI/Image/Image'

const GraphicWave = ({ className }: { className?: string }) => (
   <Image
      alt="AuAg Graphic"
      className={className}
      src={'https://a.storyblok.com/f/135633/1908x337/a81f78bb57/graphic.png'}
   />
)

export default GraphicWave
