import { useAppContext } from '@context/state'
import { SbDataSourceEntry } from 'types/storyblok-config'
import { SbDataSourceProps } from 'types/storyblok-props'

export const GetDataSourceEntryDimensionValue = (
   id: string | null,
   value: string | null,
) => {
   const { dataSources } = useAppContext()

   const dataSource =
      id && dataSources && dataSources?.length > 0
         ? dataSources.find(
              (item: SbDataSourceProps) => item?.dataSource?.slug === id,
           )
         : { dataSource: {}, dataSourceEntries: [] }

   const dataSourceEntry =
      dataSource &&
      dataSource?.dataSourceEntries &&
      dataSource?.dataSourceEntries?.length > 0
         ? dataSource.dataSourceEntries.find(
              (dataSourceEntry: SbDataSourceEntry) =>
                 dataSourceEntry?.value === value,
           )
         : { dimension_value: '' }

   const dimensionValue = dataSourceEntry?.dimension_value
      ? dataSourceEntry.dimension_value
      : ''

   if (!dimensionValue) {
      return value
   }

   return dimensionValue
}

export const GetDataSourceEntries = (id: string | null) => {
   const { dataSources } = useAppContext()

   const dataSource =
      id && dataSources && dataSources?.length > 0
         ? dataSources.find(
              (item: SbDataSourceProps) => item?.dataSource?.slug === id,
           )
         : { dataSource: {}, dataSourceEntries: [] }

   const dataSourceEntries: SbDataSourceEntry[] =
      dataSource?.dataSourceEntries || []

   return dataSourceEntries
}

export const GetDataSourceEntryByValue = (
   id: string | null,
   value: string | null,
) => {
   const { dataSources } = useAppContext()

   const dataSource =
      id && dataSources && dataSources?.length > 0
         ? dataSources.find(
              (item: SbDataSourceProps) => item?.dataSource?.slug === id,
           )
         : { dataSource: {}, dataSourceEntries: [] }

   const dataSourceEntry =
      dataSource &&
      dataSource?.dataSourceEntries &&
      dataSource?.dataSourceEntries?.length > 0
         ? dataSource.dataSourceEntries.find(
              (dataSourceEntry: SbDataSourceEntry) =>
                 dataSourceEntry?.value === value,
           )
         : { dimension_value: '', value: '', name: '' }

   return dataSourceEntry
}

export const GetDataSourceEntryByName = (
   id: string | null,
   name: string | null,
) => {
   const { dataSources } = useAppContext()

   const dataSource =
      id && dataSources && dataSources?.length > 0
         ? dataSources.find(
              (item: SbDataSourceProps) => item?.dataSource?.slug === id,
           )
         : { dataSource: {}, dataSourceEntries: [] }

   const dataSourceEntry =
      dataSource &&
      dataSource?.dataSourceEntries &&
      dataSource?.dataSourceEntries?.length > 0
         ? dataSource.dataSourceEntries.find(
              (dataSourceEntry: SbDataSourceEntry) =>
                 dataSourceEntry?.name === name,
           )
         : { dimension_value: '', value: '', name: '' }

   return dataSourceEntry
}

export const GetDataSourceEntryIndexByValue = (
   id: string | null,
   value: string | null,
) => {
   const { dataSources } = useAppContext()

   const dataSource =
      id && dataSources && dataSources?.length > 0
         ? dataSources.find(
              (item: SbDataSourceProps) => item?.dataSource?.slug === id,
           )
         : { dataSource: {}, dataSourceEntries: [] }

   const dataSourceEntryIndex =
      dataSource &&
      dataSource?.dataSourceEntries &&
      dataSource?.dataSourceEntries?.length > 0
         ? dataSource.dataSourceEntries.findIndex(
              (dataSourceEntry: SbDataSourceEntry) =>
                 dataSourceEntry?.value === value,
           )
         : -1

   return dataSourceEntryIndex
}
