import React, { useEffect, useRef, useState } from 'react'
import { VideoProps } from './types'
import styles from './Video.module.scss'

import { Box, Flex } from '@components/UI/Grid/Grid'
import YouTube from 'react-youtube'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import Head from 'next/head'

const Video: React.FC<VideoProps> = ({
   blok,
   withScrim = false,
   autoplay = false,
}) => {
   const nativeRef = useRef<any>(null)
   const wrapperRef = useRef(null)
   const [started, setStarted] = useState(false)
   const [youtubeRef, setYoutubeRef] = useState<any>(null)
   const [loaded, setLoaded] = useState(false)

   const {
      poster_image,
      video_type,
      video,
      youtube_id,
      small,
      schema_name,
      schema_description,
      schema_thumbnail,
      schema_upload_date,
      schema_duration,
      schema_url,
   } = blok

   const schema = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: `${schema_name}`,
      description: `${schema_description}`,
      thumbnailUrl: `${schema_thumbnail}`,
      uploadDate: `${schema_upload_date}`,
      duration: `${schema_duration}`,
      contentUrl: `${schema_url}`,
   }

   const observeHandler = (entries: any) => {
      for (const entry of entries) {
         if (entry.isIntersecting && !loaded) {
            setLoaded(true)
            if (autoplay) {
               playVideo()
            }
         }
      }
   }

   const observer = process.browser
      ? new IntersectionObserver(observeHandler, {
           rootMargin: '0px 0px 0px 0px',
        })
      : null

   useEffect(() => {
      if (wrapperRef && wrapperRef.current) {
         observer?.observe(wrapperRef.current)
      }
      return () => {
         observer?.disconnect()
      }
   }, [])

   const youtubeOptions: any = {
      height: '100%',
      width: '100%',
      playerVars: {
         controls: 0,
         disablekb: 1,
         loop: 0,
         modestbranding: 1,
      },
   }

   const onYoutubeReady = (e: any) => {
      if (e?.target) {
         setYoutubeRef(e.target)
      }
   }

   const playVideo = () => {
      console.log('play video')
      setStarted(true)
      if (video_type === 'native' && nativeRef?.current?.play) {
         nativeRef.current.play()
      }
      if (video_type === 'youtube' && youtubeRef?.playVideo) {
         youtubeRef.playVideo()
      }
   }

   return (
      <Flex
         ref={wrapperRef}
         className={classnames(
            styles.videoWrapper,
            withScrim ? styles.scrim : '',
         )}
         width={[1]}
      >
         {schema_name && (
            <Head>
               <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                     __html: JSON.stringify(schema),
                  }}
                  key="item-jsonld-video"
               />
            </Head>
         )}
         {video_type === 'youtube' && youtube_id ? (
            <Box className={styles.youtubeVideo}>
               {loaded && (
                  <YouTube
                     onReady={onYoutubeReady}
                     opts={youtubeOptions}
                     videoId={youtube_id}
                  />
               )}
            </Box>
         ) : video_type === 'native' && video?.filename ? (
            <video
               muted={autoplay}
               ref={nativeRef}
               className={styles.video}
               controls={started && !autoplay}
               autoPlay={autoplay}
               loop={autoplay}
               playsInline={autoplay}
            >
               <source src={video.filename} type="video/mp4" />
            </video>
         ) : (
            <Box className={styles.placeholder}>
               <Flex
                  justifyContent="center"
                  alignItems="center"
                  className={styles.placeholderContent}
               >
                  <Box>
                     {video_type === 'native'
                        ? 'Upload video file'
                        : video_type === 'youtube'
                        ? 'Enter youtube id'
                        : ''}
                  </Box>
               </Flex>
            </Box>
         )}
         {((video_type === 'native' && video && !autoplay) ||
            (video_type === 'youtube' && youtube_id)) && (
            <Flex
               as="button"
               justifyContent="center"
               alignItems="center"
               flexDirection="column"
               aria-label="Play Video"
               className={
                  started
                     ? [styles.button, styles.hidden].join(' ')
                     : styles.button
               }
               onClick={() => playVideo()}
            >
               <Box className={[styles.overlay, 'overlay'].join(' ')} />
               {poster_image && poster_image?.filename ? (
                  <Image
                     src={poster_image?.filename}
                     alt={poster_image?.title}
                  />
               ) : youtube_id ? (
                  <img
                     loading="lazy"
                     src={`https://img.youtube.com/vi/${youtube_id}/0.jpg`}
                     alt={
                        poster_image?.title
                           ? poster_image?.title
                           : 'AuAg Youtube video'
                     }
                  />
               ) : null}
               <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={small ? '45' : '65'}
                     height={small ? '55' : '75'}
                     viewBox="0 0 65 75"
                  >
                     <g
                        id="Polygon_1"
                        data-name="Polygon 1"
                        transform="translate(65) rotate(90)"
                        fill="none"
                     >
                        <path
                           d="M34.035,6.006a4,4,0,0,1,6.929,0L71.539,59a4,4,0,0,1-3.465,6H6.926a4,4,0,0,1-3.465-6Z"
                           stroke="none"
                           fill="rgba(255,255,255,0)"
                        />
                        <path
                           d="M 37.49999237060547 5.004440307617188 C 36.41503524780273 5.004440307617188 35.4436149597168 5.565502166748047 34.90144348144531 6.505271911621094 L 4.32708740234375 59.50083160400391 C 3.785202026367188 60.44009017944336 3.785476684570312 61.56143188476562 4.327804565429688 62.50041961669922 C 4.870124816894531 63.43941116333008 5.841285705566406 64 6.925636291503906 64 L 68.07435607910156 64 C 69.15871429443359 64 70.12986755371094 63.43941116333008 70.67219543457031 62.50041961669922 C 71.21452331542969 61.56143188476562 71.21479034423828 60.44009017944336 70.67291259765625 59.50083160400391 L 40.09854507446289 6.505271911621094 C 39.55637359619141 5.565502166748047 38.58495330810547 5.004440307617188 37.49999237060547 5.004440307617188 M 37.49999618530273 4.004440307617188 C 38.84753799438477 4.004440307617188 40.1950798034668 4.671474456787109 40.9647331237793 6.005550384521484 L 71.53910064697266 59.0011100769043 C 73.07756042480469 61.66778182983398 71.15299224853516 65 68.07435607910156 65 L 6.925636291503906 65 C 3.847000122070312 65 1.922431945800781 61.66778182983398 3.460891723632812 59.0011100769043 L 34.03525543212891 6.005550384521484 C 34.80491256713867 4.671474456787109 36.15245819091797 4.004440307617188 37.49999618530273 4.004440307617188 Z"
                           stroke="none"
                           fill="#fff"
                        />
                     </g>
                  </svg>
                  <Box className={styles.label} marginTop={[2]}>
                     Play video
                  </Box>
               </Flex>
            </Flex>
         )}
      </Flex>
   )
}

export default Video
