import React, { useRef } from 'react'
import { HighlightsProps } from './types'
import styles from './Highlights.module.scss'
import { Box, Column, Row, Container, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { stringify } from '@lib/helpers'
import Image from '@components/UI/Image/Image'
import { motion, useScroll, useTransform } from 'framer-motion'
import classnames from 'classnames'

const Highlights: React.FC<HighlightsProps> = ({ blok }) => {
   const { label, title, description, list } = blok
   const sectionRef = useRef(null)
   const { scrollYProgress } = useScroll({
      target: sectionRef,
      offset: ['start end', 'end end'],
   })
   const animate = scrollYProgress
   const animateGlowOpacity = useTransform(scrollYProgress, [0.8, 1], [0, 0.5])
   const animateGlowY = useTransform(scrollYProgress, [0.8, 1], ['100%', '50%'])
   const animateTextOpacity = useTransform(scrollYProgress, [0, 0.5], [0, 1])
   const AnimateColumnOpacity = (index: number) =>
      useTransform(animate, [0.4 + index / 10, 1], [0, 1])
   const AnimateColumnY = (index: number) =>
      useTransform(animate, [0.4 + (index * 2) / 10, 1], [60, 0])
   return (
      <Flex
         ref={sectionRef}
         as="section"
         className={classnames(styles.wrapper, 'dark-section')}
         paddingTop={[10, null, null, 20]}
         paddingBottom={[20, null, null, 40]}
      >
         <motion.div
            className={styles.glow}
            style={{
               opacity: animateGlowOpacity,
               y: animateGlowY,
            }}
         />
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Row
               justifyContent="center"
               alignItems="center"
               flexDirection="column"
            >
               <Column width={[1, null, null, 7 / 12]}>
                  {(label || title || description) && (
                     <Box className={styles.content}>
                        {label && (
                           <motion.div
                              style={{
                                 opacity: animateTextOpacity,
                              }}
                           >
                              <Box>
                                 <Label title={label} />
                              </Box>
                           </motion.div>
                        )}
                        {title && (
                           <motion.div
                              style={{
                                 opacity: animateTextOpacity,
                              }}
                           >
                              <Box
                                 as="h3"
                                 className={styles.title}
                                 marginTop={[4, null, null, 6]}
                              >
                                 {title}
                              </Box>
                           </motion.div>
                        )}
                        {description && (
                           <motion.div
                              style={{
                                 opacity: animateTextOpacity,
                              }}
                           >
                              <Box
                                 as="p"
                                 className={styles.text}
                                 marginTop={[5, null, null, 6]}
                              >
                                 {description}
                              </Box>
                           </motion.div>
                        )}
                     </Box>
                  )}
               </Column>
            </Row>
            {list && list.length > 0 && (
               <Box marginTop={[10, null, null, 15]}>
                  <Row>
                     {list.map((block: any, index: number) => {
                        const { icon, title, text, _uid } = block || {}

                        return (
                           <Column width={[1, null, null, 4 / 12]} key={_uid}>
                              <motion.div
                                 style={{
                                    opacity: AnimateColumnOpacity(index),
                                    y: AnimateColumnY(index),
                                 }}
                              >
                                 <Box
                                    marginBottom={
                                       index < list.length - 1
                                          ? [8, null, null, 0]
                                          : 0
                                    }
                                    className={styles.listItem}
                                 >
                                    <Flex
                                       flexDirection="column"
                                       alignItems="center"
                                       width={1}
                                       className={styles.listContent}
                                    >
                                       {icon?.filename && (
                                          <Flex
                                             justifyContent="center"
                                             alignItems="center"
                                             className={styles.listIcon}
                                          >
                                             <Image
                                                src={icon?.filename}
                                                alt={icon?.alt}
                                                sizes={{
                                                   mobile: '50px',
                                                   tablet: '50px',
                                                   desktop: '50px',
                                                }}
                                             />
                                          </Flex>
                                       )}
                                       {title && (
                                          <Box
                                             as="h3"
                                             className={styles.listTitle}
                                             marginTop={[3, null, null, 3]}
                                          >
                                             {title}
                                          </Box>
                                       )}
                                       {text && (
                                          <Box
                                             as="span"
                                             className={styles.listText}
                                             marginTop={[3, null, null, 3]}
                                          >
                                             {text}
                                          </Box>
                                       )}
                                    </Flex>
                                 </Box>
                              </motion.div>
                           </Column>
                        )
                     })}
                  </Row>
               </Box>
            )}
         </Container>
      </Flex>
   )
}

export default Highlights
