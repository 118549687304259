import { createContext, useContext } from 'react'
export const AppContext = createContext({ dataSources: [] })
import { SbDataSourceProps } from 'types/storyblok-props'
import { SeoStoryblok } from 'types/storyblok-components'
import { ReactNode } from 'react'

export interface DefaultContext {
   dataSources: SbDataSourceProps[] | any
   fallbackSeo?: SeoStoryblok | any
}

export interface Props {
   children: ReactNode
   defaultValue: DefaultContext
}
export function AppWrapper({ children, defaultValue }: Props) {
   const sharedState = { ...defaultValue }

   return (
      <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
   )
}

export function useAppContext() {
   return useContext(AppContext)
}
