import { Box, Flex } from '@components/UI/Grid/Grid'
import React, { Fragment, useEffect, useState } from 'react'
import styles from './PeriodicTable.module.scss'
import { periodicSchema, periodicSchemaExtra } from '@lib/periodicTable'
import Markdown from '@components/UI/Markdown/Markdown'

const ActiveItem = ({ item }: { item: any }) => {
   return (
      <Flex
         width={1}
         maxWidth={['200px', null, null, '360px', '420px']}
         padding={[4, null, null, null, 8, 9]}
         flexDirection="column"
         style={{ top: `${item.row * 5}vw` }}
         className={styles.activeItem}
      >
         <Box as="span" className={styles.icon}>
            {item.icon}
         </Box>
         <Box
            paddingBottom={[2]}
            marginBottom={[3]}
            className={styles.title}
            as="span"
         >
            {item.title}
         </Box>
         {item.text && <Markdown className={styles.text}>{item.text}</Markdown>}
      </Flex>
   )
}

const PeriodicTable = ({
   activeItems,
   clickable,
}: {
   activeItems: any
   clickable?: boolean
}) => {
   const [animatedIds, setAnimatedIds]: any = useState([])
   const [activeItem, setActiveItem]: any = useState(null)
   const activeIds = activeItems
      ? activeItems.map((item: any) => parseInt(item.number))
      : []
   const snakeIds = [79]

   const randomAnimateIds = () => {
      const newIds = []
      const randomItem1: any =
         activeIds[Math.floor(Math.random() * activeIds.length)]
      const randomItem2: any =
         activeIds[Math.floor(Math.random() * activeIds.length)]
      const randomItem3: any =
         activeIds[Math.floor(Math.random() * activeIds.length)]
      if (animatedIds && !animatedIds.includes(randomItem1)) {
         newIds.push(randomItem1)
      }
      if (animatedIds && !animatedIds.includes(randomItem2)) {
         newIds.push(randomItem2)
      }
      if (animatedIds && !animatedIds.includes(randomItem3)) {
         newIds.push(randomItem3)
      }
      setAnimatedIds(newIds)
   }

   const activeAnimation = () => {
      setTimeout(() => {
         randomAnimateIds()
      }, 2000)
      setInterval(randomAnimateIds, 10000)
   }

   useEffect(() => {
      activeAnimation()
   }, [])
   return (
      <Box width={1}>
         {activeItem !== null && (
            <Box
               onClick={() => {
                  if (clickable && activeItem) {
                     setActiveItem(null)
                  }
               }}
               className={styles.overlay}
            />
         )}
         <Flex
            onClick={() => {
               if (clickable && activeItem) {
                  setActiveItem(null)
               }
            }}
            style={{ position: 'relative', zIndex: 'var(--z-index-popup)' }}
            flexDirection="column"
         >
            {clickable && activeItem && <ActiveItem item={activeItem} />}
            {periodicSchema &&
               periodicSchema.length > 0 &&
               periodicSchema.map((row: any, index: number) => (
                  <Flex width={1} flexDirection="row" key={`row-` + index}>
                     {row.items &&
                        row.items.map((item: any, subIndex: number) =>
                           item.title === 'empty' ? (
                              <Flex
                                 width={`${(1 / 18) * 100 * item.columns}%`}
                                 key={`extra-${index}-${subIndex}`}
                                 className={
                                    item.info
                                       ? item.full
                                          ? [styles.info, styles.full].join(' ')
                                          : styles.info
                                       : ''
                                 }
                                 alignItems="flex-end"
                              >
                                 {item.info && (
                                    <Flex
                                       className={styles.infoWrapper}
                                       justifyContent="center"
                                       width={1}
                                    >
                                       <Flex
                                          alignItems={'center'}
                                          className={styles.content}
                                       >
                                          {item.info}
                                       </Flex>
                                    </Flex>
                                 )}
                              </Flex>
                           ) : (
                              <Box
                                 className={
                                    activeIds.includes(item.number)
                                       ? [
                                            styles.item,
                                            styles.active,
                                            animatedIds.includes(item.number)
                                               ? styles.highlight
                                               : '',
                                            clickable ? styles.hover : '',
                                         ].join(' ')
                                       : styles.item
                                 }
                                 style={
                                    animatedIds.includes(item.number)
                                       ? {
                                            animationDelay: `${Math.floor(
                                               Math.random() * (4 - 1 + 1) + 1,
                                            )}s`,
                                         }
                                       : undefined
                                 }
                                 onClick={(e: any) => {
                                    if (
                                       activeIds.includes(item.number) &&
                                       clickable
                                    ) {
                                       const activeItem =
                                          item.number && activeItems
                                             ? activeItems.find(
                                                  (innerItem: any) => {
                                                     return (
                                                        item.number ===
                                                        parseInt(
                                                           innerItem.number,
                                                        )
                                                     )
                                                  },
                                               )
                                             : null
                                       if (activeItem) {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          activeItem.icon = item.title
                                          activeItem.row = index
                                       }

                                       setActiveItem(activeItem)
                                    }
                                 }}
                                 width={1 / 18}
                                 key={item.number}
                              >
                                 <Box as="div" className={styles.top} />
                                 <Box as="div" className={styles.bottom} />
                                 <Box as="div" className={styles.right} />
                                 <Box as="div" className={styles.left} />
                                 {snakeIds.includes(item.number) && (
                                    <Box className={styles.snake}>
                                       <div className={styles.snakeBoxBot}>
                                          <Box className={styles.snakeMid} />
                                          <Box
                                             className={styles.snakeBotRight}
                                          />
                                          <Box className={styles.snakeBot} />
                                          <Box
                                             className={styles.snakeBotLeft}
                                          />
                                       </div>
                                       <div className={styles.snakeBoxTop}>
                                          <Box className={styles.snakeTop} />
                                          <Box
                                             className={styles.snakeTopRight}
                                          />
                                          <Box
                                             className={styles.snakeTopLeft}
                                          />
                                       </div>
                                    </Box>
                                 )}
                                 {activeIds.includes(item.number) && (
                                    <React.Fragment>
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.topLeft,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.topRight,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.bottomRight,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.bottomLeft,
                                          ].join(' ')}
                                       />
                                    </React.Fragment>
                                 )}
                                 <Box className={styles.number}>
                                    {item.number}
                                 </Box>
                                 <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    className={styles.content}
                                 >
                                    {item.title}
                                 </Flex>
                              </Box>
                           ),
                        )}
                  </Flex>
               ))}

            <Flex width={1}>
               <Box className={styles.empty} width={1 / 18}></Box>
               <Box className={styles.empty} width={1 / 18}></Box>
               <Box
                  className={[styles.empty, styles.fullLine].join(' ')}
                  width={1 / 18}
               ></Box>
            </Flex>
            {periodicSchemaExtra &&
               periodicSchemaExtra.length > 0 &&
               periodicSchemaExtra.map((row: any, index: number) => (
                  <Flex width={1} flexDirection="row" key={`extra-${index}`}>
                     {row.items &&
                        row.items.map((item: any, subIndex: number) =>
                           item.title === 'empty' ? (
                              <Flex
                                 key={`extra-${index}-${subIndex}`}
                                 width={`${(1 / 18) * 100 * item.columns}%`}
                                 className={
                                    item.info
                                       ? item.full
                                          ? [styles.info, styles.full].join(' ')
                                          : styles.info
                                       : ''
                                 }
                                 alignItems="flex-end"
                              >
                                 {item.info && (
                                    <Flex
                                       className={styles.infoWrapper}
                                       justifyContent="center"
                                       width={1}
                                    >
                                       <Flex className={styles.content}>
                                          {item.info}
                                       </Flex>
                                    </Flex>
                                 )}
                              </Flex>
                           ) : item.title === 'combine' ? (
                              <Box
                                 key={`extra-${index}-${subIndex}`}
                                 className={[
                                    styles.fullLine,
                                    styles.combine,
                                    item.half ? styles.half : '',
                                 ].join(' ')}
                                 width={1 / 18}
                              ></Box>
                           ) : (
                              <Box
                                 key={`extra-${index}-${subIndex}`}
                                 width={1 / 18}
                                 className={
                                    activeIds.includes(item.number)
                                       ? [
                                            styles.item,
                                            styles.active,
                                            animatedIds.includes(item.number)
                                               ? styles.highlight
                                               : '',
                                            clickable ? styles.hover : '',
                                         ].join(' ')
                                       : styles.item
                                 }
                                 style={
                                    animatedIds.includes(item.number)
                                       ? {
                                            animationDelay: `${Math.floor(
                                               Math.random() * (4 - 1 + 1) + 1,
                                            )}s`,
                                         }
                                       : undefined
                                 }
                                 onClick={(e: any) => {
                                    if (
                                       activeIds.includes(item.number) &&
                                       clickable
                                    ) {
                                       const activeItem =
                                          item.number && activeItems
                                             ? activeItems.find(
                                                  (innerItem: any) => {
                                                     return (
                                                        item.number ===
                                                        parseInt(
                                                           innerItem.number,
                                                        )
                                                     )
                                                  },
                                               )
                                             : null
                                       if (activeItem) {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          activeItem.icon = item.title
                                          activeItem.row = index + 8
                                       }

                                       setActiveItem(activeItem)
                                    }
                                 }}
                              >
                                 <Box className={styles.top} />
                                 <Box className={styles.bottom} />
                                 <Box className={styles.right} />
                                 <Box className={styles.left} />
                                 {activeIds.includes(item.number) && (
                                    <React.Fragment>
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.topLeft,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.topRight,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.bottomRight,
                                          ].join(' ')}
                                       />
                                       <Box
                                          className={[
                                             styles.box,
                                             styles.bottomLeft,
                                          ].join(' ')}
                                       />
                                    </React.Fragment>
                                 )}
                                 <Box className={styles.number}>
                                    {item.number}
                                 </Box>
                                 <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    className={styles.content}
                                 >
                                    {item.title}
                                 </Flex>
                              </Box>
                           ),
                        )}
                  </Flex>
               ))}
         </Flex>
      </Box>
   )
}

export default PeriodicTable
