import React, { useEffect, useState, useRef } from 'react'
import { PageHomeProps } from './types'
import { Box, Flex, Container, Row, Column } from '@components/UI/Grid/Grid'
import styles from './PageHome.module.scss'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import PeriodicTable from '@components/Modules/PeriodicTable/PeriodicTable'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import Head from 'next/head'
import Image from '@components/UI/Image/Image'
import Video from '@components/Video/Video'
import { isImage } from '@lib/isImage'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import classnames from 'classnames'
import { motion, useScroll, useTransform } from 'framer-motion'

const PageHome: React.FC<PageHomeProps> = ({
   blok,
   periodicItems,
   stories,
   funds,
}) => {
   const {
      title,
      hero_subtitle,
      buttons,
      hero_alignment,
      hero_background,
      hero_media,
      body,
      seo_data,
      schemas,
   } = blok || {}
   const [loadPeriodic, setLoadPeriodic] = useState(
      hero_background === 'periodic_table' ? true : false,
   )

   useEffect(() => {
      setLoadPeriodic(hero_background === 'periodic_table' ? true : false)
   }, [hero_background])

   const sectionRef = useRef(null)
   const { scrollYProgress } = useScroll()
   const animateTextOpacity = useTransform(scrollYProgress, [0, 0.1], [1, 0])
   const animateTextY = useTransform(scrollYProgress, [0, 0.1], ['0%', '-15%'])
   const transitionDuration = 1.2
   const transitionDelay = 0.6

   const { currentNavData, documents } = stories || {}

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage storyblokData={seo_data} />
         <Box className={styles.hero}>
            <Flex
               className={styles.content}
               alignItems={
                  hero_alignment === 'bottom_left' ? 'flex-end' : 'center'
               }
            >
               <Container ref={sectionRef}>
                  <Row
                     justifyContent={
                        hero_alignment === 'center' ? 'center' : 'flex-start'
                     }
                  >
                     {title && (
                        <Column
                           width={[1, null, null, 12 / 12, 10 / 12]}
                           textAlign={
                              hero_alignment === 'center' ? 'center' : 'left'
                           }
                        >
                           <motion.h1
                              style={{
                                 opacity: animateTextOpacity,
                                 y: animateTextY,
                              }}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{
                                 delay: transitionDelay,
                                 ease: 'easeOut',
                                 duration: transitionDuration,
                              }}
                           >
                              <Box
                                 dangerouslySetInnerHTML={{
                                    __html: title || '',
                                 }}
                              ></Box>
                           </motion.h1>
                        </Column>
                     )}
                     {hero_subtitle && (
                        <Column
                           width={[1, null, null, 6 / 12, 6 / 12]}
                           textAlign={
                              hero_alignment === 'center' ? 'center' : 'left'
                           }
                        >
                           <Box
                              className={styles.subtitle}
                              marginTop={[4, null, null, null, 8]}
                           >
                              <motion.span
                                 style={{
                                    opacity: animateTextOpacity,
                                    y: animateTextY,
                                 }}
                                 initial={{ opacity: 0 }}
                                 animate={{ opacity: 1 }}
                                 transition={{
                                    delay: transitionDelay,
                                    ease: 'easeOut',
                                    duration: transitionDuration,
                                 }}
                              >
                                 {hero_subtitle}
                              </motion.span>
                           </Box>
                        </Column>
                     )}
                     {buttons && buttons?.length > 0 && (
                        <Column width={[1]}>
                           <Flex
                              marginTop={[10, null, null, null, 12]}
                              flexWrap="wrap"
                              justifyContent={
                                 hero_alignment === 'center'
                                    ? 'center'
                                    : 'flex-start'
                              }
                           >
                              {buttons.map(
                                 (blok: CtaButtonStoryblok, index: number) => {
                                    return (
                                       <Box
                                          key={blok._uid}
                                          marginRight={
                                             index < buttons?.length - 1
                                                ? [3, null, 4]
                                                : 0
                                          }
                                       >
                                          <motion.span
                                             style={{
                                                opacity: animateTextOpacity,
                                                y: animateTextY,
                                             }}
                                             transition={{
                                                delay: transitionDelay,
                                                ease: 'easeOut',
                                                duration: transitionDuration,
                                             }}
                                          >
                                             <DynamicComponent blok={blok} />
                                          </motion.span>
                                       </Box>
                                    )
                                 },
                              )}
                           </Flex>
                        </Column>
                     )}
                  </Row>
               </Container>
            </Flex>
            {loadPeriodic && (
               <Box
                  width={['150%', null, null, 10 / 12]}
                  className={styles.periodicBackground}
               >
                  <PeriodicTable activeItems={periodicItems} />
               </Box>
            )}
            {hero_background === 'media' &&
               hero_media &&
               hero_media?.filename && (
                  <Box
                     className={classnames(
                        styles.mediaBackground,
                        isImage(hero_media?.filename)
                           ? styles.imageBackground
                           : styles.videoBackground,
                     )}
                  >
                     {isImage(hero_media?.filename) ? (
                        <Image alt={title} src={hero_media.filename} />
                     ) : (
                        <Video
                           blok={{
                              _uid: 'video',
                              component: 'video',
                              video: hero_media || null,
                              video_type: 'native',
                           }}
                           autoplay
                        />
                     )}
                  </Box>
               )}
         </Box>
         <Flex width={1} flexDirection="column">
            {body &&
               body?.length > 0 &&
               body.map((blok: any, index: number) => (
                  <DynamicComponent
                     key={blok._uid}
                     currentNavData={currentNavData}
                     documents={documents}
                     blok={{ ...blok, isLast: index === body.length - 1 }}
                     className={styles.buttonFilled}
                     funds={
                        blok.component === 'fund_list' ||
                        blok.component === 'investors'
                           ? funds
                           : null
                     }
                  />
               ))}
         </Flex>
      </React.Fragment>
   )
}

export default PageHome
