import React, { useEffect, useState } from 'react'
import { PagePostLandingProps } from './types'
import { Container, Flex, Box } from '@components/UI/Grid/Grid'
import styles from './PagePostLanding.module.scss'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { HeroStoryblok } from 'types/storyblok-components'
import classnames from 'classnames'
import PostGrid from '@components/PostGrid/PostGrid'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import StoryblokClient from 'storyblok-js-client'
import { config } from 'config'
import { postReducer } from 'pages/[[...slug]]'
import { useRouter } from 'next/router'

const getPostCategory = (cat: any, nextTranslate?: any) => {
   const categoryNames: any = {
      resources: nextTranslate('resources'),
      articles: nextTranslate('articles'),
      podcast: nextTranslate('podcast'),
      videos: nextTranslate('videos'),
      publications: nextTranslate('publications'),
      'monthly-letters': nextTranslate('monthlyLetters'),
      'portfolio-updates': nextTranslate('portfolioUpdates'),
   }
   const categoryName = cat && categoryNames[cat.slug]

   return {
      name: categoryName || '',
      value: cat.slug || '',
      full_slug: cat.full_slug,
   }
}

const PagePostLanding: React.FC<PagePostLandingProps> = ({
   blok,
   story,
   stories,
}) => {
   const { t } = useTranslation('common')
   const { locale } = useRouter()
   const { header, footer, seo_data, schemas } = blok || {}
   const { total, slug } = stories || {}
   const [posts, setPosts] = useState(stories.posts)

   useEffect(() => {
      setPosts(stories.posts)
   }, [stories.slug, locale])

   let categories: any = [
      {
         name: t('all'),
         value: 'all',
         full_slug: '/research-centre',
      },
   ]
   const categoriesOrder = [
      'all',
      'articles',
      'podcast',
      'monthly-letters',
      'videos',
      'publications',
      'portfolio-updates',
      'resources',
   ]

   const handleLoadMore = async () => {
      const sbParams = {
         version: 'published',
         cv: Date.now(),
         language: locale,
         resolve_relations:
            config?.storyblok?.resolveRelations &&
            config.storyblok.resolveRelations?.length > 0
               ? config.storyblok.resolveRelations.join(',')
               : [],
      }

      const Storyblok = new StoryblokClient({
         accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
         cache: {
            clear: 'auto',
            type: 'memory',
         },
      })
      let sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news`
      if (slug === 'research-centre/resources') {
         sbString = `cdn/stories/?starts_with=${slug}&sort_by=first_published_at:desc&filter_query[component][in]=page_article,page_podcast,page_publication,page_video,page_news,page_seo`
      }
      const { data: new_posts } = await Storyblok.get(sbString, {
         ...sbParams,
         per_page: 100,
      })
      setPosts(
         new_posts?.stories && new_posts.stories.length > 0
            ? new_posts.stories.map(postReducer)
            : posts,
      )
   }
   if (posts && posts.length > 0) {
      stories?.categories?.forEach((cat: any) => {
         const category = getPostCategory(cat, t) || {
            name: '',
            value: '',
         }

         if (
            category &&
            !categories.find((c: any) => c.value === category.value)
         ) {
            categories.push(category)
         }
      })
      categories = categoriesOrder
         .map((cat: string) => {
            const find = categories.find((inner: any) => inner.value === cat)
            return find
         })
         .filter((item: any) => item)
   }
   const catSlug =
      slug === 'research-centre/'
         ? 'all'
         : slug.replace('research-centre/', '').replace('/', '')

   let activeCategory = categories.find((cat: any) => cat.value === catSlug)
   if (!activeCategory) {
      activeCategory = categories[0]
   }
   // Sort articles by published date
   const postsSortedByDate =
      posts && posts.length > 0
         ? posts.sort(function (a: any, b: any) {
              const aDate: Date = a?.first_published_at
                 ? new Date(a.first_published_at)
                 : new Date()
              const bDate: Date = b?.first_published_at
                 ? new Date(b?.first_published_at)
                 : new Date()
              return bDate.getTime() - aDate.getTime()
           })
         : []

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: header?.[0].title || story?.name || '',
               description: header?.[0].subtitle || '',
            }}
         />
         {header &&
            header?.length > 0 &&
            header.map((blok: HeroStoryblok) => {
               return (
                  <DynamicComponent
                     blok={{ ...blok, smallMobile: true }}
                     key={blok._uid}
                  />
               )
            })}
         <Box bg="var(--light-purple)" as="section" className={styles.wrapper}>
            <Container
               paddingTop={[10, null, null, 30]}
               paddingX={[0, null, null, 'var(--container-side-padding)']}
            >
               <Flex
                  flexDirection={'column'}
                  alignItems="center"
                  width={1}
                  className={styles.tabsOuter}
               >
                  <div className={styles.scrimFirst} />

                  <Flex className={styles.tabs}>
                     {categories.map((category: any) => {
                        return (
                           <LinkComponent
                              key={category.value}
                              href={
                                 category.full_slug
                                    ? category.full_slug
                                    : '/research-centre'
                              }
                           >
                              <Flex
                                 justifyContent="center"
                                 alignItems="center"
                                 key={category.value}
                                 paddingX={['16px', null, null, '8px', '16px']}
                                 className={classnames(
                                    styles.tab,
                                    activeCategory.value === category.value
                                       ? styles.active
                                       : '',
                                 )}
                              >
                                 {category.name}
                              </Flex>
                           </LinkComponent>
                        )
                     })}
                  </Flex>

                  <div className={styles.scrim} />

                  <Container className="hideDesktop">
                     <Box
                        height={'1px'}
                        width={1}
                        bg="var(--border)"
                        className="hideDesktop"
                     />
                  </Container>
               </Flex>
            </Container>
            <Container paddingBottom={[10, null, null, 15]}>
               <Box marginTop={[5, null, null, 15]}>
                  <PostGrid preloadFirst posts={postsSortedByDate} />
               </Box>
               {posts.length < total && (
                  <div className={styles.loadMore}>
                     <button className={styles.button} onClick={handleLoadMore}>
                        {t('loadMore')}
                     </button>
                  </div>
               )}
            </Container>
            {footer &&
               footer?.length > 0 &&
               footer.map((blok: any) => (
                  <DynamicComponent
                     key={blok._uid}
                     blok={blok}
                     className={styles.buttonFilled}
                  />
               ))}
         </Box>
      </React.Fragment>
   )
}

export default PagePostLanding
