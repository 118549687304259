import React from 'react'
import { PageNewsletterProps } from './types'
import { Flex, Box } from '@components/UI/Grid/Grid'

import styles from './PageNewsletterSuccess.module.scss'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import Label from '@components/UI/Label/Label'

import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import CtaButton from '@components/CtaButton/CtaButton'

const PageNewsletter: React.FC<PageNewsletterProps> = ({ blok, story }) => {
   const { t } = useTranslation('common')
   const router = useRouter()
   const {
      newsletter_label,
      newsletter_title,
      newsletter_text,
      seo_data,
      schemas,
      title,
      text,
      button,
   } = blok || {}

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: story?.name || '',
            }}
         />

         <Flex
            width={1}
            className={styles.wrapper}
            alignItems="flex-start"
            flexDirection={['column', null, null, 'row']}
         >
            <Flex
               className={styles.content}
               paddingY={[15, null, null, 20]}
               width={[1, null, null, 1 / 2]}
               flexDirection="column"
               justifyContent="center"
               alignItems="flex-start"
               paddingX={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
            >
               {newsletter_label && (
                  <Box marginBottom={[5, null, null, 5]}>
                     <Label title={newsletter_label} />
                  </Box>
               )}
               {newsletter_title && (
                  <Box
                     as="h1"
                     marginBottom={[5, null, null, 10]}
                     className={styles.title}
                  >
                     {newsletter_title}
                  </Box>
               )}
               {newsletter_text && (
                  <Box className={styles.text}>{newsletter_text}</Box>
               )}
            </Flex>
            <Box
               className={styles.formWrapper}
               width={[1, null, null, 1 / 2]}
               bg="var(--white)"
            >
               <Box
                  paddingY={[10, null, null, 20]}
                  paddingX={[
                     'var(--container-side-padding-mobile)',
                     null,
                     null,
                     'var(--container-side-padding)',
                  ]}
                  className={styles.formWrapperContent}
               >
                  <h1>{title}</h1>
                  <p>{text}</p>
                  {button &&
                     button.length > 0 &&
                     button.map((link: any) => (
                        <CtaButton
                           key={link._uid}
                           blok={{
                              _uid: '',
                              component: 'cta_button',
                              link: [{ ...link }],
                              color: 'purple',
                              hover_color: 'purple',
                           }}
                        />
                     ))}
               </Box>
            </Box>
         </Flex>
      </React.Fragment>
   )
}

export default PageNewsletter
