import React, { useState, useRef, useEffect } from 'react'
import { ChartProps } from './types'
import styles from './Chart.module.scss'
import { Box, Column, Row, Container, Flex } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'

import { stringify } from '@lib/helpers'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import { motion, useScroll, useTransform } from 'framer-motion'

const Chart: React.FC<ChartProps> = ({ blok }) => {
   const { label, title, description, list, chart_text, chart_highlight_text } =
      blok

   const sectionRef = useRef(null)
   const { scrollYProgress } = useScroll({
      target: sectionRef,
      offset: ['start end', 'end end'],
   })

   const animateTextOpacity = useTransform(scrollYProgress, [0, 0.5], [0, 1])
   const AnimateListItemOpacity = (index: number) =>
      useTransform(scrollYProgress, [0.4 + index / 10, 1], [0, 1])
   const animateGlowOpacity = useTransform(scrollYProgress, [0, 0.8], [0, 0.8])
   const animateChartScale = useTransform(scrollYProgress, [0, 0.5], [0, 1])
   const animatePartX = useTransform(scrollYProgress, [0.5, 1], [0, 20])
   const animatePartY = useTransform(scrollYProgress, [0.5, 1], [0, -30])
   const animatePartTextOpacity = useTransform(
      scrollYProgress,
      [0.8, 1],
      [0, 1],
   )

   return (
      <Flex
         ref={sectionRef}
         as="section"
         className={classnames(styles.wrapper, 'dark-section')}
         glob
         paddingBottom={[15, null, null, 20]}
         // marginTop={'-1px'}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container paddingTop={[10, null, null, 20]}>
            <Row>
               <Column width={[1, null, null, 7 / 12]}>
                  {(label || title || description) && (
                     <Flex
                        marginBottom={[10, null, null, 0]}
                        width={1}
                        alignItems="flex-end"
                        flexDirection={['column', null, null, 'row']}
                     >
                        <Flex flexDirection="column" className={styles.content}>
                           {label && (
                              <motion.div
                                 style={{
                                    opacity: animateTextOpacity,
                                 }}
                              >
                                 <Box marginBottom={[4, null, null, 6]}>
                                    <Label title={label} />
                                 </Box>
                              </motion.div>
                           )}
                           {title && (
                              <motion.div
                                 style={{
                                    opacity: animateTextOpacity,
                                 }}
                              >
                                 <Box
                                    as="h3"
                                    className={styles.title}
                                    marginBottom={[5, null, null, 6]}
                                 >
                                    {title}
                                 </Box>
                              </motion.div>
                           )}
                           {description && (
                              <motion.div
                                 style={{
                                    opacity: animateTextOpacity,
                                 }}
                              >
                                 <Box as="p" className={styles.text}>
                                    {description}
                                 </Box>
                              </motion.div>
                           )}
                           {list && list.length > 0 && (
                              <Box marginTop={[10, null, null, 15]}>
                                 {list.map((block: any, index: number) => {
                                    const { image, text, _uid } = block || {}

                                    return (
                                       <motion.div
                                          key={_uid}
                                          style={{
                                             opacity:
                                                AnimateListItemOpacity(index),
                                          }}
                                       >
                                          <Box
                                             marginBottom={
                                                index < list.length - 1
                                                   ? [5, null, null, 5]
                                                   : 0
                                             }
                                             className={styles.listItem}
                                          >
                                             <Flex
                                                alignItems="center"
                                                width={1}
                                                className={styles.listContent}
                                             >
                                                {image?.filename ? (
                                                   <Box
                                                      marginRight={[6]}
                                                      className={
                                                         styles.listIcon
                                                      }
                                                   >
                                                      <Image
                                                         src={image?.filename}
                                                         alt={image?.alt}
                                                         sizes={{
                                                            mobile: '50px',
                                                            tablet: '50px',
                                                            desktop: '50px',
                                                         }}
                                                      />
                                                   </Box>
                                                ) : (
                                                   <Box
                                                      marginRight={[6]}
                                                      className={
                                                         styles.listNumber
                                                      }
                                                   >
                                                      {index + 1}
                                                   </Box>
                                                )}
                                                {text && (
                                                   <Box
                                                      as="span"
                                                      className={
                                                         styles.listText
                                                      }
                                                   >
                                                      {text}
                                                   </Box>
                                                )}
                                             </Flex>
                                          </Box>
                                       </motion.div>
                                    )
                                 })}
                              </Box>
                           )}
                        </Flex>
                     </Flex>
                  )}
               </Column>
               <Column width={[1, null, null, 5 / 12]}>
                  <Box paddingX={'4vw'}>
                     <Box className={classnames(styles.pieChartWrapper)}>
                        <motion.div
                           className={styles.glow}
                           style={{
                              opacity: animateGlowOpacity,
                           }}
                        />
                        <motion.div
                           className={styles.pieChart}
                           style={{
                              scaleX: animateChartScale,
                              scaleY: animateChartScale,
                           }}
                        >
                           <motion.div
                              style={{
                                 x: animatePartX,
                                 y: animatePartY,
                              }}
                              className={classnames(
                                 styles.piePart,
                                 styles.piePartCommodities,
                              )}
                           >
                              {chart_highlight_text && (
                                 <motion.div
                                    className={styles.piePartInner}
                                    style={{
                                       opacity: animatePartTextOpacity,
                                    }}
                                 >
                                    {chart_highlight_text}
                                 </motion.div>
                              )}
                           </motion.div>
                           <div
                              className={classnames(
                                 styles.piePart,
                                 styles.piePartOther,
                              )}
                           >
                              {chart_text && (
                                 <motion.div
                                    className={styles.piePartInner}
                                    style={{
                                       opacity: animatePartTextOpacity,
                                    }}
                                 >
                                    {chart_text}
                                 </motion.div>
                              )}
                           </div>
                        </motion.div>
                     </Box>
                  </Box>
               </Column>
            </Row>
         </Container>
      </Flex>
   )
}

export default Chart
