import React from 'react'
import StoryblokImage from '@components/UI/StoryblokImage/StoryblokImage'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import { ImageProps } from './types'
// import { StripDefaultLocale } from '@lib/helpers'
import styles from './Image.module.scss'

const Image: React.FC<ImageProps> = ({ blok }) => {
   const { image } = blok || {}

   // Get link
   // const { cached_url } = link || {}
   // const href = StripDefaultLocale(cached_url)
   const href = ''
   // console.log(image)
   // Get image
   const { filename = '', alt = '' } = image || {}
   return href ? (
      <LinkComponent className={styles.link} href={href} title={alt}>
         {filename && <StoryblokImage src={filename} alt={alt} />}
      </LinkComponent>
   ) : (
      <React.Fragment>
         {filename && <StoryblokImage src={filename} alt={alt} />}
      </React.Fragment>
   )
}

export default Image
