import React, { createContext, useState } from 'react'

interface Props {
   children: any
}

export const UiContext = createContext<any>(null)

const UiProvider: React.FC<Props> = ({ children }) => {
   const [activeMenu, setActiveMenu] = useState('')

   return (
      <UiContext.Provider
         value={{
            activeMenu,
            setActiveMenu,
         }}
      >
         {children}
      </UiContext.Provider>
   )
}

export default UiProvider
