import { initializeApp, getApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
   apiKey: 'AIzaSyCUwA9kSBCQSFNaeAkzPpM7QxhU4UeleBU',
   authDomain: 'ticker-80ddf.firebaseapp.com',
   databaseURL: 'https://ticker-80ddf-default-rtdb.firebaseio.com',
   projectId: 'ticker-80ddf',
   storageBucket: 'ticker-80ddf.appspot.com',
   messagingSenderId: '889731945947',
   appId: '1:889731945947:web:42a98797e75b7dd6d383fe',
   measurementId: 'G-3P4JK2FJZ6',
}

function createFirebaseApp(config) {
   try {
      const test = getApp()
      return test
   } catch (e) {
      // console.log('ERROR', firebase)
      return initializeApp('Ticker', config)
   }
}

export const getFirebaseDatabase = () => {
   const firebaseApp = createFirebaseApp(firebaseConfig)
   return getDatabase(
      firebaseApp,
      'https://ticker-80ddf-default-rtdb.firebaseio.com/',
   )
}
// Initialize Realtime Database and get a reference to the service
