import { Flex } from '@components/UI/Grid/Grid'
import React, { useEffect } from 'react'
import SbEditable from 'storyblok-react'
import styles from './EditChartData.module.scss'

const Col = ({ children }: any) => <td className={styles.td}>{children}</td>

const EditChartData = ({ blok }: any) => {
   useEffect(() => {
      document.body.classList.add('edit-chart')
      return () => {
         document.body.classList.remove('edit-chart')
      }
   }, [])

   return (
      <Flex
         paddingX={4}
         paddingY={4}
         flexDirection="column"
         className={styles.wrapper}
      >
         <table>
            <thead className={styles.header}>
               <tr>
                  <th className={styles.th}>Name</th>
                  <th className={styles.th}>Date</th>
                  <th className={styles.th}>Percentage</th>
                  <th className={styles.th}>Price</th>
               </tr>
            </thead>
            <tbody>
               <SbEditable content={blok}>
                  {blok && blok.data ? (
                     blok.data
                        .sort(function (a: any, b: any) {
                           const bDate: any = new Date(b.date)
                           const aDate: any = new Date(a.date)
                           return bDate - aDate
                        })
                        .slice(0, 4)
                        .map((row: any) => (
                           <tr key={row._uid} className={styles.row}>
                              <SbEditable content={row}>
                                 <Col>{row.name}</Col>
                              </SbEditable>
                              <SbEditable content={row}>
                                 <Col>{row.date}</Col>
                              </SbEditable>
                              <SbEditable content={row}>
                                 <td className={styles.info}>
                                    <p>{row.percentage}</p>
                                 </td>
                              </SbEditable>
                              <SbEditable content={row}>
                                 <td className={styles.info}>
                                    <p>{row.price}</p>
                                 </td>
                              </SbEditable>
                           </tr>
                        ))
                  ) : (
                     <div></div>
                  )}
               </SbEditable>
            </tbody>
         </table>
      </Flex>
   )
}

export default EditChartData
