import React from 'react'
import { SustainabilityStoryProps } from './types'
import styles from './SustainabilityStory.module.scss'
import { Box, Container, Flex, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { SustainabilityCardStoryblok } from 'types/storyblok-components'
import Image from '@components/UI/Image/Image'
import classnames from 'classnames'
const SustainabilityStory: React.FC<SustainabilityStoryProps> = ({ blok }) => {
   const {
      label,
      title,
      top_text,
      top_text_column_2,
      image,
      info_items,
      cta,
      background,
      title_markup,
   } = blok
   return (
      <Flex
         as="section"
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
         flexDirection="column"
         width={1}
         className={styles.wrapper}
         paddingTop={[10, null, null, 20]}
         paddingBottom={[
            info_items && info_items.length > 0 ? 0 : 10,
            null,
            null,
            20,
         ]}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Flex alignItems="flex-start" width={1} flexDirection="column">
               <Box marginBottom={[4, null, null, 6]}>
                  {label && <Label title={label} />}
               </Box>
               {title && (
                  <Box
                     as={getTitleMarkup(title_markup)}
                     className={styles.title}
                     width={[1, null, null, 1 / 2]}
                     marginBottom={top_text ? [5, null, null, 6] : 0}
                  >
                     {title}
                  </Box>
               )}

               <div className={styles.content}>
                  <div className={!top_text_column_2 ? styles.full : ''}>
                     {top_text && (
                        <Box
                           className={classnames(
                              styles.text,
                              !top_text_column_2 ? styles.isOneColumn : '',
                           )}
                        >
                           <Markdown>{top_text}</Markdown>
                        </Box>
                     )}
                  </div>
                  {top_text_column_2 && (
                     <div>
                        <Box className={styles.text}>
                           <Markdown>{top_text_column_2}</Markdown>
                        </Box>
                     </div>
                  )}
               </div>
               {((image && image?.filename) ||
                  (info_items && info_items.length > 0) ||
                  (cta && cta?.length > 0)) && (
                  <Flex
                     justifyContent="space-between"
                     marginTop={[5, null, null, 20]}
                     width={1}
                     flexDirection={['column', null, null, 'row']}
                     alignItems="flex-start"
                  >
                     {image && image.filename && (
                        <Box
                           width={[1, null, null, 5 / 12]}
                           className={styles.image}
                        >
                           <Image src={image.filename} />
                        </Box>
                     )}
                     <Flex
                        flexDirection={'column'}
                        alignItems="flex-start"
                        width={[1, null, null, 6 / 12]}
                        paddingLeft={[0, null, null, 3]}
                        marginTop={[10, null, null, 0]}
                     >
                        {info_items && info_items.length > 0 && (
                           <Row>
                              {info_items.map(
                                 (blok: SustainabilityCardStoryblok) => (
                                    <Column
                                       key={blok._uid}
                                       marginBottom={[10, null, null, 18]}
                                       width={
                                          blok.width === '1'
                                             ? 1
                                             : [1, null, null, 1 / 2]
                                       }
                                    >
                                       <DynamicComponent blok={blok} />
                                    </Column>
                                 ),
                              )}
                           </Row>
                        )}
                        {cta && cta?.length > 0 && (
                           <Box
                              marginBottom={[10, null, null, 0]}
                              width={['100%', null, 'auto']}
                           >
                              {cta.map((blok: any) => (
                                 <DynamicComponent
                                    blok={blok}
                                    key={blok._uid}
                                 />
                              ))}
                           </Box>
                        )}
                     </Flex>
                  </Flex>
               )}
            </Flex>
         </Container>
      </Flex>
   )
}

export default SustainabilityStory
