import React from 'react'
import { ContentProps } from './types'
import styles from './Table.module.scss'
import { Container, Box, Row, Column, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'

const Table: React.FC<ContentProps> = ({ blok }) => {
   const { title, text, table, label, Background } = blok
   return (
      <Box
         as="section"
         width={1}
         className={[
            styles.wrapper,
            getThemeColor(Background).background === 'transparent'
               ? styles.transparent
               : '',
         ].join(' ')}
         bg={getThemeColor(Background).background}
         color={getThemeColor(Background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Box paddingY={[10, null, null, 20]}>
            <Container>
               <Flex
                  width={[1, null, null, 6 / 12]}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
               >
                  {label && (
                     <Box marginBottom={[4, null, null, 6]}>
                        {label && <Label title={label} />}
                     </Box>
                  )}
                  {title && (
                     <Box
                        className={styles.title}
                        as="h3"
                        marginBottom={[5, null, null, 10]}
                     >
                        {title}
                     </Box>
                  )}
                  {text && (
                     <Box
                        marginBottom={[5, null, null, 10]}
                        width={1}
                        className={styles.intro}
                     >
                        <Markdown>{text}</Markdown>
                     </Box>
                  )}
               </Flex>
               <Row justifyContent="center">
                  <Column width={[1, null, null, 1]}>
                     {table && (
                        <div
                           className={[
                              styles.container,
                              Background === 'grey' ? styles.grey : '',
                           ].join(' ')}
                        >
                           <table>
                              <thead>
                                 {table.thead && (
                                    <tr>
                                       {table.thead.map((item: any) => (
                                          <th key={item._uid}>{item.value}</th>
                                       ))}
                                    </tr>
                                 )}
                              </thead>
                              <tbody>
                                 {table.tbody &&
                                    table.tbody.map((item: any) => (
                                       <tr key={item._uid}>
                                          {item.body &&
                                             item.body.map((bItem: any) => {
                                                return (
                                                   <td
                                                      className={
                                                         bItem.value.startsWith(
                                                            '<a',
                                                         )
                                                            ? styles.gotLink
                                                            : ''
                                                      }
                                                      key={bItem._uid}
                                                      dangerouslySetInnerHTML={{
                                                         __html: bItem.value,
                                                      }}
                                                   />
                                                )
                                             })}
                                       </tr>
                                    ))}
                              </tbody>
                           </table>
                        </div>
                     )}
                  </Column>
               </Row>
            </Container>
         </Box>
      </Box>
   )
}

export default Table
