import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import React from 'react'
import styles from './Footer.module.scss'
import NextImage from 'next/image'

import { SettingsProps } from '../types'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import {
   CtaButtonStoryblok,
   LinkListStoryblok,
   LinkStoryblok,
   SocialMediaLinkStoryblok,
} from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'

import { Logotype } from '@components/UI/Icon/Icon'
import useTranslation from 'next-translate/useTranslation'

const SocialLink = ({ type }: { type?: string }) => {
   if (type === 'twitter') {
      return <NextImage src="/icons/x.svg" alt="x" width="26" height="26" />
   }
   if (type === 'instagram') {
      return (
         <NextImage
            src="/icons/instagram.svg"
            alt="instagram"
            width="26"
            height="26"
         />
      )
   }
   if (type === 'linkedin') {
      return (
         <NextImage
            src="/icons/linkedin.svg"
            alt="linkedin"
            width="26"
            height="26"
         />
      )
   }
   if (type === 'facebook') {
      return (
         <NextImage
            src="/icons/facebook.svg"
            alt="facebook"
            width="26"
            height="26"
         />
      )
   }
   if (type === 'tiktok') {
      return (
         <NextImage
            src="/icons/tiktok.svg"
            alt="facebook"
            width="26"
            height="26"
         />
      )
   }
   if (type === 'youtube') {
      return (
         <NextImage
            src="/icons/youtube.svg"
            alt="youtube"
            width="26"
            height="26"
         />
      )
   }
   return <div />
}

const Footer: React.FC<SettingsProps> = ({ blok }) => {
   const { t } = useTranslation('common')

   const empty: any = {}
   const {
      contact_email,
      footer_link_lists,
      footer_main_links,
      newsletter_title,
      social_media_links,
      bottom_bar_links,
      copyright_text,
      newsletter_cta,
   } = blok ? blok : empty

   return (
      <Flex flexDirection="column" className={styles.footer} as="footer">
         <Flex
            alignItems="flex-end"
            width={1}
            flexDirection={['column', null, null, 'row']}
         >
            <Flex
               paddingY={[14, null, null, 18]}
               className={styles.left}
               width={[1, null, null, '44%']}
               flexDirection="column"
               paddingX={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
               alignItems="flex-start"
            >
               <Box className={styles.newsletterTitle} as="span">
                  {newsletter_title}
               </Box>
               {newsletter_cta &&
                  newsletter_cta.length > 0 &&
                  newsletter_cta.map((link: CtaButtonStoryblok) =>
                     link.link ? (
                        <Box marginTop={[8, null, null, 12]} key={link._uid}>
                           <DynamicComponent blok={link} />
                        </Box>
                     ) : null,
                  )}
            </Flex>
            <Flex
               paddingY={[14, null, null, 18]}
               paddingLeft={[5, null, null, 20]}
               paddingRight={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
               paddingBottom={[8]}
               width={[1, null, null, '66%']}
               flexDirection="column"
               className={styles.rightSection}
            >
               <Box marginBottom={[5, null, null, 5]}>
                  <LinkComponent
                     ariaLabel="Go to AuAg startpage"
                     href="/"
                     className={styles.logotypeWrapper}
                  >
                     <Logotype className={styles.logotype} />
                  </LinkComponent>
               </Box>

               <Flex
                  marginTop={[6, null, null, 17]}
                  width={1}
                  flexDirection={['column', null, null, 'row']}
               >
                  <Flex
                     width={[1, null, null, 1 / 3]}
                     marginBottom={[8, null, null, 0]}
                     flexDirection="column"
                  >
                     {footer_main_links &&
                        footer_main_links.length > 0 &&
                        footer_main_links.map((link: LinkStoryblok) =>
                           link.link ? (
                              <Box
                                 marginBottom={[2, null, null, 8]}
                                 className={styles.mainLink}
                                 key={link._uid}
                              >
                                 <DynamicComponent blok={link} />
                              </Box>
                           ) : null,
                        )}
                  </Flex>
                  <Flex
                     flexDirection={['column']}
                     width={[1, null, null, 2 / 3]}
                  >
                     <Flex
                        width={1}
                        flexDirection={['column', null, null, 'row']}
                     >
                        {footer_link_lists &&
                           footer_link_lists.length > 0 &&
                           footer_link_lists.map(
                              (linkList: LinkListStoryblok) => (
                                 <Flex
                                    flexDirection={['column']}
                                    key={linkList._uid}
                                    width={[1, null, null, 1 / 2]}
                                    marginBottom={[8, null, null, 0]}
                                    alignItems="flex-start"
                                 >
                                    <Box
                                       marginBottom={[3, null, null, 8]}
                                       className={styles.listTitle}
                                       as="span"
                                    >
                                       {linkList.title}
                                    </Box>
                                    {linkList.links &&
                                       linkList.links.length > 0 &&
                                       linkList.links.map(
                                          (link: LinkStoryblok) =>
                                             link.link ? (
                                                <Box
                                                   marginBottom={[
                                                      2,
                                                      null,
                                                      null,
                                                      4,
                                                   ]}
                                                   className={styles.link}
                                                   key={link._uid}
                                                >
                                                   <DynamicComponent
                                                      blok={link}
                                                   />
                                                </Box>
                                             ) : null,
                                       )}
                                 </Flex>
                              ),
                           )}
                     </Flex>
                     <Flex
                        flexDirection="column"
                        className={styles.social}
                        paddingTop={[4]}
                        marginTop={[8, null, null, 16]}
                     >
                        <Flex width={1} justifyContent="space-between">
                           <Box width={1 / 2} as="span">
                              {t('followUs')}
                           </Box>
                           <Flex
                              width={1 / 2}
                              justifyContent={[
                                 'flex-end',
                                 null,
                                 null,
                                 'flex-end',
                              ]}
                           >
                              {social_media_links &&
                                 social_media_links.length > 0 &&
                                 social_media_links.map(
                                    (socialLink: SocialMediaLinkStoryblok) =>
                                       socialLink.link ? (
                                          <Box
                                             key={socialLink._uid}
                                             // marginRight={[0, null, null, 4]}
                                             marginLeft={[4, null, null, 4]}
                                          >
                                             <LinkComponent
                                                ariaLabel={`Link to ${socialLink.logotype}`}
                                                href={
                                                   socialLink.link.cached_url ||
                                                   ''
                                                }
                                             >
                                                <SocialLink
                                                   type={socialLink.logotype}
                                                />
                                             </LinkComponent>
                                          </Box>
                                       ) : null,
                                 )}
                           </Flex>
                        </Flex>
                        <Flex
                           marginTop={3}
                           width={1}
                           justifyContent="space-between"
                        >
                           <Box as="span">{t('contactUs')}</Box>
                           {contact_email && (
                              <a
                                 className={styles.mail}
                                 href={`mailto:${contact_email}`}
                              >
                                 {contact_email}
                              </a>
                           )}
                        </Flex>
                     </Flex>
                  </Flex>
               </Flex>
            </Flex>
         </Flex>
         <Flex
            className={styles.bottomBar}
            height={['34px', null, null, '55px']}
            width={1}
            alignItems="center"
         >
            <Container>
               <Flex
                  width={1}
                  alignItems="center"
                  justifyContent={[
                     'space-between',
                     null,
                     null,
                     'space-between',
                  ]}
               >
                  <Box as="span" flexShrink={0}>
                     {copyright_text}
                  </Box>
                  <Flex
                     width={1}
                     justifyContent={['flex-end', null, null, 'flex-end']}
                  >
                     {bottom_bar_links &&
                        bottom_bar_links.length > 0 &&
                        bottom_bar_links.map((link: LinkStoryblok) =>
                           link.link ? (
                              <Box
                                 marginLeft={[5, null, null, 16]}
                                 key={link._uid}
                                 textAlign={['right', null, null, 'left']}
                              >
                                 <DynamicComponent blok={link} />
                              </Box>
                           ) : null,
                        )}
                  </Flex>
               </Flex>
            </Container>
         </Flex>
      </Flex>
   )
}

export default Footer
