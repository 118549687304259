import React from 'react'
import { InvestorsTableProps } from './types'
import styles from './InvestorsTable.module.scss'
import { Box } from '@components/UI/Grid/Grid'
import classnames from 'classnames'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'

const TableRow = ({ item }: { item: any }) => {
   const { exchange, bbg_code, ric, isin, sedol, currency, link } = item

   const cached_url = link && link[0] && link[0].link && link[0].link.cached_url
   return (
      <Box as="tr" className={styles.row} width={1} alignItems="center">
         <Box className={cached_url ? styles.gotLink : ''} as="td">
            {cached_url ? (
               <LinkComponent href={cached_url} title={exchange}>
                  {exchange}
               </LinkComponent>
            ) : (
               exchange
            )}
         </Box>
         <Box as="td">{currency}</Box>
         <Box as="td">{bbg_code}</Box>
         <Box as="td">{ric}</Box>
         <Box as="td">{isin}</Box>
         <Box as="td">{sedol}</Box>
      </Box>
   )
}

const InvestorsTable: React.FC<InvestorsTableProps> = ({
   tableData,
   background,
}) => {
   return (
      <Box
         paddingX={['var(--container-side-padding-mobile)', null, null, 0]}
         className={styles.container}
      >
         <table
            className={classnames(
               styles.rows,
               background !== '#fff' ? styles.transparent : '',
            )}
         >
            <thead>
               <Box className={styles.header} as="tr">
                  <Box as="th">EXCHANGE</Box>
                  <Box as="th">TRADING CURRENCY</Box>
                  <Box as="th">BBG CODE</Box>
                  <Box as="th">RIC</Box>
                  <Box as="th">ISIN</Box>
                  <Box as="th">SEDOL</Box>
               </Box>
            </thead>
            <tbody>
               {tableData &&
                  tableData.length > 0 &&
                  tableData.map((item: any) => (
                     <TableRow key={item._uid} item={item} />
                  ))}
            </tbody>
         </table>
      </Box>
   )
}

export default InvestorsTable
