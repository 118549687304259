import { Box } from '@components/UI/Grid/Grid'
import React from 'react'
import styles from './PeriodicSlider.module.scss'
import { periodicSchema } from '@lib/periodicTable'
import Markdown from '@components/UI/Markdown/Markdown'

interface PeriodicItem {
   title: string
   number: number
}

const PeriodicTable = ({ activeItems }: { activeItems: any }) => {
   const periodicLabels: PeriodicItem[] = []

   if (periodicSchema && periodicSchema?.length > 0) {
      periodicSchema.forEach((item: any) => {
         if (item && item?.items && item?.items?.length > 0) {
            item.items.forEach((subItem: any) => {
               periodicLabels.push(subItem)
            })
         }
      })
   }

   return (
      <Box width={1}>
         <div className={styles.wrapper}>
            {activeItems &&
               activeItems?.length > 0 &&
               activeItems.map((item: any, index: number) => {
                  const { text, title, number } = item || {}
                  const periodicItem =
                     periodicLabels && periodicLabels?.length > 0
                        ? periodicLabels.find(
                             (label: any) =>
                                parseInt(label.number) === parseInt(number),
                          )
                        : null

                  return (
                     <Box
                        key={item?._uid}
                        className={styles.card}
                        marginLeft={[5]}
                        marginRight={index === activeItems.length - 1 ? 5 : 0}
                        bg="var(--white)"
                     >
                        {periodicItem && periodicItem?.title && (
                           <Box className={styles.letter}>
                              {periodicItem.title}
                           </Box>
                        )}
                        {title && (
                           <Box
                              className={styles.title}
                              marginBottom={[3]}
                              paddingBottom={[3]}
                           >
                              {title}
                           </Box>
                        )}
                        {text && (
                           <Box className={styles.text}>
                              <Markdown>{text}</Markdown>
                           </Box>
                        )}
                     </Box>
                  )
               })}
         </div>
      </Box>
   )
}

export default PeriodicTable
