const locales = ['en', 'sv', 'no']

const i18n = {
   locales,
   defaultLocale: locales[0],
   localeDetection: false,
   pages: {
      '*': ['common'], // Namespaces that you want to import per page (we stick to one namespace for all the application in this tutorial)
   },
}

module.exports = i18n
