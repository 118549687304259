import React from 'react'
import SEO from '@components/Head/SEO/SEO'
import { getSeo } from '@lib/helpers'
import { SeoStoryblok } from 'types/storyblok-components'

export interface SEOPageProps {
   storyblokData?: SeoStoryblok | any
   customData?: { title?: string; description?: string; image?: string }
}

const SEOPage: React.FC<SEOPageProps> = ({ storyblokData, customData }) => {
   const { seo_title, seo_description, seo_image } = getSeo(
      storyblokData,
      customData,
   )

   // if (!seo_title && !customData) {
   //    return null
   // }
   return (
      <SEO title={seo_title} description={seo_description} image={seo_image} />
   )
}

export default SEOPage
