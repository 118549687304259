import { ref, get, child } from 'firebase/database'
import { getFirebaseDatabase } from './firebase'

export async function getHoldings(holding = 'essentialMetals') {
   const dbRef = ref(getFirebaseDatabase())
   let response = []
   const metadata = {
      latestFetch: null,
      delay: 30,
   }
   const dataHandler = {}
   await get(child(dbRef, holding))
      .then((snapshot) => {
         if (snapshot.exists()) {
            const rawData = snapshot.val()
            if (rawData.data) {
               Object.keys(rawData.data).forEach((key) => {
                  Object.keys(rawData.data[key]).forEach((attrKey) => {
                     if (dataHandler[attrKey]) {
                        dataHandler[attrKey][key] = rawData.data[key][attrKey]
                     } else {
                        dataHandler[attrKey] = {}
                        dataHandler[attrKey][key] = rawData.data[key][attrKey]
                     }
                  })
               })
            }
            if (rawData.metadata) {
               metadata.latestFetch = rawData.metadata.latestFetch
               metadata.delay = rawData.metadata.delay
            }
            if (rawData.symbols) {
               Object.keys(rawData.symbols).forEach((key) => {
                  if (dataHandler[key]) {
                     dataHandler[key] = {
                        ...dataHandler[key],
                        ...rawData.symbols[key],
                     }
                  } else {
                     dataHandler[key] = {
                        ...rawData.symbols[key],
                     }
                  }
               })
            }

            if (dataHandler) {
               response = Object.keys(dataHandler)
                  .map((key) => ({
                     key: key,
                     ...dataHandler[key],
                  }))
                  .filter((data) => data.name)
            }
         } else {
            console.log('No data available')
         }
      })
      .catch((error) => {
         console.error(error)
      })
   return { metadata, holdings: response }
}
