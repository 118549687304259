import React, { useState, FormEvent } from 'react'
import { useRouter } from 'next/router'
import styles from './LanguagePicker.module.scss'
import useUi from 'stores/ui/useUi'
import NextImage from 'next/image'
import classnames from 'classnames'

const LanguagePicker = ({ id }: { id: string }) => {
   const router = useRouter()
   const { setActiveMenu } = useUi()
   const { pathname, query, asPath, locales, locale } = router || {}
   const [language, setLanguage] = useState(locale)
   const [activeSelect, setActiveSelect] = useState(false)

   const handleChange = (e: FormEvent) => {
      // TODO: Set NEXT_LOCALE cookie on change --> https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
      const target = e.target as HTMLSelectElement
      setLanguage(target.value)
      setActiveMenu('')

      router.push({ pathname, query }, asPath, {
         locale: target.value,
         shallow: false,
      })
   }

   const handleClick = (language: string) => {
      setLanguage(language)
      setActiveMenu('')

      router.push({ pathname, query }, asPath, {
         locale: language,
         shallow: false,
      })
   }

   return (
      <div
         className={classnames(
            styles.wrapper,
            activeSelect ? styles.active : '',
         )}
      >
         <div className={styles.language}>{language}</div>
         <div className="hideMobile">
            <button
               className={classnames(styles.button)}
               onClick={() => setActiveSelect(!activeSelect)}
            >
               {language}
            </button>
            {locales && locales.length > 0 && (
               <div
                  className={classnames(
                     styles.customSelect,
                     activeSelect ? styles.open : '',
                  )}
               >
                  {locales.map((loc: string) => (
                     <button
                        className={classnames(
                           styles.customOption,
                           loc === language && styles.active,
                        )}
                        key={loc}
                        value={loc}
                        onClick={() => {
                           setActiveSelect(!activeSelect), handleClick(loc)
                        }}
                     >
                        {loc}
                     </button>
                  ))}
               </div>
            )}
         </div>
         <div className="hideDesktop">
            <select
               className={classnames(styles.select)}
               name="locales"
               id={id}
               onChange={(e) => handleChange(e)}
               defaultValue={language}
            >
               {locales &&
                  locales.length > 0 &&
                  locales.map((loc: string) => (
                     <option key={loc} value={loc}>
                        {loc}
                     </option>
                  ))}
            </select>
         </div>
         <div className={styles.arrow}>
            <div className={styles.arrowBlack}>
               <NextImage
                  src="/icons/material/drop-down-black.svg"
                  width="24"
                  height="24"
                  alt="Chevron icon"
               />
            </div>
            <div className={styles.arrowWhite}>
               <NextImage
                  src="/icons/material/drop-down-white.svg"
                  width="24"
                  height="24"
                  alt="Chevron icon"
               />
            </div>
         </div>
      </div>
   )
}

export default LanguagePicker
