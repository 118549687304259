import * as yup from 'yup'

const contactFormSchema = yup
   .object({
      firstname: yup.string(),
      lastname: yup.string(),
      email: yup
         .string()
         .required()
         .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
      company: yup.string(),
      message: yup.string().required(),
   })
   .required()

export { contactFormSchema }
