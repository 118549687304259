import * as yup from 'yup'

const newsletterSchema = yup
   .object({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup
         .string()
         .required()
         .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
      company: yup.string(),
      country_of_residence: yup.string(),
      typ_av_investerare: yup.string().required(),
      hs_language: yup.string().required(),
      investing_status: yup.array(yup.string()).nullable(),
      communication: yup.boolean(),
      press: yup.boolean(),
      newsletter: yup.boolean().oneOf([true]),
   })
   .required()

// const newsletterSchema = yup
//    .object({
//       firstName: yup.string(),
//       lastName: yup.string(),
//       email: yup
//          .string()

//          .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
//       company: yup.string(),
//       country: yup.string(),
//       language: yup.string(),
//       type: yup.string(),
//       investments: yup.array(yup.string()),
//       agreements: yup.boolean(),
//       newsletter: yup.boolean().oneOf([true]),
//    })
//    .required()

export { newsletterSchema }
