import React from 'react'
import { PageHighlightProps } from './types'
import styles from './PageHighlight.module.scss'
import { Flex, Box } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Markdown from '@components/UI/Markdown/Markdown'
import Image from '@components/UI/Image/Image'

const PageHighlight: React.FC<PageHighlightProps> = ({ blok }) => {
   const {
      label,
      title,
      left_image,
      right_image,
      excerpt,
      text,
      link,
      background,
      direction,
      title_markup,
   } = blok

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         width={1}
         flexDirection={[
            'column',
            null,
            null,
            direction === 'reverse' ? 'row-reverse' : 'row',
         ]}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Box
            height={['75vw', null, '50vw', 'auto']}
            className={styles.leftImage}
            width={[1, null, null, 1 / 2]}
            marginRight={direction === 'reverse' ? [0, null, null, 0] : 0}
         >
            {left_image && left_image.filename && (
               <Image alt={label} src={left_image.filename} />
            )}
         </Box>
         <Flex flexDirection="column" width={[1, null, null, 1 / 2]}>
            {right_image && right_image.filename && (
               <Image alt={title} src={right_image.filename} />
            )}
            <Flex
               paddingLeft={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  direction !== 'reverse'
                     ? 10
                     : 'var(--container-side-padding)',
               ]}
               paddingRight={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
               paddingTop={[10, null, null, 20]}
               paddingBottom={[10, null, null, 20]}
               flexDirection="column"
               width={1}
               alignItems="flex-start"
            >
               {label && (
                  <Box
                     marginBottom={
                        (link && link?.length > 0) || text || excerpt || title
                           ? [4, null, null, 6]
                           : 0
                     }
                  >
                     <Label title={label} />
                  </Box>
               )}
               {title && (
                  <Box
                     className={styles.title}
                     marginBottom={
                        (link && link?.length > 0) || text || excerpt
                           ? [5, null, null, 6, null, 6]
                           : 0
                     }
                     as={getTitleMarkup(title_markup)}
                  >
                     {title}
                  </Box>
               )}
               {excerpt && (
                  <Box
                     className={styles.excerpt}
                     marginBottom={
                        (link && link?.length > 0) || text
                           ? [5, null, null, 5, null, 6]
                           : 0
                     }
                     as="p"
                  >
                     {excerpt}
                  </Box>
               )}
               {text && (
                  <Box
                     width={1}
                     marginBottom={
                        link && link?.length > 0
                           ? [5, null, null, 8, null, 10]
                           : 0
                     }
                  >
                     <Markdown className={styles.text}>{text}</Markdown>
                  </Box>
               )}
               {link &&
                  link?.length > 0 &&
                  link.map((blok: CtaButtonStoryblok) => {
                     return (
                        <DynamicComponent
                           key={blok._uid}
                           background={'#fff'}
                           blok={blok}
                        />
                     )
                  })}
            </Flex>
         </Flex>
      </Flex>
   )
}

export default PageHighlight
