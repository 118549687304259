import React from 'react'
import { HeroProps } from './types'
import { Flex, Box, Container } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import styles from './HeroWithButtons.module.scss'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import classnames from 'classnames'
import Image from '@components/UI/Image/Image'
import { useRouter } from 'next/router'
import {
   getLatestData,
   getLatestSharedData,
   getNavString,
} from '@lib/charthelpers'
import useTranslation from 'next-translate/useTranslation'

const getPercentageString = (
   percentage: string,
   locale: string | undefined,
) => {
   if (!percentage) {
      return ''
   }
   return `${percentage.includes('-') ? '' : '+'}${percentage.replace(
      '.',
      locale === 'sv' || locale === 'no' ? ',' : '.',
   )}%`
}

const HeroWithButtons: React.FC<HeroProps> = ({
   blok,
   navData,
   navDataDailyA,
   navDataDailyB,
   sharedNavData,
}) => {
   const { locale } = useRouter()
   const { t } = useTranslation('common')
   const {
      title,
      label,
      description,
      product,
      cta,
      graphic_element,
      smallMobile,
   } = blok

   const latestNav = product
      ? getLatestData(
           typeof product === 'string' ? product : '',
           navData,
           navDataDailyA,
           navDataDailyB,
        )
      : null
   const latestShared = sharedNavData
      ? getLatestSharedData(sharedNavData)
      : null

   return (
      <Box
         className={classnames(
            styles.hero,
            smallMobile ? styles.smallMobile : '',
         )}
      >
         <Flex
            className={styles.content}
            alignItems="center"
            justifyContent="center"
         >
            <Container>
               <Flex
                  flexDirection="column"
                  height="100%"
                  justifyContent="center"
                  alignItems="flex-start"
                  width={1}
               >
                  {label && <Label title={label ? label : ''} />}
                  {title && (
                     <Box
                        as="h1"
                        className={styles.title}
                        marginTop={[3, null, null, 5]}
                        width={[1, null, null, 8 / 12]}
                     >
                        {title}
                     </Box>
                  )}
                  <Box marginTop={[4, null, null, null, 6]}>
                     {product && (
                        <Box
                           width={[1, null, null, 1]}
                           className={styles.subtitle}
                           as="span"
                        >
                           {latestNav
                              ? getNavString(latestNav, locale, t)
                              : 'Fetching NAV data'}
                        </Box>
                     )}
                     {product &&
                        product !== 'AUAG GOLD MINING ETF' &&
                        latestShared && (
                           <Flex flexDirection="column">
                              <Box className={styles.description}>
                                 {`${t('shareClass')} ${
                                    latestShared.shareClass
                                 } ${latestShared.price.replace(
                                    '.',
                                    locale === 'sv' ? ',' : '.',
                                 )} ${latestShared.currency} (${
                                    latestShared.percentage > 0 ? '+' : ''
                                 }${parseFloat(latestShared.percentage).toFixed(
                                    2,
                                 )}%)`}
                              </Box>
                           </Flex>
                        )}
                     {product === 'AUAG GOLD MINING ETF' &&
                     latestNav &&
                     latestNav.price ? (
                        <Flex flexDirection="column">
                           <Box
                              className={styles.description}
                              marginTop={[1, null, null, null, 2]}
                           >
                              {`ETF: ${latestNav.euro.replace(
                                 '.',
                                 locale === 'sv' || locale === 'no' ? ',' : '.',
                              )} EUR (${getPercentageString(
                                 latestNav.euroPercentage,
                                 locale,
                              )}) | ${latestNav.gbp.replace(
                                 '.',
                                 locale === 'sv' || locale === 'no' ? ',' : '.',
                              )} GBP (${getPercentageString(
                                 latestNav.gbpPercentage,
                                 locale,
                              )}) | ${latestNav.chf.replace(
                                 '.',
                                 locale === 'sv' || locale === 'no' ? ',' : '.',
                              )} CHF (${getPercentageString(
                                 latestNav.chfPercentage,
                                 locale,
                              )})`}
                           </Box>
                           {latestNav &&
                              latestNav.currentDailyA &&
                              latestNav.currentDailyA.price &&
                              latestNav.currentDailyB &&
                              latestNav.currentDailyB.price && (
                                 <Box
                                    className={styles.description}
                                    marginTop={[1, null, null, null, 2]}
                                 >
                                    {`${t(
                                       'dailyTraded',
                                    )}: ${latestNav.currentDailyA.price.replace(
                                       '.',
                                       locale === 'sv' ? ',' : '.',
                                    )} SEK (${
                                       latestNav.currentDailyA.percentage > 0
                                          ? '+'
                                          : ''
                                    }${parseFloat(
                                       latestNav.currentDailyA.percentage,
                                    ).toFixed(
                                       2,
                                    )}%) | ${latestNav.currentDailyB.price.replace(
                                       '.',
                                       locale === 'sv' ? ',' : '.',
                                    )} EUR (${
                                       latestNav.currentDailyB.percentage > 0
                                          ? '+'
                                          : ''
                                    }${parseFloat(
                                       latestNav.currentDailyB.percentage,
                                    ).toFixed(2)}%)`}
                                 </Box>
                              )}
                        </Flex>
                     ) : description ? (
                        <Box
                           className={styles.description}
                           marginTop={[1, null, null, null, 2]}
                        >
                           {description}
                        </Box>
                     ) : null}
                  </Box>

                  <Box marginTop={[6, null, null, null, 10]}>
                     {cta && cta?.length > 0 && (
                        <Flex flexWrap="wrap">
                           {cta.map(
                              (blok: CtaButtonStoryblok, index: number) => {
                                 return (
                                    <Box
                                       key={blok._uid}
                                       marginRight={
                                          index < cta?.length - 1
                                             ? [3, null, 4]
                                             : 0
                                       }
                                       marginBottom={[3, null, 4]}
                                    >
                                       <DynamicComponent blok={blok} />
                                    </Box>
                                 )
                              },
                           )}
                        </Flex>
                     )}
                  </Box>
               </Flex>
            </Container>

            <Flex alignItems="center" className={styles.circle}>
               {graphic_element && graphic_element.filename && (
                  <Image
                     preload
                     // isCritical
                     sizes={{
                        mobile: '50vw',
                        tablet: '50vw',
                        desktop: '50vw',
                     }}
                     alt="AuAg Graphic"
                     src={graphic_element.filename}
                  />
               )}
            </Flex>
         </Flex>
      </Box>
   )
}

export default HeroWithButtons
