import React from 'react'
import { TwoColumnTextProps } from './types'
import styles from './TwoColumnText.module.scss'
import { Box, Container, Flex, Row, Column } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'

import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Label from '@components/UI/Label/Label'
const TwoColumnText: React.FC<TwoColumnTextProps> = ({ blok }) => {
   const { text, title, cta, background, label, title_markup } = blok
   return (
      <Flex
         as="section"
         flexDirection="column"
         width={1}
         className={styles.wrapper}
         paddingY={[10, null, null, 20]}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Flex alignItems={'flex-start'}>
               <Box marginBottom={[4, null, null, 6]}>
                  {label && <Label title={label} />}
               </Box>
            </Flex>
            <Row justifyContent="space-between">
               <Column width={[1, null, null, 5 / 12]}>
                  <Box
                     className={styles.title}
                     as={getTitleMarkup(title_markup)}
                     marginBottom={[5, null, null, 0]}
                  >
                     {title}
                  </Box>
               </Column>
               <Column width={[1, null, null, 6 / 12]}>
                  <Flex
                     alignItems="flex-start"
                     flexDirection="column"
                     width={1}
                  >
                     {text && (
                        <Box className={styles.text}>
                           <Markdown>{text}</Markdown>
                        </Box>
                     )}
                     {cta &&
                        cta?.length > 0 &&
                        cta.map((blok: any) => (
                           <Box key={blok._uid} marginTop={[6, null, null, 15]}>
                              <DynamicComponent
                                 blok={blok}
                                 className={styles.buttonFilled}
                              />
                           </Box>
                        ))}
                  </Flex>
               </Column>
            </Row>
         </Container>
      </Flex>
   )
}

export default TwoColumnText
