import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import React from 'react'
import styles from './Redirects.module.scss'
import SbEditable from 'storyblok-react'

const Redirects: React.FC = ({ blok }: any) => {
   const { redirects } = blok
   return (
      <Container>
         <Flex
            marginTop={20}
            className={styles.wrapper}
            flexDirection="column"
            width={1}
         >
            <Flex>
               <Box className={styles.text}>From</Box>
               <Box className={styles.text}>TO</Box>
               <Box className={styles.perm}>Permanent</Box>
            </Flex>
            {redirects &&
               redirects.map((blok: any) => (
                  <SbEditable key={blok._uid} content={blok}>
                     <Flex>
                        <Box className={styles.text}>{blok.from}</Box>
                        <Box className={styles.text}>{blok.to}</Box>
                        <Box className={styles.perm}>
                           {blok.permanent ? 'Permanent' : 'Not Permanent'}
                        </Box>
                     </Flex>
                  </SbEditable>
               ))}
         </Flex>
      </Container>
   )
}

export default Redirects
