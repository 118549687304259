import React, { useState } from 'react'
import { TabbedContentListProps } from './types'
import styles from './TabbedContentList.module.scss'
import { Flex, Box } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'
import Markdown from '@components/UI/Markdown/Markdown'
import { TabItemStoryblok } from 'types/storyblok-components'

const TabbedContentList: React.FC<TabbedContentListProps> = ({ blok }) => {
   const { label, title, items, background } = blok
   const [activeIndex, setActiveIndex] = useState(0)
   const activeItem: TabItemStoryblok | false =
      items && items.length > 0 && items[activeIndex]
   activeItem
   return (
      <Flex
         as="section"
         className={styles.wrapper}
         flexDirection="row"
         justifyContent={['space-between', null, null, 'flex-start']}
         alignItems={['stretch', null, null, 'stretch']}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Flex
            className={styles.content}
            flexDirection="column"
            alignItems="flex-start"
            width={[1, null, null, 6 / 12]}
            paddingY={[10, null, null, null, 20]}
            paddingLeft={[
               'var(--container-side-padding-mobile)',
               null,
               null,
               'var(--container-side-padding)',
            ]}
            paddingRight={[6, null, null, 0]}
         >
            <Box marginBottom={[4, null, null, 6]}>
               {label && <Label title={label} />}
            </Box>

            {title && (
               <Box
                  as="h3"
                  className={styles.title}
                  width={[1, null, null, 6 / 7]}
                  marginBottom={[15, null, null, 42]}
               >
                  {title}
               </Box>
            )}
            <Box className={styles.bigNumber}>{activeIndex + 1}</Box>
            {activeItem && activeItem.title && (
               <Box
                  paddingBottom={[5, null, null, 10]}
                  marginBottom={[5, null, null, 10]}
                  width={[1, null, null, 4 / 7]}
                  className={styles.itemTitle}
                  as="span"
               >
                  {activeItem.title}
               </Box>
            )}
            {activeItem && activeItem.text && (
               <Box width={[1, null, null, 5 / 7]}>
                  <Markdown className={styles.text}>{activeItem.text}</Markdown>
               </Box>
            )}
         </Flex>
         <Flex
            className={styles.right}
            paddingLeft={[0, null, null, 20]}
            paddingY={[4, null, null, null, 20, 20]}
            paddingRight={[0, null, null, 'var(--container-side-padding)']}
            flexDirection="column"
            justifyContent={['center', null, null, 'center']}
            alignItems={['flex-start', null, null, 'center']}
            width={['80px', null, null, 6 / 12]}
         >
            <Flex
               width={[1, null, null, 2 / 3]}
               flexDirection="column"
               justifyContent="center"
            >
               {items &&
                  items.length > 0 &&
                  items.map((item: TabItemStoryblok, index: number) => (
                     <Flex
                        as="button"
                        key={item._uid}
                        onClick={() => {
                           setActiveIndex(index)
                        }}
                        className={
                           index === activeIndex
                              ? [styles.button, styles.active].join(' ')
                              : styles.button
                        }
                        alignItems="center"
                        justifyContent={['center', null, null, 'left']}
                        flexDirection="row"
                        marginTop={[
                           '16px !important',
                           null,
                           null,
                           '0px !important',
                        ]}
                        marginBottom={
                           index === items.length - 1
                              ? 0
                              : [
                                   '16px !important',
                                   null,
                                   null,
                                   null,
                                   '60px !important',
                                   '80px !important',
                                ]
                        }
                     >
                        <Flex
                           className={styles.number}
                           alignItems="center"
                           justifyContent="center"
                           marginRight={[0, null, 0, 11]}
                        >
                           {index + 1}
                        </Flex>
                        <Box className="hideMobile">{item.title}</Box>
                     </Flex>
                  ))}
            </Flex>
         </Flex>
      </Flex>
   )
}

export default TabbedContentList
