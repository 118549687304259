import { Box, Container, Flex } from '@components/UI/Grid/Grid'
import React, { useEffect } from 'react'
import styles from './HoldingData.module.scss'

const HoldingData = ({ blok }: any) => {
   const {
      tradingview_iframe_code,
      // clarifications,
      // disclaimer,
      introduction,
      title,
   } = blok

   useEffect(() => {
      document.body.classList.add('edit-chart')
      return () => {
         document.body.classList.remove('edit-chart')
      }
   }, [])

   return (
      <Flex paddingY={[40]} className={styles.wrapper} width={1}>
         <Container>
            <Flex flexDirection={'column'}>
               <Box
                  className={styles.title}
                  as="h3"
                  marginBottom={[5, null, null, 10]}
               >
                  {title}
               </Box>
               <Box as="p" width={[7 / 12]} className={styles.intro}>
                  {introduction}
               </Box>
               <Box>
                  <div
                     className={styles.tradingview}
                     dangerouslySetInnerHTML={{
                        __html: tradingview_iframe_code,
                     }}
                  />
               </Box>
               <Box marginTop={20}>
                  {/* {type === 'silver' ? (
                     <div className="auag-table__wrapper">
                        <table
                           id="auag-silverbullet-table"
                           className="auag-table auag-table--dark"
                        >
                           <thead
                              id="silver-bullet__name-table"
                              className="auag-table__names flex-column"
                           >
                              <tr className="auag-table__row flex-order--first holding-label">
                                 <th
                                    className="auag-table__data auag-table__data--heading auag-data-sort"
                                    data-sort="name"
                                 >
                                    Holdings
                                 </th>
                              </tr>
                           </thead>
                           <tbody
                              id="silver-bullet__data-table"
                              className="auag-table__content flex-column"
                           >
                              <tr className="auag-table__row flex-order--first auag-table-labels">
                                 <th className="auag-table__data auag-table__data--heading">
                                    Last price
                                 </th>
                                 <th
                                    className="auag-table__data auag-table__data--heading auag-data-sort"
                                    data-sort="changePercent"
                                 >
                                    Change
                                 </th>
                                 <th
                                    className="
                  auag-table__data auag-table__data--heading
                  auag-data-sort auag-data-sort--active
               "
                                    data-sort="category"
                                 >
                                    Category
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Target weight
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Currency
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Exchange
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Updated
                                 </th>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  ) : (
                     <div className="auag-table__wrapper">
                        <table
                           id="auag-precious-green-table"
                           className="auag-table auag-table--dark"
                        >
                           <thead
                              id="precious-green__name-table"
                              className="auag-table__names flex-column"
                           >
                              <tr className="auag-table__row flex-order--first holding-label">
                                 <th
                                    className="auag-table__data auag-table__data--heading precious-green-data-sort"
                                    data-sort="name"
                                 >
                                    Holdings
                                 </th>
                              </tr>
                           </thead>
                           <tbody
                              id="precious-green__data-table"
                              className="auag-table__content flex-column"
                           >
                              <tr className="auag-table__row flex-order--first auag-table-labels">
                                 <th className="auag-table__data auag-table__data--heading">
                                    Last price
                                 </th>
                                 <th
                                    className="auag-table__data auag-table__data--heading precious-green-data-sort"
                                    data-sort="changePercent"
                                 >
                                    Change
                                 </th>
                                 <th
                                    className="auag-table__data auag-table__data--heading precious-green-data-sort precious-green-data-sort--active"
                                    data-sort="category"
                                 >
                                    Category
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Target weight
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Currency
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Exchange
                                 </th>
                                 <th className="auag-table__data auag-table__data--heading">
                                    Updated
                                 </th>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  )} */}
               </Box>
            </Flex>
         </Container>
      </Flex>
   )
}

export default HoldingData
