import React, { useState, useEffect } from 'react'
import { ContentTabsProps } from './types'
import styles from './ContentTabs.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, getTitleMarkup } from '@lib/helpers'

import { stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import classnames from 'classnames'
import GraphicWave from '@components/UI/GraphicWave/GraphicWave'
import { useRouter } from 'next/router'
import { updateSearchParamAndHash } from '@lib/helpers'

const ContentTabs: React.FC<ContentTabsProps> = ({ blok }) => {
   const router = useRouter()
   const { query } = router
   const queryTab = query && query.tab ? query.tab : ''

   const {
      label,
      title,
      tabs,
      background,
      show_graphic_element,
      isLast,
      title_markup,
   } = blok

   const [activeTabIndex, setActiveTab] = useState(0)

   const sectionID = label ? stringify(label) : ''

   useEffect(() => {
      // Get active tab from query parameter
      const getActiveIndex = (blok: any) =>
         blok?.title &&
         queryTab &&
         typeof queryTab === 'string' &&
         stringify(blok.title) === stringify(queryTab)
      const activeIndex =
         tabs && tabs.length > 0 && tabs.findIndex(getActiveIndex) >= 0
            ? tabs.findIndex(getActiveIndex)
            : 0

      setActiveTab(activeIndex)
   }, [queryTab])

   return (
      <Box
         as="section"
         width={1}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
         className={styles.wrapper}
      >
         <div id={sectionID} className="anchor" />
         <Container>
            <Flex
               alignItems="flex-start"
               flexDirection="column"
               paddingY={[10, null, null, 20]}
               className={styles.content}
            >
               {label && (
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
               )}
               {title && (
                  <Box
                     className={styles.title}
                     as={getTitleMarkup(title_markup)}
                     marginBottom={[5, null, null, 4]}
                  >
                     {title}
                  </Box>
               )}

               <Box width={1}>
                  <Row>
                     <Column width={[1, null, null, 12 / 12]}>
                        <Box width={1} className={styles.tabs}>
                           {tabs &&
                              tabs.map((blok: any, index: number) => {
                                 return (
                                    <Box
                                       as="button"
                                       key={`${blok._uid}-tab`}
                                       className={classnames(
                                          styles.tab,
                                          activeTabIndex === index
                                             ? styles.active
                                             : '',
                                       )}
                                       onClick={() => {
                                          setActiveTab(index)

                                          const activeTab =
                                             tabs && tabs.length > 0
                                                ? tabs[index].title
                                                : ''

                                          if (activeTab) {
                                             updateSearchParamAndHash({
                                                parameter: {
                                                   name: 'tab',
                                                   value: stringify(activeTab),
                                                },
                                                hash: sectionID,
                                             })
                                          }
                                       }}
                                    >
                                       <Box as="span">{blok.title}</Box>
                                    </Box>
                                 )
                              })}
                        </Box>
                     </Column>
                  </Row>
                  <Box paddingTop={[5, null, null, 15]}>
                     {tabs &&
                        tabs.length > 0 &&
                        tabs.map((blok: any, index: number) => (
                           <Box
                              key={`${blok._uid}-content`}
                              className={
                                 activeTabIndex !== index ? styles.hidden : ''
                              }
                           >
                              <DynamicComponent blok={blok} />
                           </Box>
                        ))}
                  </Box>
               </Box>
            </Flex>
         </Container>
         <Box className={styles.graphicWrapper}></Box>
         {show_graphic_element && (
            <GraphicWave
               className={
                  isLast
                     ? [styles.graphic, styles.last].join(' ')
                     : styles.graphic
               }
            />
         )}
      </Box>
   )
}

export default ContentTabs
