import dynamic from 'next/dynamic'
import { Box, Container, Flex } from '@components/UI/Grid/Grid'

import React from 'react'
import styles from './TradingViewWidget.module.scss'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { getTitleMarkup, stringify } from '@lib/helpers'

const TradingViewClient = dynamic(() => import('./TradingViewClient.js'), {
   ssr: false,
})

const TradingViewWidget = ({ blok }: any) => {
   const { label, title, text, options, autosize, title_markup } = blok
   const sectionID = label ? stringify(label) : ''
   if (!options) {
      return null
   }
   return (
      <Flex as="section" style={{ position: 'relative' }}>
         <Container paddingY={[10, null, null, 20]}>
            <div id={sectionID} className="anchor" />
            <Flex
               width={[1, null, null, 6 / 12]}
               flexDirection={'column'}
               alignItems={'flex-start'}
            >
               {label && (
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
               )}
               {title && (
                  <Box
                     className={styles.title}
                     as={getTitleMarkup(title_markup)}
                     marginBottom={[5, null, null, 10]}
                  >
                     {title}
                  </Box>
               )}
               {text && (
                  <Box
                     marginBottom={[5, null, null, 10]}
                     width={1}
                     className={styles.intro}
                  >
                     <Markdown>{text}</Markdown>
                  </Box>
               )}
            </Flex>
            <div
               className={[styles.wrapper, autosize ? styles.auto : ''].join(
                  ' ',
               )}
            >
               <div className={styles.wrapper}>
                  <TradingViewClient blok={blok} />
               </div>
            </div>
         </Container>
      </Flex>
   )
}

export default TradingViewWidget
