import { useEffect, useState } from 'react'

import StoryblokClient from 'storyblok-js-client'
import { config } from 'config'

const Storyblok = new StoryblokClient({
   accessToken: process.env.STORYBLOK_PREVIEW_TOKEN,
   cache: {
      clear: 'auto',
      type: 'memory',
   },
})

export function useStoryblok(originalStory, preview) {
   let [story, setStory] = useState(originalStory)
   let initialized = false

   // appends the bridge script tag to our document
   // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
   function addBridge(callback) {
      // check if the script is already present
      const existingScript = document.getElementById('storyblokBridge')
      if (!existingScript) {
         const script = document.createElement('script')
         script.src = '//app.storyblok.com/f/storyblok-v2-latest.js'
         script.id = 'storyblokBridge'
         document.body.appendChild(script)
         script.onload = () => {
            // once the scrip is loaded, init the event listeners
            callback()
         }
      } else {
         callback()
      }
   }

   useEffect(() => {
      // adds the events for updating the visual editor
      // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
      function initEventListeners() {
         const { StoryblokBridge } = window
         console.log('INSIDE', typeof StoryblokBridge)
         if (typeof StoryblokBridge !== 'undefined' && !initialized) {
            console.log(1)
            initialized = true
            // initialize the bridge with your token
            const storyblokInstance = new StoryblokBridge({
               resolveRelations:
                  config?.storyblok?.resolveRelations &&
                  config.storyblok.resolveRelations?.length > 0
                     ? config.storyblok.resolveRelations.join(',')
                     : [],
               cv: Date.now(),
            })

            // reload on Next.js page on save or publish event in the Visual Editor
            storyblokInstance.on(['change', 'published'], () =>
               location.reload(true),
            )

            // live update the story on input events
            storyblokInstance.on('input', (event) => {
               console.log('INPUT')
               if (story && event.story.content._uid === story.content._uid) {
                  setStory(event.story)
               }
            })

            storyblokInstance.on('enterEditmode', (event) => {
               console.log('2')
               // loading the draft version on initial enter of editor
               Storyblok.get(`cdn/stories/${event.storyId}`, {
                  version: 'draft',
                  resolve_relations:
                     config?.storyblok?.resolveRelations &&
                     config.storyblok.resolveRelations?.length > 0
                        ? config.storyblok.resolveRelations.join(',')
                        : [],
                  cv: Date.now(),
               })
                  .then(({ data }) => {
                     if (data.story) {
                        setStory(data.story)
                     }
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            })
         } else {
            if (!initialized) {
               setTimeout(() => {
                  initEventListeners()
               }, 2000)
            }
         }
      }

      // only load inside preview mode

      if (preview) {
         console.log('INIT')
         // first load the bridge, then initialize the event listeners
         addBridge(initEventListeners)
      }
   }, [])

   return preview ? story : originalStory
}

export default Storyblok
