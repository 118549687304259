import React from 'react'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import { CtaButtonProps } from './types'
import styles from './CtaButton.module.scss'
import { Box } from '@components/UI/Grid/Grid'
import { getHrefFromStoryblokLink } from '@lib/helpers'
const CtaButton: React.FC<CtaButtonProps> = ({
   blok,
   className,
   onClick,
   type,
}) => {
   const classNames = [className, styles.button]
   // let background = '#fff'
   const { link, color, hover_color, size } = blok || {}
   const hasLink = link && link.length > 0 && link[0]
   const title = hasLink ? link[0].title : ''
   const href = hasLink ? getHrefFromStoryblokLink({ ...link[0] }) : ''

   switch (color) {
      case 'black':
         classNames.push(styles.black)
         break
      case 'white':
         classNames.push(styles.white)
         break
      case 'white-filled':
         classNames.push(styles.whiteFilled)
         break
      case 'purple':
         classNames.push(styles.purple)
         break
   }

   switch (size) {
      case 'dense':
         classNames.push(styles.dense)
         break
   }

   switch (hover_color) {
      case 'black':
         classNames.push(styles.blackHover)
         break
      case 'white':
         classNames.push(styles.whiteHover)
         break
      case 'purple':
         classNames.push(styles.purpleHover)
         break
   }

   // if (!(cached_url && title) || (email && title)) {
   //    classNames.push(styles.isButton)
   // }

   return href && title ? (
      <LinkComponent
         href={href}
         className={classNames.join(' ')}
         onClick={onClick}
         title={title}
      >
         <Box as="span" className={styles.text}>
            {title}
         </Box>
         {/* <Box style={{ background: background }} className={styles.gap} /> */}
      </LinkComponent>
   ) : (type === 'submit' || type === 'button') && title ? (
      <button
         type={type}
         className={classNames.join(' ')}
         onClick={onClick}
         title={title}
      >
         <Box as="span" className={styles.text}>
            {title}
         </Box>
         {/* <Box style={{ background: background }} className={styles.gap} /> */}
      </button>
   ) : null
}

export default CtaButton
