import React from 'react'
import { ContactModuleProps } from './types'
import styles from './ContactModule.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import { ContactCardStoryblok } from 'types/storyblok-components'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'

const ContactModule: React.FC<ContactModuleProps> = ({ blok }) => {
   const {
      label,
      title,
      description,
      contact_persons,
      title_markup,
      background,
   } = blok

   return (
      <Flex
         as="section"
         className={styles.wrapper}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Box
               className={styles.content}
               width={1}
               paddingTop={[10, null, null, 20]}
               paddingBottom={[10, null, null, 20]}
            >
               <Flex flexDirection="column" alignItems="flex-start">
                  <Box marginBottom={[4, null, null, 6]}>
                     {label && <Label title={label} />}
                  </Box>
                  <Box
                     as={getTitleMarkup(title_markup)}
                     className={styles.title}
                     marginBottom={[6]}
                  >
                     {title}
                  </Box>
                  {description && (
                     <Box
                        width={[1, null, null, 6 / 12]}
                        as="p"
                        className={styles.text}
                     >
                        {description}
                     </Box>
                  )}
               </Flex>
               <Row
                  marginTop={[10, null, null, '100px']}
                  justifyContent="space-between"
               >
                  {contact_persons &&
                     contact_persons?.length > 0 &&
                     contact_persons.map(
                        (blok: ContactCardStoryblok, index: number) => {
                           return (
                              <Column
                                 width={[1, null, null, 6 / 12]}
                                 key={blok._uid}
                                 marginBottom={[
                                    index === contact_persons.length - 1
                                       ? 0
                                       : 10,
                                    null,
                                    null,
                                    0,
                                 ]}
                              >
                                 <DynamicComponent
                                    background={
                                       getThemeColor(background).background
                                    }
                                    blok={blok}
                                 />
                              </Column>
                           )
                        },
                     )}
               </Row>
            </Box>
         </Container>
      </Flex>
   )
}

export default ContactModule
