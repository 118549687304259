import React from 'react'
import { TabInformationProps } from './types'
import styles from './TabInformation.module.scss'
import { Flex, Box, Row, Column } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Image from '@components/UI/Image/Image'
import classnames from 'classnames'

const TabInformation: React.FC<TabInformationProps> = ({ blok }) => {
   const { text, footer, content, direction, contacts, cta } = blok
   return (
      <Box className={styles.wrapper}>
         <Row
            justifyContent="space-between"
            flexDirection={[
               direction === 'reverse' ? 'column-reverse' : 'column',
               null,
               null,
               direction === 'reverse' ? 'row-reverse' : 'row',
            ]}
         >
            {cta && cta?.length > 0 && (
               <Flex
                  className={styles.cta}
                  order={[2, null, null, 1]}
                  marginY={[5, null, null, 0]}
               >
                  {cta.map((blok: any) => {
                     return (
                        <Box
                           key={blok._uid}
                           width={[1, null, null, 'auto']}
                           marginRight={[0, null, null, 8]}
                           paddingX={[2, null, null, 0]}
                        >
                           <DynamicComponent blok={blok} />
                        </Box>
                     )
                  })}
               </Flex>
            )}
            <Column width={[1, null, null, 6 / 12]} order={[1, null, null, 2]}>
               <Flex flexDirection={'column'} height="100%">
                  {text && (
                     <Box marginBottom={footer ? 0 : 8}>
                        <Markdown className={styles.text}>{text}</Markdown>
                     </Box>
                  )}
                  {footer && (
                     <Box
                        className={styles.footer}
                        paddingTop={[4, null, null, 8]}
                        marginTop={['auto']}
                     >
                        {footer}
                     </Box>
                  )}
               </Flex>
            </Column>
            <Column width={[1, null, null, 5 / 12]} order={[3, null, null, 3]}>
               {content && content?.length > 0 ? (
                  <Box marginTop={[8, null, null, 0]}>
                     {content.map((blok: any) => (
                        <div
                           className={classnames(
                              styles.content,
                              blok?.component === 'text' ? styles.text : '',
                           )}
                           key={`content-${blok?._uid}`}
                        >
                           <DynamicComponent blok={blok} />
                        </div>
                     ))}
                  </Box>
               ) : contacts && contacts.length > 0 ? (
                  <Flex className={styles.contacts}>
                     {contacts.map((blok: any) => {
                        const { title, phone, email, name, image } = blok

                        return (
                           <Box key={blok._uid} className={styles.contact}>
                              <Box
                                 className={styles.imageWrapper}
                                 marginBottom={[5, null, null, 8]}
                              >
                                 {image && image.filename && (
                                    <Image
                                       alt={name}
                                       offScreen
                                       src={image.filename}
                                    />
                                 )}
                              </Box>
                              {name && (
                                 <Box
                                    className={styles.name}
                                    marginBottom={[1, null, null, 1]}
                                 >
                                    {name}
                                 </Box>
                              )}
                              {title && (
                                 <Box
                                    marginBottom={[0, null, null, 1]}
                                    className={styles.title}
                                 >
                                    {title}
                                 </Box>
                              )}
                              {phone && (
                                 <Box
                                    marginBottom={[0, null, null, 1]}
                                    className={styles.phone}
                                 >
                                    {phone}
                                 </Box>
                              )}
                              {email && (
                                 <Box
                                    marginBottom={[0, null, null, 1]}
                                    className={styles.mail}
                                 >
                                    <LinkComponent
                                       href={`mailto:${email}`}
                                    >{`${email}`}</LinkComponent>
                                 </Box>
                              )}
                           </Box>
                        )
                     })}
                  </Flex>
               ) : null}
            </Column>
         </Row>
      </Box>
   )
}

export default TabInformation
