import React, { useState } from 'react'
import { PageNewsletterProps } from './types'
import { Flex, Box } from '@components/UI/Grid/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './PageNewsletter.module.scss'
import SEOPage from '@components/Head/SEOPage/SEOPage'
import Label from '@components/UI/Label/Label'
import { newsletterSchema } from './schemas'
import countries from './countries.json'
import Markdown from '@components/UI/Markdown/Markdown'
import CtaButton from '@components/CtaButton/CtaButton'
import InputText from '@components/UI/InputText/InputText'
import InputSelect from '@components/UI/InputSelect/InputSelect'
import InputCheckboxGroup from '@components/UI/InputCheckboxGroup/InputCheckboxGroup'
import axios from 'axios'
import { getNewsletterTranslations } from '@lib/translations'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

const PageNewsletter: React.FC<PageNewsletterProps> = ({ blok, story }) => {
   const { t } = useTranslation('common')
   const router = useRouter()
   const {
      label,
      title,
      text,
      privacy_text,
      subscription_text,
      seo_data,
      schemas,
   } = blok || {}
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(false)

   const resolver = yupResolver(newsletterSchema)

   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({
      resolver: resolver,
   })

   const countryOptions =
      countries && countries?.length > 0
         ? countries
              .sort((a: { name: string }, b: { name: string }) => {
                 if (a.name < b.name) {
                    return -1
                 }
                 if (a.name > b.name) {
                    return 1
                 }
                 return 0
              })
              .map((country: { name: string; code: string }) => ({
                 value: country?.name,
                 label: country?.name,
              }))
         : []

   const {
      languageOptions,
      typeOptions,
      investmentOptions,
      communicationOptions,
      newsletterOptions,
      pressOptions,
      subscribeTitle,
   } = getNewsletterTranslations(t)

   const onSubmit = async (data: any) => {
      setLoading(true)
      if (data) {
         const portalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID

         // New newsletter sign-up form in hubspot
         const individualFormGuid = '3767dc8e-85c9-45e0-9b68-55b08b683a58'

         // ElementsPro Form
         const investorFormGuid = '851d2c7f-59c8-4c51-9099-3be09680b606'

         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         }
         const timeOut = 3000

         const fields: any[] = []
         let communication = false
         /* eslint-disable */

         let newsletter = false
         /* eslint-enable */

         const formKeys = Object.keys(data)
         formKeys.forEach((key: string) => {
            if (
               key !== 'communication' &&
               key !== 'newsletter' &&
               key !== 'investing_status'
            ) {
               fields.push({ name: key, value: data[key] })
            }
            if (key === 'investing_status') {
               const arrayToString =
                  data[key] && data[key].length > 0 ? data[key].join(';') : ''
               fields.push({ name: key, value: arrayToString })
            }
            if (key === 'communication') {
               communication = data[key]
            }
            if (key === 'newsletter') {
               newsletter = data[key]
            }
         })

         const sub_ids = [11551622]

         if (communication) {
            sub_ids.push(426802635)
         }

         const legalConsentOptions = {
            legitimateInterest: {
               value: true,
               subscriptionTypeIds: sub_ids,
               legalBasis: 'LEAD',
               text: 'AuAg Fonder protects and respects your privacy. Your personal information is only used to provide the requested products and services. If you are interested, we would like to be able to contact you regarding our products and services and send information about other content that we think may only be of interest to you. If you give your consent for us to contact you, select the check box below for the preferred contact method:',
            },
         }

         if (fields && fields?.length > 0) {
            const press = fields.find((f: any) => f.name === 'press')
            const lang = fields.find((f: any) => f.name === 'hs_language')
            const email = fields.find((f: any) => f.name === 'email')
            const type = fields.find(
               (f: any) => f.name === 'typ_av_investerare',
            )
            if (press && press.value) {
               await axios.get(
                  `/api/press?lang=${lang.value}&email=${email.value}`,
               )
            }

            const formGuid =
               type && type.value && type.value === 'Privat Investerare'
                  ? individualFormGuid
                  : investorFormGuid
            axios
               .post(
                  `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
                  {
                     portalId,
                     formGuid,
                     fields,
                     legalConsentOptions,
                  },
                  config,
               )
               .then(() => {
                  setLoading(false)
                  router.push(
                     `/${story?.full_slug}/success?email=${email.value}`,
                  )
               })
               .catch((error) => {
                  console.log(error)
                  setLoading(false)
                  setError(true)
                  setTimeout(() => {
                     setError(false)
                     reset()
                  }, timeOut)
               })
         }
      }
   }

   return (
      <React.Fragment>
         {schemas && schemas.length > 0 && (
            <Head>
               {schemas.map((schema: any) => (
                  <script
                     key={schema._uid}
                     type="application/ld+json"
                     dangerouslySetInnerHTML={{ __html: schema.schema }}
                  />
               ))}
            </Head>
         )}
         <SEOPage
            storyblokData={seo_data}
            customData={{
               title: story?.name || '',
            }}
         />

         <Flex
            width={1}
            className={styles.wrapper}
            alignItems="flex-start"
            flexDirection={['column', null, null, 'row']}
         >
            <Flex
               className={styles.content}
               paddingY={[15, null, null, 20]}
               width={[1, null, null, 1 / 2]}
               flexDirection="column"
               justifyContent="center"
               alignItems="flex-start"
               paddingX={[
                  'var(--container-side-padding-mobile)',
                  null,
                  null,
                  'var(--container-side-padding)',
               ]}
            >
               {label && (
                  <Box marginBottom={[5, null, null, 5]}>
                     <Label title={label} />
                  </Box>
               )}
               {title && (
                  <Box
                     as="h1"
                     marginBottom={[5, null, null, 10]}
                     className={styles.title}
                  >
                     {title}
                  </Box>
               )}
               {text && <Box className={styles.text}>{text}</Box>}
            </Flex>
            <Box
               className={styles.formWrapper}
               width={[1, null, null, 1 / 2]}
               bg="var(--white)"
            >
               <form
                  className={styles.formInner}
                  method="POST"
                  name="newsletter"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
               >
                  <Box
                     paddingTop={[
                        10,
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                     paddingX={[
                        'var(--container-side-padding-mobile)',
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                  >
                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           required
                           name="firstname"
                           placeholder={t('firstName')}
                           register={register}
                           error={errors?.firstname}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           required
                           name="lastname"
                           placeholder={t('lastName')}
                           register={register}
                           error={errors?.lastname}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           required
                           name="email"
                           placeholder={t('eMail')}
                           register={register}
                           error={errors?.email}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputText
                           name="company"
                           placeholder={t('companyName')}
                           register={register}
                           error={errors?.company}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputSelect
                           name="country_of_residence"
                           options={countryOptions}
                           placeholder={t('selectYourCountry')}
                           register={register}
                           error={errors?.country_of_residence}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputSelect
                           required
                           name="typ_av_investerare"
                           options={typeOptions}
                           placeholder={t('whatTypeOfInvestor')}
                           register={register}
                           error={errors?.typ_av_investerare}
                        />
                     </Box>

                     <Box marginBottom={[8, null, null, 10]}>
                        <InputSelect
                           name="hs_language"
                           required
                           options={languageOptions}
                           placeholder={t('whatLanguage')}
                           register={register}
                           error={errors?.hs_language}
                        />
                     </Box>
                     <Box marginBottom={[8, null, null, 10]}>
                        <Box marginBottom={[5]} className={styles.description}>
                           {t('haveYouAlreadyInvested')}
                        </Box>
                        <InputCheckboxGroup
                           name="investing_status"
                           options={investmentOptions}
                           register={register}
                           error={errors?.investing_status}
                           useValue
                        />
                     </Box>
                  </Box>
                  <Box
                     bg="var(--light-gray)"
                     paddingTop={[10, null, null, 12]}
                     paddingX={[
                        'var(--container-side-padding-mobile)',
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                     paddingBottom={[
                        10,
                        null,
                        null,
                        'var(--container-side-padding)',
                     ]}
                  >
                     {privacy_text && (
                        <Box marginBottom={[5]} className={styles.description}>
                           <Markdown>{privacy_text}</Markdown>
                        </Box>
                     )}
                     <Box marginBottom={[8, null, null, 10]}>
                        <InputCheckboxGroup
                           name="newsletter"
                           options={newsletterOptions}
                           register={register}
                           required
                           error={errors?.newsletter}
                        />
                        <InputCheckboxGroup
                           name="press"
                           options={pressOptions}
                           register={register}
                           error={errors?.press}
                        />
                        <InputCheckboxGroup
                           name="communication"
                           options={communicationOptions}
                           register={register}
                           error={errors?.communication}
                        />
                     </Box>
                     {subscription_text && (
                        <Box marginBottom={[5]} className={styles.description}>
                           <Markdown>{subscription_text}</Markdown>
                        </Box>
                     )}
                     <Box className={styles.submit}>
                        <CtaButton
                           type="submit"
                           blok={{
                              component: 'cta_button',
                              _uid: '1',
                              color: 'purple',
                              background_color: 'lightGray',
                              hover_color: 'purple',
                              link: [
                                 {
                                    component: 'link',
                                    _uid: '1',
                                    title: success
                                       ? 'Success'
                                       : error
                                       ? 'Error'
                                       : loading
                                       ? 'Loading'
                                       : subscribeTitle,
                                 },
                              ],
                           }}
                        />
                     </Box>
                  </Box>
               </form>
               {/* {(success || error) && (
                  <div className={styles.message}>
                     {success ? 'Success' : error ? 'Error' : ''}
                  </div>
               )} */}
            </Box>
         </Flex>
      </React.Fragment>
   )
}

export default PageNewsletter
