import React from 'react'
import { FileListProps } from './types'
import { Box, Container } from '@components/UI/Grid/Grid'
import Files from '@components/Files/Files'
import styles from './FileList.module.scss'
import { getThemeColor, getTitleMarkup } from '@lib/helpers'
const FileList: React.FC<FileListProps> = ({ blok }) => {
   const { title, files, background, title_markup } = blok || {}
   return (
      <Box
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
         as="section"
         paddingY={[10]}
      >
         <Container>
            {title && (
               <Box
                  className={styles.title}
                  as={getTitleMarkup(title_markup)}
                  marginBottom={[12]}
               >
                  {title}
               </Box>
            )}
            {files && files?.length > 0 && <Files blok={{ files }} />}
         </Container>
      </Box>
   )
}

export default FileList
