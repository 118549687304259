import React from 'react'
import { withTheme } from '@emotion/react'
import styles from './Grid.module.scss'
// import { GridProps } from './types'
import { Box as ReflexBox, Flex as ReflexFlex } from 'reflexbox'

export const Box = withTheme(ReflexBox)
export const Flex = withTheme(ReflexFlex)

export const Container = ({ children, className = '', ...rest }: any) => {
   return (
      <Box
         paddingX={[
            'var(container-side-padding-mobile)',
            null,
            null,
            'var(--container-side-padding)',
         ]}
         className={[styles.container, className].join(' ')}
         {...rest}
      >
         {children}
      </Box>
   )
}

export const Row = (props: any) => <Flex {...props} />

Row.defaultProps = {
   marginX: ['-8px', null, null, '-8px'],
   flexDirection: ['column', null, null, 'row'],
   flexWrap: 'wrap',
}

export const Column = (props: any) => <Box {...props} />

Column.defaultProps = {
   position: 'relative',
   width: '100%',
   minHeight: '1px',
   paddingX: [2, null, null, 2],
}
